import { API_LIRIUM_USER_URL } from '../../common/constants';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const getCryptoPoints = async (): Promise<number> => {
  const URL: string = API_LIRIUM_USER_URL.cryptoPoints;

  try {
    const cryptoPoints: number = await fetchContent(URL, {
      method: 'GET',
    });
    return cryptoPoints;
  } catch (error: any) {
    console.error('[getCryptoPoints]: ' + error);
    throw handleServiceError(error);
  }
};
