import { CSSProperties } from 'styled-components';

export const TITLE_CONTAINER_STYLES = {
  titleContainer: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
  } as CSSProperties,
  discount: {
    marginTop: '3px',
  } as CSSProperties,
};
