import { API_LIRIUM_USER_URL, headerOptions } from '../../common/constants';
import { LiriumUser } from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const getUserById = async (idUsuario: number): Promise<LiriumUser> => {
  const URL: string = API_LIRIUM_USER_URL.userById.replace('{idUsuario}', String(idUsuario));

  try {
    const response: LiriumUser = await fetchContent(URL, {
      method: 'GET',
      headers: headerOptions,
    });

    return response;
  } catch (error: any) {
    console.error('[getUserById]: ' + error);
    throw handleServiceError(error);
  }
};
