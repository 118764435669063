import React from 'react';
import { Box, Paper, Table, TableBody, TableContainer, TableHead, Typography } from '@mui/material';
import { PlaylistAddCheckOutlined as PlaylistAddCheckOutlinedIcon } from '@mui/icons-material';
import { SimpleTableRow } from '../../../../components/SimpleTableRow/SimpleTableRow';
import { ICustomSimpleTableColumn, Rows } from '../../../../common/types';
import { ExchangeStatus } from './ExchangeStatus';
import CustomTableRow from '../../../../components/CustomTable/CustomTableRow';

interface PointsExchangeProps {
  title: string;
  idStatus: number;
  statusTitle: string;
  status: string;
  columns: ICustomSimpleTableColumn[];
  products: Rows;
}

export const PointsExchangeDetail: React.FC<PointsExchangeProps> = ({
  title,
  idStatus,
  statusTitle,
  status,
  columns,
  products,
}) => {
  return (
    <>
      <ExchangeStatus
        statusTitle={statusTitle}
        idStatus={idStatus}
        status={status}
      />

      <Box
        display="flex"
        alignItems="center"
        py={1}
        gap={1}
      >
        <PlaylistAddCheckOutlinedIcon color="primary" />
        <Typography variant="body2">{title}</Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        py={1}
        gap={1}
      >
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 185 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              size="small"
            >
              <TableHead>
                <SimpleTableRow
                  cell={columns}
                  cellVariant="subtitle2"
                />
              </TableHead>
              <TableBody>
                {products?.map((product) => {
                  return (
                    <CustomTableRow
                      key={product}
                      {...product}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};
