import {
  ICustomSimpleTableColumn,
  IFormatTableColumns,
  FormatTableColumns,
  FormateSimpleTableColumns,
} from '../../common/types';

export const formatTableColumns = (column: FormatTableColumns, excludeSort: string[]): IFormatTableColumns[] => {
  const sortColumns: IFormatTableColumns[] = Object.entries(column).map(([key, value]) => {
    const isHasOrdering: boolean = !excludeSort.includes(key);

    return {
      label: value,
      queryParam: key,
      hasOrdering: isHasOrdering,
    };
  });

  return sortColumns;
};

export const formatTableSimpleColumns = (column: FormateSimpleTableColumns): ICustomSimpleTableColumn[] => {
  const sortColumns: ICustomSimpleTableColumn[] = Object.values(column).map((key) => {
    return {
      label: key,
    };
  });
  return sortColumns;
};
