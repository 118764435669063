import { fetchContent } from '../../../utils/fetchUtils';
import { setQueryParams } from '../../../utils/functions';
import { handleServiceError } from '../../GiftCardService';
import { IRecognitions, IRecognitionsQueryParams } from '../../../common/types/recognitions';
import { API_RECOGNITIONS_URL } from '../../../common/constants';

export const getApprovedRecognitions = async (queryParams: IRecognitionsQueryParams): Promise<IRecognitions> => {
  const URL: string = setQueryParams(API_RECOGNITIONS_URL.recognition.supervisor, queryParams);
  try {
    const response = await fetchContent(URL, {
      method: 'GET',
    });
    return response;
  } catch (error) {
    console.error('[getApprovedRecognitions]: ' + error);
    throw handleServiceError(error);
  }
};
