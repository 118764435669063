import React from 'react';
import { Grid } from '@mui/material';
import InputForm from '../../../../../components/Inputs/InputForm/InputForm';
import { DatePickerParentController } from '../../../../../components/DatePickerParentController/DatePickerParentController';
import { CustomerBody } from '../../../../../common/types';

interface BasicFieldsProps {
  name: string;
  middleName: string;
  lastName: string;
  dni: string;
  dateOfBirth: string;
  form: CustomerBody;
  setForm: (form: CustomerBody) => void;
}

export const BasicFields: React.FC<BasicFieldsProps> = ({
  name,
  middleName,
  lastName,
  dni,
  dateOfBirth,
  form,
  setForm,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4} spacing={2}>
        <InputForm name="first_name" label={name} type="text" form={form} setForm={setForm} />
      </Grid>

      <Grid item xs={12} sm={12} md={4} spacing={2}>
        <InputForm name="middle_name" label={middleName} type="text" form={form} setForm={setForm} />
      </Grid>

      <Grid item xs={12} sm={12} md={4} spacing={2}>
        <InputForm name="last_name" label={lastName} type="text" form={form} setForm={setForm} />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <InputForm name="national_id" label={dni} type="text" form={form} setForm={setForm} />
      </Grid>
      
      <Grid item xs={12} sm={12} md={4}>
        <DatePickerParentController name="date_of_birth" label={dateOfBirth} form={form} setForm={setForm} />
      </Grid>
    </Grid>
  );
};
