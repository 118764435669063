import React from 'react';
import { Modal, Box, Typography, IconButton, Grid } from '@mui/material';
import {
  Close as CloseIcon,
  NumbersOutlined as NumbersOutlinedIcon,
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
  LocalOfferOutlined as LocalOfferOutlinedIcon,
} from '@mui/icons-material/';
import { MyTransactionItems } from '../../../common/types';
import { MY_TRANSACTIONS_DETAIL_CONTAINER_STYLES } from './styles';

interface DetailModalProps {
  title: string;
  idTransaction: number;
  date: string;
  points: string;
  detailModal: MyTransactionItems;
  children: React.ReactNode;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  image?: string;
}

export const MyTransactionDetailContainer: React.FC<DetailModalProps> = ({
  title,
  idTransaction,
  date,
  points,
  detailModal,
  children,
  image,
  isModalOpen,
  setIsModalOpen,
}) => {
  return (
    <Box>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Box sx={MY_TRANSACTIONS_DETAIL_CONTAINER_STYLES.modal}>
          <Box
            sx={MY_TRANSACTIONS_DETAIL_CONTAINER_STYLES.closeIconContainer}
            onClick={() => setIsModalOpen(false)}
          >
            <IconButton>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography
            variant="h5"
            color={'primary.main'}
          >
            {title}
          </Typography>

          <Grid
            container
            spacing={2}
          >
            {image && (
              <Grid
                item
                xs={6}
                display={'flex'}
                alignItems={'center'}
              >
                <img
                  src={image}
                  width="100%"
                  height="auto"
                  alt="transaction-image"
                />
              </Grid>
            )}
            <Grid
              item
              xs={image ? 6 : 12}
            >
              <Box
                display="flex"
                alignItems="center"
                py={1}
                gap={1}
              >
                <NumbersOutlinedIcon color="primary" />
                <Typography variant="body2">
                  {detailModal.id} {idTransaction}
                </Typography>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                gap={1}
                py={1}
              >
                <CalendarMonthOutlinedIcon color="primary" />
                <Typography variant="body2">
                  {detailModal.date} {date}
                </Typography>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                py={1}
                gap={1}
              >
                <LocalOfferOutlinedIcon color="primary" />
                <Typography variant="body2">
                  {detailModal.points} {points}
                </Typography>
              </Box>

              {children}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};
