import { CSSProperties } from 'styled-components';

export const CURRENCY_GRAPHIC_STYLES = {
  graphicContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as CSSProperties,
  mainBox: {
    width: '100%',
    maxWidth: '315px',
    height: '240px',
    border: 2,
    borderColor: 'primary.main',
    borderRadius: 2,
  } as CSSProperties,
};
