import { API_RECOGNITIONS_URL } from '../../../common/constants';
import { headerOptions } from '../../../common/constants/constants';
import { IRecognitionsForm } from '../../../common/types/recognitions';
import { fetchNoJsonRes } from '../../../utils/fetchUtils';
import { modalError } from '../../../utils/validaciones';

export const postRecognition = async (body: IRecognitionsForm) => {
  const { idCliente, idUsuario, idTipoReconocimiento, descripcion, usuariosReconocidos } = body;
  try {
    if (usuariosReconocidos.length > 0) {
      const idUsuariosReconocidos = usuariosReconocidos.map((user) => {
        return { idUsuario: user };
      });

      const recoBody = {
        idCliente,
        IdUsuarioAsignador: idUsuario,
        Descripcion: descripcion,
        UsuariosReconocidos: idUsuariosReconocidos,
        IdTipoReconocimiento: idTipoReconocimiento,
      };

      const response = await fetchNoJsonRes(API_RECOGNITIONS_URL.recognition.recognition, {
        method: 'POST',
        headers: {
          ...headerOptions,
          'x-api-version': '2.0',
        },
        body: JSON.stringify(recoBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        let errorMessages = [];

        for (const key in errorData.errors) {
          if (Object.prototype.hasOwnProperty.call(errorData.errors, key)) {
            errorMessages = errorMessages.concat(errorData.errors[key]);
          }
        }
        throw Error(errorData.message);
      }

      return response;
    }
  } catch (error: any) {
    console.error(`[error][postRecognition]: ${error}`);
    const errorMessage = error.message || 'Se produjo un error desconocido.';

    if (error.status === 422) {
      modalError(errorMessage, 8000);
    } else {
      modalError(errorMessage);
    }
    throw new Error(errorMessage);
  }
};
