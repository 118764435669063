import { useProductDetail } from '../../hooks/product';
import { Grid, Typography } from '@mui/material';
import ShortLine from '../../components/ShortLine/ShortLine';
import { Loading } from '../../components';
import { NoResults } from '../../components/NoResults/NoResults';
import { RUTAS } from '../../common/constants/rutas';
import { useParams } from 'react-router-dom';
import { PreLogIn } from '../../components/PreLogIn/PreLogIn';
import { ProductDetail } from './components';

export const Product = () => {
  const { id } = useParams();
  const { productDetail, productDetailError, isLoading, dictionary, idUsuario } = useProductDetail(id);

  return (
    <div className="container-section">
      <Grid container columns={12} paddingBottom={5}>
        <Grid item sm={12}>
          <Typography variant="h4" color="primary">
            {dictionary.title}
            <ShortLine position="left" />
          </Typography>
        </Grid>

        {isLoading && <Loading />}

        {!idUsuario && (
          <PreLogIn title={dictionary.notLogged} buttonText={dictionary.notLoggedButton} route={RUTAS.login} />
        )}

        {(productDetailError || !productDetail) && idUsuario && (
          <NoResults text={dictionary.noResults} {...{ color: 'primary' }} />
        )}

        {productDetail && <ProductDetail productDetail={productDetail} />}
      </Grid>
    </div>
  );
};
