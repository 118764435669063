import { Box } from '@mui/material';
import ButtonSubmit from '../../ButtonSubmit/ButtonSubmit';

interface Actions {
  isAnyPending: boolean;
  isApproving: boolean;
  isFormEmpty: boolean;
  button: string;
  handleSubmit: () => void;
  setIsApproving: (isApproving: boolean) => void;
}

export const ApprovedRecognitionsActions = (actions: Actions) => {
  const { isAnyPending, isApproving, button, isFormEmpty, handleSubmit, setIsApproving } = actions;

  return (
    <Box textAlign={'right'} display={!isAnyPending && 'none'}>
      <ButtonSubmit disabled={isFormEmpty} isLoading={isApproving} onClick={handleSubmit} setIsLoading={setIsApproving}>
        {button}
      </ButtonSubmit>
    </Box>
  );
};
