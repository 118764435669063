import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import ShortLine from '../../../../components/ShortLine/ShortLine';
import { RUTAS } from '../../../../common/constants/rutas';
import { Balance, CRYPTO_LOGIN_STYLES, CurrencyGraphic, TransactionsInCrypto } from '..';
import { BalanceProps, CurrencyGraphicProps, TransactionsInCryptoProps } from '../../../../common/types';

interface CryptoLogInProps {
  title: string;
  get: string;
  sell: string;
  balance: BalanceProps;
  currencyGraphic: CurrencyGraphicProps;
  transactionsInCrypto: TransactionsInCryptoProps;
  onNavigate: (route: string) => void;
}

export const CryptoAuth: React.FC<CryptoLogInProps> = ({
  title,
  get,
  sell,
  balance: { balanceText, currencyText, amount, cryptoExchangeCurrencyTypes, currentCurrency, setCurrentCurrency },
  currencyGraphic: { currency, graphicTitle },
  transactionsInCrypto,
  onNavigate,
}) => {
  return (
    <Box style={CRYPTO_LOGIN_STYLES.mainContainer}>
      <Grid container columns={12} spacing={2} padding={3}>
        <Typography variant="h4" color="primary">
          {title}
          <ShortLine position="left" />
        </Typography>

        <Balance
          balanceText={balanceText}
          currencyText={currencyText}
          amount={amount}
          cryptoExchangeCurrencyTypes={cryptoExchangeCurrencyTypes}
          currentCurrency={currentCurrency}
          setCurrentCurrency={setCurrentCurrency}
        />

        <Grid item xs={12}>
          <Box sx={CRYPTO_LOGIN_STYLES.buttonsContainer}>
            <Button variant="contained" color="btnPrimary" onClick={() => onNavigate(RUTAS.compraCripto)}>
              {get}
            </Button>
            <Button variant="outlined" color="btnPrimary" onClick={() => onNavigate(RUTAS.ventaCripto)}>
              {sell}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} sx={CRYPTO_LOGIN_STYLES.graphicBox}>
          {cryptoExchangeCurrencyTypes.map((exchangeCurrency) => (
            <CurrencyGraphic
              key={exchangeCurrency}
              currency={currency?.alfa3}
              currencyType={exchangeCurrency}
              title={graphicTitle}
            />
          ))}
        </Grid>

        <TransactionsInCrypto transactionsInCrypto={transactionsInCrypto} />
      </Grid>
    </Box>
  );
};
