import { API_LIRIUM_HISTORICAL_PRICE } from '../../common/constants';
import { HistoricalPriceByCurrencyTypeProps, LiriumHistoricalPrice } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getHistoricalPriceByCurrencyType = async ({
  currencyType,
  amount,
  currency,
}: HistoricalPriceByCurrencyTypeProps): Promise<LiriumHistoricalPrice[]> => {
  const URL: string = API_LIRIUM_HISTORICAL_PRICE.currencyType
    .replace('{currencyType}', currencyType)
    .replace('{amount}', String(amount))
    .replace('{currency}', currency);

  try {
    const liriumHistoricalPrice: LiriumHistoricalPrice[] = await fetchContent(URL, {
      method: 'GET',
    });
    return liriumHistoricalPrice;
  } catch (error: any) {
    console.error('[getHistoricalPriceByCurrencyType]: ' + error);
    throw handleServiceError(error);
  }
};
