import { CSSProperties } from 'styled-components';

export const CUSTOM_COUNT_STYLES = {
  container: { display: 'flex', flexDirection: 'column', gap: '5px' },
  errorMessage: { display: 'flex', justifyContent: 'flex-end', gap: '45px' },
  containerButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  input: {
    width: '130px',
    textAlign: 'center',
    height: '40px',
    borderRadius: '0px',
    borderTop: '1px solid #80A3E3',
    borderBottom: '1px solid #80A3E3',
    color: '#535955',
  },
  button: {
    height: '40px',
    minWidth: '40px',
    maxWidth: '40px',
  },
  buttonLeft: {
    borderRadius: '10px 0 0 10px',
  },
  buttonRight: {
    borderRadius: '0 10px 10px 0',
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
    width: '210px',
  },
  arrowIcon: {
    fontSize: '20px',
  } as CSSProperties,
};
