import React from 'react';
import { Box, FormControl, Grid } from '@mui/material';
import { EXCHANGE_FORM_CONTAINER_STYLES } from '../..';

export const ExchangeFormContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
      <Box
        width={'95%'}
        maxWidth={'682px'}
        minHeight={'350px'}
        border={2}
        borderColor={'primary.main'}
        borderRadius={3}
        marginBottom={6}
      >
        <FormControl sx={EXCHANGE_FORM_CONTAINER_STYLES.formControl}>{children}</FormControl>
      </Box>
    </Grid>
  );
};
