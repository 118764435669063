import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CustomerBody, InternalAPILiriumUserResponse, PostCustomerProps } from '../../types';
import { postCustomer } from '../../../services/lirium_user';
import { modalError, modalSuccess, clearAllFormInputs } from '../../../utils';

interface UseLiriumUserMutationProps {
  customer: PostCustomerProps;
  successText: string;
  errorText: string;
  setIsHasCryptoAccount: (isHasCryptoAccount: boolean) => void;
  setForm: (form: CustomerBody) => void;
}

export const useLiriumUserMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({ customer }: UseLiriumUserMutationProps): Promise<InternalAPILiriumUserResponse> =>
      await postCustomer(customer),

    onSuccess(_, variables) {
      const {
        successText,
        customer: { idUsuario, customerBody },
        setIsHasCryptoAccount,
        setForm,
      } = variables;

      setForm(clearAllFormInputs(customerBody));

      modalSuccess(successText);

      queryClient.cancelQueries({ queryKey: ['crypto-user', { idUsuario }] });
      queryClient.cancelQueries({ queryKey: ['crypto-customer', { idUsuario }] });

      setIsHasCryptoAccount(true);
    },

    onError(_, variables) {
      const { errorText } = variables;

      modalError(errorText);
    },
  });

  return { mutation };
};
