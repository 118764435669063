import { CSSProperties } from 'styled-components';

export const STYLE_PRODUCT_DETAIL_CARD = {
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    gap: '25px',
  } as CSSProperties,
  descriptionContainer: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
  } as CSSProperties,
  discount: {
    marginTop: '3px',
  } as CSSProperties,
  productsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as CSSProperties,
};
