import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import ShortLine from '../../../../../../components/ShortLine/ShortLine';
import step1 from '../../../../assets/crypto_step1.png';
import loadImg from '../../../../assets/crypto_input_file.png';
import { PostFile } from '../../../../../../services/CryptoService';
import { AppContext } from '../../../../../../context/AppContext';
import { modalError } from '../../../../../../utils/validaciones';
import { UPLOAD_IMAGES_FORM_STYLES } from '../../../..';

interface FrontIDUploadFormProps {
  subtitle: string;
  imageText: string;
  inputText: string;
  nextText: string;
  errorImageText: string;
}

export const FrontIDUploadForm: React.FC<FrontIDUploadFormProps> = ({
  subtitle,
  imageText,
  inputText,
  nextText,
  errorImageText,
}) => {
  const appContext = useContext(AppContext);
  const [img, setImg] = useState();
  const navigate = useNavigate();

  const handleImageChange = (e: any) => {
    setImg(e.target.value);
  };

  const handleSubmit = async () => {
    const res = await PostFile('id_front', appContext?.user?.idUsuario, img);
    res ? navigate('/crypto/alta/step2') : modalError(errorImageText);
  };

  return (
    <Box sx={UPLOAD_IMAGES_FORM_STYLES.container}>
      <Typography variant="h6" color="primary" maxWidth={'550px'} textAlign={'center'}>
        {subtitle}
        <ShortLine position="center" />
      </Typography>

      <Box sx={UPLOAD_IMAGES_FORM_STYLES.imageContainer}>
        <img src={step1} alt={imageText} width={'220px'} />

        <Typography variant="subtitle1" color="primary">
          {imageText}
        </Typography>
      </Box>

      <Box sx={UPLOAD_IMAGES_FORM_STYLES.fileInputContainer}>
        <img src={loadImg} alt="loading" width={'40px'} />

        <Typography variant="caption" color="darkslategrey">
          {inputText}
        </Typography>

        <input
          type="file"
          accept="image/*"
          style={UPLOAD_IMAGES_FORM_STYLES.fileLoadInput}
          onChange={handleImageChange}
        />
      </Box>

      <Box>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {nextText}
        </Button>
      </Box>
    </Box>
  );
};
