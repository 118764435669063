import { CSSProperties } from 'styled-components';

export const STYLE_DASHBOARD_SUMMARY_TABLE = {
  tableContainer: {
    display: 'table',
    width: '100%',
  } as CSSProperties,
  dashboardBox: {
    height: '100%',
  } as CSSProperties,
  noResults: {
    color: 'primary',
    marginTop: '0',
  } as CSSProperties,
  defaultCursor: {
    cursor: 'default',
  } as CSSProperties,
};
