import { createSlice } from "@reduxjs/toolkit";
import { carritoItemsInterface } from "../../interfaces/CarritoInterface";

const initialState: carritoItemsInterface[] = [];

const cartSlice = createSlice({
  name: "carrito",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.push(action.payload);
    },
    increament(state, { payload }) {
      return state.map((item, index) =>
        index === payload //voy a buscar por index para incrementar
          ? {
              ...item,
              cantidad: item.cantidad + 1,
              puntos:
                item.puntos + (item.puntosPorUnidad ? item.puntosPorUnidad : 0),
            }
          : item
      );
    },
    decrement(state, { payload }) {
      return state.map((item, index) =>
        index === payload && item.cantidad !== 1
          ? {
              ...item,
              cantidad: item.cantidad - 1,
              puntos:
                (item.puntosPorUnidad ? item.puntosPorUnidad : 0) *
                (item.cantidad - 1),
            }
          : item
      );
    },
    removeItem: (state, action) => {
      state.splice(action.payload, 1);
    },
    resetItems: () => initialState,
  },
});

export const carritoReducer = cartSlice.reducer;
export const { addToCart, increament, decrement, removeItem, resetItems } =
  cartSlice.actions;
