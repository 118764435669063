import React from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ITypeOfTransaction } from '../../../../common/types';

interface TypeOfTransactionMenuProps {
  title: string;
  typeOfTransaction: ITypeOfTransaction[];
  typeOfTransactionSelected: number | string;
  setTypeOfTransaction: (typeOfTransactionSelected: number) => void;
}

export const TypeOfTransactionMenu: React.FC<TypeOfTransactionMenuProps> = ({
  title,
  typeOfTransaction,
  typeOfTransactionSelected,
  setTypeOfTransaction,
}) => {
  if (!typeOfTransaction) return <></>;

  return (
    <FormControl
      fullWidth
      margin="normal"
    >
      <InputLabel>{title}</InputLabel>
      <Select
        value={typeOfTransactionSelected || ''}
        label={title}
        onChange={(e) => {
          e.preventDefault();
          const selectedType: number = Number(e.target.value);
          setTypeOfTransaction(selectedType);
        }}
      >
        {typeOfTransaction?.map(({ id, descripcion }) => {
          return (
            <MenuItem
              key={id}
              value={id}
            >
              {descripcion}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
