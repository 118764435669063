import { API_CURRENCY_URL } from '../../common/constants';
import { Currency } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getCurrencyById = async (id: string): Promise<Currency> => {
  const URL: string = API_CURRENCY_URL.currencyById.replace('{id}', id);

  try {
    const response: Currency = await fetchContent(URL, {
      method: 'GET',
    });

    return response;
  } catch (error: any) {
    console.error('[getCurrency]: ' + error);
    throw handleServiceError(error);
  }
};
