import { postLiriumOrder } from '.';
import { API_LIRIUM_ORDER_URL, headerOptions } from '../../common/constants';
import {
  AssetProps,
  BuyOrSell,
  LiriumOrderExternalAPI,
  OrderOperation,
  PostLiriumOrderAPIExternal,
} from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';
import { v4 as uuidv4 } from 'uuid';

interface postLiriumOrdersProps {
  idCustomer: string;
  idUsuario: number;
  operation: OrderOperation;
  asset: AssetProps;
  buyOrSell: BuyOrSell;
}

export const postLiriumOrderAPIExternal = async ({
  idUsuario,
  idCustomer,
  operation,
  asset,
  buyOrSell,
}: postLiriumOrdersProps): Promise<LiriumOrderExternalAPI> => {
  const URL: string = API_LIRIUM_ORDER_URL.ordersByIdCustomer.replace('{idCustomer}', String(idCustomer));

  const order: PostLiriumOrderAPIExternal = {
    reference_id: uuidv4(),
    operation,
    asset,
  };

  if ('buy' in buyOrSell) {
    order.buy = buyOrSell.buy;
  } else {
    order.sell = buyOrSell.sell;
  }

  try {
    const externalAPIResponse: LiriumOrderExternalAPI = await fetchContent(URL, {
      method: 'POST',
      headers: headerOptions,
      body: JSON.stringify(order),
    });

    // //* NO ES NECESARIO ESPERAR LA RESPUESTA NI DEVOLVERLA
    postLiriumOrder({
      idUsuario,
      reference_id: order.reference_id,
      liriumOrderExternalAPI: externalAPIResponse,
    });

    return externalAPIResponse;
  } catch (error: any) {
    console.error('[postLiriumOrderAPIExternal]: ' + error);
    throw handleServiceError(error);
  }
};
