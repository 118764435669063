import dayjs from 'dayjs';

export const formatDate = (dateString) => {
  const date = dayjs(dateString);
  return date.isValid() ? date.format('DD/MM/YYYY') : '-';
};

export const formateDayAndMonth = (dateString: Date): string => {
  const date = dayjs(dateString);
  return date.isValid() ? date.format('MM/DD') : '-';
};

export const formateFullYear = (dateString: Date): string => {
  const date = dayjs(dateString);
  return date.isValid() ? date.format('YYYY/MM/DD') : '-';
};
