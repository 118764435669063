import { useQuery } from '@tanstack/react-query';
import { ClientAmountPerPointsProps, IDsTypeOFTransaction } from '../../types';
import {
  getAmountPerPoints,
  getClientCurrency,
  getClientFooterData,
  getClientTheme,
  getPointsPerAmount,
} from '../../../services/client';
import { cliente } from '../../constants';
import { useAppContext } from '../useAppContext';

interface Client {
  pointsPerAmount?: number;
  clientCurrency?: boolean;
  amountPerPoints?: ClientAmountPerPointsProps;
}

export const useClient = ({ pointsPerAmount, clientCurrency, amountPerPoints }: Client) => {
  const { idUsuario } = useAppContext();

  const pointsPerAmountQuery = useQuery({
    queryKey: ['points-per-amount', { idUsuario, pointsPerAmount }],
    queryFn: async () =>
      await getPointsPerAmount({
        idUsuario,
        idCliente: cliente,
        idTipoMovimiento: IDsTypeOFTransaction.canjePuntos,
        importe: pointsPerAmount,
      }),
    enabled: Boolean(idUsuario && pointsPerAmount > 0),
  });

  const clientCurrencyQuery = useQuery({
    queryKey: ['client-currency', { idUsuario }],
    queryFn: async () => await getClientCurrency(cliente),
    enabled: Boolean(cliente && clientCurrency),
  });

  const amountPerPointsQuery = useQuery({
    queryKey: ['amount-per-points', { idUsuario, points: amountPerPoints?.puntos }],
    queryFn: async () => await getAmountPerPoints({ idUsuario, ...amountPerPoints }),
    enabled: Boolean(idUsuario && amountPerPoints?.puntos > 0),
  });

  const themeAndFooterQuery = useQuery({
    queryKey: ['client-theme-&-footer', { cliente }],
    queryFn: async () => {
      const promises = [getClientTheme(cliente), getClientFooterData(cliente)];
      const [theme, footer] = await Promise.allSettled(promises).then((results) =>
        results.map((result) => result.status === 'fulfilled' && result.value)
      );
      return { theme, footer };
    },
    enabled: Boolean(cliente),
  });

  return { idUsuario, pointsPerAmountQuery, clientCurrencyQuery, amountPerPointsQuery, themeAndFooterQuery };
};
