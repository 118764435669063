import { useCatalogoAPI } from '../../common/hooks';
import { IFiltroCatalogo } from '../../common/interfaces/GiftCardInterfaces';

interface ICatalogoFiltersProps {
  filters: IFiltroCatalogo;
}
export const useCatalogoFilters = ({ filters }: ICatalogoFiltersProps) => {
  const { catalogoMetadataQuery: catalogoFilters } = useCatalogoAPI({ metadata: filters });

  return {
    catalogoFilters,
  };
};
