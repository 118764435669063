import { API_RECOGNITIONS_URL } from '../../../common/constants';
import {
  IApprovedRecognitionsUpdated,
  IApprovedRecognitionsUpdateForm,
  STATUS_APPROVED_RECOGNITION,
} from '../../../common/types/recognitions';
import { fetchContent, Method } from '../../../utils/fetchUtils';
import { handleServiceError } from '../../GiftCardService';

export const updateApprovedRecognition = async (
  approved: IApprovedRecognitionsUpdateForm
): Promise<IApprovedRecognitionsUpdated> => {
  const { idReconocimientos, idUsuario, cliente } = approved;
  const URL: string = API_RECOGNITIONS_URL.recognition.approve;
  const recognitions =
    idReconocimientos.map((idRecognition) => ({
      idReconocimiento: idRecognition,
      idCliente: cliente,
      estado: STATUS_APPROVED_RECOGNITION.approved,
      fechaAprobacion: new Date(Date.now()).toISOString(),
      idUsuarioSupervisor: idUsuario,
    })) ?? [];

  for (const recognition of recognitions) {
    try {
      return await fetchContent(URL, {
        method: Method.POST,
        body: recognition,
        headers: {
          'x-api-version': 2.0,
        },
      });
    } catch (error: any) {
      console.error('[updateApprovedRecognition]: ' + error);
      throw handleServiceError(error);
    }
  }
};
