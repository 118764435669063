import { useContext } from 'react';
import { Carrousel, Dashboard, PreLogin } from '.';
import { AppContext } from '../../context';

export const Home = () => {
  const appContext = useContext(AppContext);

  return (
    <>
      <Carrousel />
      {appContext?.user ? <Dashboard /> : <PreLogin />}
    </>
  );
};
