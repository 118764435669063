import { fetchNoJsonRes } from '../utils/fetchUtils';

export const getPersonaPuntos = async (idPersona: number | null | undefined, idCliente: number) => {
  if (idPersona === undefined) {
    if (sessionStorage.getItem('token')) {
      idPersona = JSON.parse(sessionStorage.getItem('user') || 'null').idPersona;
    }
  }
  try {
    let response = fetchNoJsonRes(`/api/Persona/${idPersona}/cliente/${idCliente}/puntos`, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    throw new Error('[CompraCrypto], getPersonaPuntos [error]: ' + error);
  }
};
