import { Box, Typography } from '@mui/material';
import ShortLine from '../../../components/ShortLine/ShortLine';
import { useSignUpCrypto } from '../../../hooks/crypto';
import { FrontIDUploadForm, SIGN_UP_STYLES, SignUpCryptoForm } from '..';

export const SignUpCrypto = () => {
  const {
    isHasCryptoAccount,
    dictionary,
    form,
    countries,
    cities,
    states,
    isCreatingCustomer,
    isFormFilled,
    setIsCreatingCustomer,
    setForm,
    handleSubmit,
  } = useSignUpCrypto();

  return (
    <Box sx={SIGN_UP_STYLES.signUpContainer}>
      <Typography variant="h4" color="primary">
        {dictionary.title}
        <ShortLine position="left" />
      </Typography>
      {!isHasCryptoAccount ? (
        <SignUpCryptoForm
          countries={countries}
          cities={cities}
          states={states}
          form={form}
          isCreatingCustomer={isCreatingCustomer}
          isFormFilled={isFormFilled}
          dictionary={dictionary}
          setForm={setForm}
          handleSubmit={handleSubmit}
          setIsCreatingCustomer={setIsCreatingCustomer}
        />
      ) : (
        <FrontIDUploadForm
          subtitle={dictionary.subtitle}
          imageText={dictionary.form.uploadImagesDNI.frontIDTitle}
          inputText={dictionary.form.uploadImagesDNI.frontIDSubtitle}
          nextText={dictionary.buttons.next}
          errorImageText={dictionary.form.error}
        />
      )}
    </Box>
  );
};
