import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Menu, MenuItem, Avatar, Box, ListItemIcon, Divider, IconButton, Typography, Tooltip } from '@mui/material';
import {
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  ChecklistRtlOutlined as ChecklistRtlOutlinedIcon,
  Logout as LogoutIcon,
  ContactPageOutlined as ContactPageOutlinedIcon,
  PhoneInTalkOutlined as PhoneInTalkOutlinedIcon,
} from '@mui/icons-material';
import { RUTAS } from '../../common/constants/rutas';
import { AccountMenuProps } from '../../common/interfaces/header';
import { formatNumber } from '../../utils';
import { ACCOUNT_MENU_STYLES } from './styles';

type IAccountMenuProps = Pick<AccountMenuProps, keyof AccountMenuProps>;

export const AccountMenu: React.FC<IAccountMenuProps> = ({
  userName,
  lastName,
  email,
  avatar,
  anchor,
  isMenuOpen,
  transactions,
  getInTouch,
  logOut,
  personalInformation,
  puntos,
  tooltipTitle,
  handleOnClick,
  handleOnNavigate,
  handleLogout,
  handleOnClose,
}) => {
  return (
    <>
      <Box sx={{ ...ACCOUNT_MENU_STYLES.accountContainer, ...ACCOUNT_MENU_STYLES.logoContainerHover() }}>
        <Tooltip title={tooltipTitle}>
          <IconButton
            onClick={handleOnClick}
            size="small"
            sx={ACCOUNT_MENU_STYLES.iconButton}
            aria-haspopup="true"
            //*_ así está en la api de MUI
            aria-controls={isMenuOpen ? 'account-menu' : undefined}
            aria-expanded={isMenuOpen ? 'true' : undefined}
          >
            <AccountCircleOutlinedIcon sx={ACCOUNT_MENU_STYLES.profileIcon} />

            <Typography variant="subtitle2">{formatNumber(puntos)} pts</Typography>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchor}
        id="account-menu"
        open={isMenuOpen}
        onClose={handleOnClose}
        onClick={handleOnClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: { ...ACCOUNT_MENU_STYLES.menuItemPaper },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem sx={ACCOUNT_MENU_STYLES.userNameContainer}>
          <Avatar src={avatar} />
          <Box>
            <Typography variant="body1" color={'primary'}>
              {userName} {lastName}
            </Typography>
            <Typography variant="body2" color={'gray'}>
              {email}
            </Typography>
          </Box>
        </MenuItem>

        <Divider />

        <MenuItem onClick={() => handleOnNavigate(RUTAS.misMovimientos)}>
          <ListItemIcon>
            <ChecklistRtlOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography>{transactions}</Typography>
        </MenuItem>

        <MenuItem onClick={() => handleOnNavigate(RUTAS.misDatos)}>
          <ListItemIcon>
            <ContactPageOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography>{personalInformation}</Typography>
        </MenuItem>

        <MenuItem onClick={() => handleOnNavigate(RUTAS.contacto)}>
          <ListItemIcon>
            <PhoneInTalkOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography>{getInTouch}</Typography>
        </MenuItem>

        <RouterLink to={RUTAS.login}>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <Typography color={'red'}>{logOut}</Typography>
          </MenuItem>
        </RouterLink>
      </Menu>
    </>
  );
};
