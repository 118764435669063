import { CSSProperties } from 'styled-components';

export const HOME_CARROUSEL_STYLES = {
  swiper: {
    maxHeight: '235px',
  } as CSSProperties,
  swiperSLide: {
    width: '100%',
  } as CSSProperties,
};
