import React from 'react';
import { Typography, Box } from '@mui/material';
import { ModeCommentOutlined as ModeCommentOutlinedIcon } from '@mui/icons-material/';

interface AssignmentDetailProps {
  commentTitle: string;
  comment: string;
}

export const AssignmentDetail: React.FC<AssignmentDetailProps> = ({ commentTitle, comment }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      py={1}
    >
      <ModeCommentOutlinedIcon color="primary" />
      <Typography variant="body2">
        {commentTitle} {comment}
      </Typography>
    </Box>
  );
};
