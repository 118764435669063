import { API_RECOGNITIONS_URL } from '../../../common/constants';
import { IRecognitions, IRecognitionsQueryParams } from '../../../common/types/recognitions';
import { fetchContent } from '../../../utils/fetchUtils';
import { setQueryParams } from '../../../utils/functions';
import { handleServiceError } from '../../GiftCardService';

export const getMyRecognitions = async (queryParams: IRecognitionsQueryParams): Promise<IRecognitions> => {
  const URL: string = setQueryParams(API_RECOGNITIONS_URL.recognition.received, queryParams);

  try {
    const response: IRecognitions = await fetchContent(URL, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.error('[getMyRecognitions]: ' + error);
    throw handleServiceError(error);
  }
};
