import { Box, styled, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import ShortLine from '../../../../components/ShortLine/ShortLine';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_BOX_STYLE } from './style';

const LineTitle = styled('div')(() => ({
  border: 'solid 1px #ebe5df',
  width: '33px',
}));

type Props = {
  title: String;
  children: ReactElement;
  color?: string;
  ruta?: string;
  maxHeight?: number | string;
};

const DashboardBox: React.FC<Props> = ({ title, children, color, ruta, ...rest }) => {
  const navigate = useNavigate();
  return (
    <Box
      bgcolor={color === 'white' ? 'white' : 'primary.main'}
      border={2}
      borderColor={'primary.main'}
      onClick={() => {
        ruta && navigate(ruta);
      }}
      sx={{ ...DASHBOARD_BOX_STYLE.mainContainer, ...DASHBOARD_BOX_STYLE.mainContainerHover(color), ...rest }}
    >
      <Typography variant="h6" color={color === 'white' ? 'primary' : 'white'} textTransform="capitalize">
        {title}
        {color === 'white' ? <ShortLine position="left" /> : <LineTitle />}
      </Typography>
      {children}
    </Box>
  );
};

export default DashboardBox;
