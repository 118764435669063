import { ITransactionsDictionary, ITypeOfTransaction } from '../../common/types';
import { getValueFromObject } from '../functions';

export const formatTypeOfTransactions = (
  typeOfTransaction: ITypeOfTransaction[],
  dictionary: ITransactionsDictionary
): ITypeOfTransaction[] => {
  return typeOfTransaction?.map((transaction) => {
    return {
      ...transaction,
      descripcion: getValueFromObject(dictionary.typeOfTransactionMenu.idTypeOfTransaction, transaction?.idTipoMovimiento),
    };
  });
};
