import { API_VIDEO_URL } from '../../common/constants';
import { IFinancialEducation, Status } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getEducationLevelVideo = async (): Promise<IFinancialEducation> => {
  const URL: string = API_VIDEO_URL.educationLevel;

  try {
    const response: IFinancialEducation[] = await fetchContent(URL, {
      method: 'GET',
    });
    const DEFAULT_EDUCATION_LEVEL: IFinancialEducation = response[3];

    const reachedLevel =
      response.find((el) => el.estado === Status.Empty || el.estado !== Status.Finalizado) ?? DEFAULT_EDUCATION_LEVEL;

    return reachedLevel;
  } catch (error: any) {
    console.error('[getVideoEducationLevel]: ' + error);
    throw handleServiceError(error);
  }
};
