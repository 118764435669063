import { Link } from 'react-router-dom';
import { Box, Hidden, Typography } from '@mui/material';
import { CRIPTO_SUB_MENU, RECONOCIMIENTO_SUB_MENU, RUTAS } from '../../../common/constants/rutas';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
import CurrencyBitcoinOutlinedIcon from '@mui/icons-material/CurrencyBitcoinOutlined';
import SportsSoccerOutlinedIcon from '@mui/icons-material/SportsSoccerOutlined';
import MenuDropdown from '../../Menu/MenuDropDown/Index';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import { AuthUser } from '../../../context';
import './CenterNav.css';

interface CenterNavbarProps {
  user: AuthUser;
  isHasCryptoPermission: boolean;
  isHasRecognitionsPermission: boolean;
  catalog: string;
  crypto: string;
  cup: string;
  recognitions: string;
}

const CenterNav: React.FC<CenterNavbarProps> = ({
  user,
  isHasCryptoPermission,
  isHasRecognitionsPermission,
  catalog,
  crypto,
  cup,
  recognitions,
}) => {
  return (
    <Hidden mdDown>
      <Box fontSize={14} className="center-nav">
        <Link to={RUTAS.catalogo} style={{ display: 'flex', alignItems: 'center' }}>
          <RedeemOutlinedIcon sx={{ fontSize: '20px' }} />
          <Typography variant="subtitle2">{catalog}</Typography>
        </Link>

        {user && isHasCryptoPermission ? (
          <MenuDropdown
            buttonText={crypto}
            buttonIcon={<CurrencyBitcoinOutlinedIcon sx={{ fontSize: '20px' }} />}
            subMenu={CRIPTO_SUB_MENU}
          />
        ) : (
          <Link to={RUTAS.cripto} style={{ display: 'flex', alignItems: 'center' }}>
            <CurrencyBitcoinOutlinedIcon sx={{ fontSize: '20px' }} />
            <Typography variant="subtitle2">{crypto}</Typography>
          </Link>
        )}

        <Link
          to={RUTAS.copa}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <SportsSoccerOutlinedIcon sx={{ fontSize: '20px' }} />
          <Typography variant="subtitle2">{cup}</Typography>
        </Link>

        {user && isHasRecognitionsPermission && (
          <MenuDropdown
            buttonText={recognitions}
            buttonIcon={<EmojiEventsOutlinedIcon sx={{ fontSize: '20px' }} />}
            subMenu={RECONOCIMIENTO_SUB_MENU}
          />
        )}
      </Box>
    </Hidden>
  );
};

export default CenterNav;
