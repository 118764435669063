import { IRecognitionsData, IMyRecognitionActions, IMyRecognitionRows } from '../../common/types/recognitions';

export const myRecognitionFormatTableRows = (
  rows: IRecognitionsData[],
  actions: IMyRecognitionActions[]
): IMyRecognitionRows[] => {
  return rows?.map(({ idReconocimiento, fechaString, descripcionTipoReconocimiento, nombreUsuarioAsignador }) => {
    return {
      idReconocimiento,
      fechaString: fechaString ?? '-',
      nombreUsuarioAsignador,
      descripcionTipoReconocimiento,
      descripcion: actions.find((el) => el.idReconocimiento === idReconocimiento)?.detalle,
    };
  });
};
