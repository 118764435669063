import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext, LanguageContext } from '../../context';
import { useDispatch } from 'react-redux';
import { useOhGiftCard, useProduct } from '../../common/hooks';
import { Product, ProductDictionary } from '../../common/types';
import { getCurrencyById } from '../../services/currency';
import { incrementar } from '../../common/redux/features/contadorItemReducer';
import { addToCart } from '../../common/redux/features/carritoReducer';
import { modalError, modalSuccess } from '../../utils';
import { useCustomCount } from './useCustomCount';
import useLanguage from '../../common/hooks/useLanguage';
import { useGiftModal } from './useGiftModal';
import { SelectChangeEvent } from '@mui/material';
import ES from '../../common/language/product/es.json';
import EN from '../../common/language/product/en.json';

export const useProductDetail = (id: string) => {
  const { language } = useContext(LanguageContext);
  const appContext = useContext(AppContext);
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: ProductDictionary };
  const [isBranchesModalOpen, setIsBranchesModalOpen] = useState<boolean>(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState<boolean>(false);
  const [product, setProduct] = useState<Product | null>(null);
  const [amountOfGiftCards, setAmountOfGiftCards] = useState<number>(0);
  const [giftCard, setGiftCard] = useState<string>('');

  const { state } = useLocation();
  const dispatch = useDispatch();

  const idUsuario: number = appContext?.user?.idUsuario;

  const {
    detailWebQuery: { data: productDetail, isLoading, isError: productDetailError },
  } = useProduct({
    detailWeb: {
      id,
      idioma: language,
    },
  });

  const {
    branchesOhGiftCardQuery: { data: branches, isLoading: isBranchesLoading },
  } = useOhGiftCard({
    branches: {
      codigoProductoProveedor: productDetail?.codigoProductoProveedor,
      esOhGiftCard: productDetail?.esOhGiftCard,
    },
  });

  const {
    maxPrice,
    points,
    prevPoints,
    isOffer,
    isError,
    amount,
    offerPrice,
    salePrice,
    setAmount,
    setPoints,
    getCurrency,
    handleArrowOnClick,
    calculatePoints,
    handleOnInputChange,
    setIsError,
    setPrevPoints,
  } = useCustomCount({
    maxPrice: productDetail?.precioHasta,
    minPrice: productDetail?.precioDesde,
    deltaIncrementPrice: product?.deltaIncrementoPrecio || 1,
    idUsuario,
    idProducto: product?.idProducto,
  });

  const { isGiftModalOpen, handleOnClickModal, handleChange, handleSubmit } = useGiftModal({
    dispatch,
    validateRecipient: dictionary.giftModal.form.validateInputs.recipient,
    validateEmail: dictionary.giftModal.form.validateInputs.email,
    validateMessage: dictionary.giftModal.form.validateInputs.message,
    product,
    productDetail,
    points,
    amount,
    success: dictionary.giftModal.form.success,
    error: dictionary.giftModal.form.error,
    amountOfGiftCards,
  });

  const handleOnSelectGiftCardChange = (event: SelectChangeEvent): void => {
    setGiftCard(event.target.value || '');
  };

  const exchange = async (id: string): Promise<void> => {
    const res = await getCurrencyById(id);
    setProduct((prev) => ({ ...prev, codigoMoneda: res?.alfa3 }));
  };

  const handleRedeem = (): void => {
    if (!product) {
      modalError(dictionary.giftModal.form.error);
      return;
    }

    const pointsToRedeem: number = isOffer ? offerPrice : salePrice;
    dispatch(incrementar());
    dispatch(
      addToCart({
        idProducto: product?.idProducto,
        Imagen: productDetail?.bigPicture,
        Descripcion: productDetail?.descripcion,
        esGiftCard: product?.esGiftcard,
        puntos: points,
        puntosPorUnidad: pointsToRedeem,
        cantidad: Boolean(amountOfGiftCards > 0) ? amountOfGiftCards : 1,
        Regalo: false,
        Nombre: product?.tituloProducto,
        Email: null,
        Comentario: null,
        PersonaRegalo: null,
        Precio: amount.toString(),
        Marca: productDetail?.marcaDescripcion,
      })
    );
    modalSuccess(dictionary.giftModal.form.success);
  };

  const handleOnClick = (product: Product): void => {
    setAmount(product?.precio);
    setProduct(product);
    calculatePoints(product?.idProducto, product?.precio);
    setIsError(false);
    setAmountOfGiftCards(1);
  };

  const handleOnClickSelectGiftCardButton = (event: React.MouseEvent): void => {
    const itemID: string = event.currentTarget.getAttribute('itemID');
    const currentAmountOfGiftCards: number = Boolean(itemID === 'prev') ? amountOfGiftCards - 1 : amountOfGiftCards + 1;

    setAmountOfGiftCards(currentAmountOfGiftCards);
    setPoints(offerPrice * currentAmountOfGiftCards);
    setPrevPoints(salePrice * currentAmountOfGiftCards);
  };

  useEffect(() => {
    if (productDetail && productDetail.productos.length <= 1) {
      setProduct(productDetail.productos[0]);
      setAmount(productDetail?.precioDesde);
      calculatePoints(productDetail.productos[0].idProducto, productDetail?.precioDesde);
      exchange(productDetail.productos[0]?.codigoMoneda);
    }
    //! Si se deshabilita este comentario se genera un bucle infinito
    //TODO Replantear lógica para evitar esto
    // eslint-disable-next-line
  }, [productDetail]);
  //* Esto sería lo recomendado por React
  // }, [productDetail, calculatePoints, setAmount]);

  return {
    idUsuario,
    productDetail,
    isLoading,
    productDetailError,
    points,
    prevPoints,
    amount,
    product,
    branches,
    isOffer,
    dictionary,
    isBranchesModalOpen,
    isTermsModalOpen,
    state,
    isError,
    maxPrice,
    isGiftModalOpen,
    isBranchesLoading,
    amountOfGiftCards,
    giftCard,
    handleOnClickModal,
    setIsTermsModalOpen,
    setIsBranchesModalOpen,
    handleRedeem,
    setProduct,
    getCurrency,
    handleOnClick,
    handleArrowOnClick,
    handleOnInputChange,
    handleChange,
    handleSubmit,
    handleOnClickSelectGiftCardButton,
    handleOnSelectGiftCardChange,
  };
};
