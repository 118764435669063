import { useTransactionsDashboard } from "../../../common/hooks";

interface ITheMostConsumedProducts {
  idioma: string;
}
export const useTheMostConsumedProducts = (props: ITheMostConsumedProducts) => {
  const { idioma } = props;
  
  const { categoriesRedeemedQuery } = useTransactionsDashboard({
    redeemedCategories: { idioma },
  });

  return { categoriesRedeemedQuery };
};
