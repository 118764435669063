import { useQuery } from '@tanstack/react-query';
import { useAppContext } from '../useAppContext';
import { getPermissionsByEnvironment } from '../../../services/permissions';

interface Permissions {
  environment?: string;
}

export const usePermissions = ({ environment }: Permissions) => {
  const { idUsuario } = useAppContext();

  const permissionsEnvironmentQuery = useQuery({
    queryKey: [`permissions-environments`, { idUsuario, environment }],
    queryFn: async () => await getPermissionsByEnvironment(idUsuario, environment),
    enabled: Boolean(idUsuario && environment),
  });

  return { permissionsEnvironmentQuery };
};
