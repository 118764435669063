import { ICustomSimpleTableColumn, IRecentRecognitionTable, IRecentTransactionTable } from '../../../common/types';
import { formatTableRowsRecentRecognitions, formatTableSimpleColumns } from '../../../utils';
import { useRecognition } from '../../../common/hooks';
import {
  RECENT_RECOGNITIONS_PAGE,
  RECENT_RECOGNITIONS_RECORDS_PER_PAGE,
  RECENT_RECOGNITIONS_SORTING,
} from '../../../common/constants';

interface IRecentTransactions {
  idioma: string;
  columns: IRecentTransactionTable | IRecentRecognitionTable;
  isPermisosInDashboardWeb: boolean;
}

export const useRecentRecognitions = (props: IRecentTransactions) => {
  const { idioma, columns, isPermisosInDashboardWeb } = props;

  const { recognitionsByUserQuery } = useRecognition({
    myRecognitions: {
      idioma,
      ordenamiento: RECENT_RECOGNITIONS_SORTING,
      pagina: RECENT_RECOGNITIONS_PAGE,
      cantidadRegistrosPorPagina: RECENT_RECOGNITIONS_RECORDS_PER_PAGE,
      isPermisosInDashboardWeb,
    },
  });

  const recentRecognitionsColumns: ICustomSimpleTableColumn[] = formatTableSimpleColumns(columns);

  let recentRecognitionsRows: IRecentRecognitionTable[] = formatTableRowsRecentRecognitions(
    recognitionsByUserQuery.data?.data
  );

  return { recognitionsByUserQuery, recentRecognitionsColumns, recentRecognitionsRows };
};
