import { IDashboardSummaryTable, IDashboardSummaryTableValues, IHomeDictionary } from '../../common/types';

export const formatDashboardSummaryTables = (
  props: IDashboardSummaryTable[],
  dictionary: IHomeDictionary
): IDashboardSummaryTableValues[] => {
  const dashboardSummary: IDashboardSummaryTableValues[] = props.map(
    ({ columns, rows, data, isPermisos, isRendering, name, route }) => {
      return {
        columns,
        rows,
        title: dictionary.tables[name].title,
        isLoading: data.isLoading,
        isError: data.isError,
        noResults: dictionary.noResults,
        route: route ?? null,
        isPermisos,
        isRendering,
      };
    }
  );

  return dashboardSummary;
};
