import { Autocomplete, Box, Chip, Grid, ListItemText, TextField, Typography } from '@mui/material';
import { IConsumer, IRecognitionsDictionary } from '../../../../common/types/recognitions';

interface ListOfMembers {
  dictionary: IRecognitionsDictionary;
  listOfConsumers: IConsumer[];
  membersSelected: IConsumer[];
  handleSelectMember: (el: IConsumer) => void;
}

export const SelectMembers = (listOfMembers: ListOfMembers) => {
  const { listOfConsumers, membersSelected, dictionary, handleSelectMember } = listOfMembers;
  return (
    <Grid container spacing={2} columns={12}>
      <Grid item xs={12}>
        <Typography variant="h5" color="primary">
          {dictionary.searchAndRecognize}
        </Typography>
        <Typography fontSize={16} color="grey">
          {dictionary.clarification}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={5} alignItems={'center'}>
          <Grid item xs={12} md={4}>
            <Typography fontSize={18} color="primary" pb={1} pt={2}>
              {dictionary.selectMembers}
            </Typography>
            <Autocomplete
              multiple
              fullWidth
              options={listOfConsumers ?? []}
              getOptionLabel={(option) => `${option.apellido} ${option.nombre}`}
              value={membersSelected ?? []}
              onChange={(_, newValue) => {
                if (newValue.length > (membersSelected?.length ?? 0)) {
                  const addedItem = newValue[newValue.length - 1];
                  handleSelectMember(addedItem);
                } else {
                  const removedItems = membersSelected?.filter((item) => !newValue.includes(item));
                  removedItems?.forEach((item) => handleSelectMember(item));
                }
              }}
              renderTags={() => null} //* _No mostrar chips dentro del input
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder={dictionary.form.selectMember.placeHolder} />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <ListItemText primary={`${option.apellido} ${option.nombre}`} />
                </li>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
              }}
            >
              {membersSelected?.map((el) => (
                <Chip
                  label={`${el.apellido} ${el.nombre}`}
                  onClick={() => handleSelectMember(el)}
                  key={`panel2-${el.idUsuario}`}
                  color="primary"
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
