import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { BalanceProps, CryptoExchangeCurrencyTypes } from '../../../../common/types';
import { BALANCE_STYLES } from '..';

export const Balance: React.FC<BalanceProps> = ({
  balanceText,
  currencyText,
  amount,
  cryptoExchangeCurrencyTypes,
  currentCurrency,
  setCurrentCurrency,
}) => {
  return (
    <Grid item xs={12}>
      <Box sx={BALANCE_STYLES.balanceBox}>
        <Typography variant="h5" color="initial">
          {balanceText}
        </Typography>
        <Typography variant="h5" color="primary">
          {amount}
        </Typography>
      </Box>

      <Box sx={BALANCE_STYLES.balanceBox} mt={3}>
        <Typography variant="h5" color="initial">
          {currencyText}
        </Typography>

        {cryptoExchangeCurrencyTypes.map((currency) => {
          return (
            <Button
              key={'btn-' + currency}
              variant={currentCurrency === currency ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setCurrentCurrency(currency as CryptoExchangeCurrencyTypes)}
            >
              {currency}
            </Button>
          );
        })}
      </Box>
    </Grid>
  );
};
