import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { IRecognitionDashboardRankingProps, IMyRecognitionsProps } from '../types';
import { RECENT_RECOGNITIONS_RECORDS_PER_PAGE } from '../constants';
import { AppContext, LanguageContext } from '../../context';
import { postRecognitionDashboardRanking } from '../../services/recognitions/dashboard';
import { getMyRecognitions } from '../../services/recognitions';

interface IRecognition {
  dashboardRanking?: IRecognitionDashboardRankingProps;
  myRecognitions?: IMyRecognitionsProps;
}

export const useRecognition = ({ dashboardRanking, myRecognitions }: IRecognition) => {
  const appContext = useContext(AppContext);
  const { language } = useContext(LanguageContext);
  const idUsuario: number = appContext?.user?.idUsuario;

  const dashboardRecognitionsRankingQuery = useQuery({
    queryKey: ['dashboard-recognition-ranking', idUsuario],
    queryFn: async () =>
      await postRecognitionDashboardRanking({
        //* En la API es un POST, pero no crea nueva información, solo devuelve data
        idioma: dashboardRanking.idioma,
        cantidadRegistros: dashboardRanking.cantidadRegistros,
        fechaDesde: dashboardRanking.fechaDesde,
        fechaHasta: dashboardRanking.fechaHasta,
      }),
    enabled: Boolean(dashboardRanking && idUsuario && dashboardRanking?.isPermisosInDashboardWeb),
  });

  const recognitionsByUserQuery = useQuery({
    queryKey: [
      'recognitions-by-user',
      { idUsuario, language, page: myRecognitions?.pagina, sort: myRecognitions?.ordenamiento },
    ],
    queryFn: async () =>
      await getMyRecognitions({
        idioma: myRecognitions?.idioma,
        ordenamiento: myRecognitions?.ordenamiento,
        pagina: myRecognitions?.pagina,
        cantidadRegistrosPorPagina: RECENT_RECOGNITIONS_RECORDS_PER_PAGE,
      }),
    enabled: Boolean(myRecognitions && idUsuario && myRecognitions?.isPermisosInDashboardWeb),
  });

  return {
    dashboardRecognitionsRankingQuery,
    recognitionsByUserQuery,
  };
};
