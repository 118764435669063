import { API_TYPE_OF_TRANSACTIONS_URL } from '../../common/constants';
import { ITypeOfTransaction } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getUserTypeOfTransactions = async (): Promise<ITypeOfTransaction[]> => {
  const URL: string = API_TYPE_OF_TRANSACTIONS_URL.byUser;

  try {
    const response: ITypeOfTransaction[] = await fetchContent(URL);
    return response;
  } catch (error: any) {
    console.error('[getUserTypeOfTransactions]: ' + error);
    throw handleServiceError(error);
  }
};
