import React from 'react';
import { Grid } from '@mui/material';
import {
  STYLE_PRODUCT_DETAIL_CARD,
  SaleContainer,
  TitleContainer,
  ProductsToRedeem,
  GiftModal,
  TermsAndConditions,
  RedeemButton,
  BranchesButton,
} from '..';
import {
  AmountSelectorProps,
  BranchesButtonProps,
  GiftModalProps,
  ProductsToRedeemProps,
  RedeemButtonProps,
  SaleContainerProps,
  TermsAndConditionsProps,
  TitleContainerProps,
} from '../../../../common/types';
import { AmountSelector } from '../AmountSelector/AmountSelector';

interface ProductDetailCardProps {
  termsAndConditions: TermsAndConditionsProps;
  titleContainer: TitleContainerProps;
  saleContainer: SaleContainerProps;
  productsToRedeem: ProductsToRedeemProps;
  giftModal: GiftModalProps;
  redeemButton: RedeemButtonProps;
  branchesButton: BranchesButtonProps;
  amountSelector: AmountSelectorProps;
}

export const ProductDetailCard: React.FC<ProductDetailCardProps> = ({
  termsAndConditions,
  titleContainer,
  saleContainer,
  productsToRedeem,
  redeemButton,
  giftModal,
  branchesButton,
  amountSelector,
}) => {
  return (
    <Grid container columns={12} p={2} maxWidth={'1400px'}>
      <TermsAndConditions termsAndConditions={termsAndConditions} />
      <Grid item sm={12} md={6} p={2} sx={STYLE_PRODUCT_DETAIL_CARD.infoContainer}>
        <TitleContainer titleContainer={titleContainer} />
        <Grid container sx={STYLE_PRODUCT_DETAIL_CARD.productsContainer}>
          <SaleContainer saleContainer={saleContainer} />
          <ProductsToRedeem productsToRedeem={productsToRedeem} />
          <AmountSelector amountSelector={amountSelector} />
        </Grid>
        <Grid container columns={12} spacing={3}>
          <GiftModal giftModal={giftModal} />

          <RedeemButton redeemButton={redeemButton} />
        </Grid>
        <BranchesButton branchesButton={branchesButton} />
      </Grid>
    </Grid>
  );
};
