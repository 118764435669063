import { useQuery } from '@tanstack/react-query';
import { useAppContext } from '../useAppContext';
import { getUserTypeOfTransactions } from '../../../services/type_of_transactions';

interface TypeOfTransactionProps {
  isFetchingUserTypeOfTransaction?: boolean;
}

export const useTypeOfTransaction = ({ isFetchingUserTypeOfTransaction }: TypeOfTransactionProps) => {
  const { idUsuario } = useAppContext();

  const userTypeOfTransactionQuery = useQuery({
    queryKey: ['user-type-of-transaction', { idUsuario }],
    queryFn: async () => await getUserTypeOfTransactions(),
    enabled: Boolean(idUsuario && isFetchingUserTypeOfTransaction),
  });

  return {
    userTypeOfTransactionQuery,
  };
};
