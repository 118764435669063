import { RECOGNITIONS_DASHBOARD_RANKING_RECORDS_PER_PAGE } from '../../../common/constants';
import { useRecognition } from '../../../common/hooks';
import { formatTableRowsTheMostRecognitionsOfTheMonth, formatTableSimpleColumns, getPastMonth } from '../../../utils';
import { IRecognitionDashboardRankingTable, ICustomSimpleTableColumn } from '../../../common/types';

interface ITheMostRecognizedOfTheMonth {
  idioma: string;
  columns: IRecognitionDashboardRankingTable;
  isPermisosInDashboardWeb: boolean;
}
export const useTheMostRecognizedOfTheMonth = (props: ITheMostRecognizedOfTheMonth) => {
  const { idioma, columns, isPermisosInDashboardWeb } = props;

  const [fechaDesde, fechaHasta] = getPastMonth();
  const { dashboardRecognitionsRankingQuery } = useRecognition({
    dashboardRanking: {
      idioma,
      cantidadRegistros: RECOGNITIONS_DASHBOARD_RANKING_RECORDS_PER_PAGE,
      fechaDesde,
      fechaHasta,
      isPermisosInDashboardWeb,
    },
  });

  const theMostRecognizedOfTheMonthColumns: ICustomSimpleTableColumn[] = formatTableSimpleColumns(columns);

  const theMostRecognizedOfTheMonthRows: IRecognitionDashboardRankingTable[] =
    formatTableRowsTheMostRecognitionsOfTheMonth(dashboardRecognitionsRankingQuery.data ?? null);

  return {
    dashboardRecognitionsRankingQuery,
    theMostRecognizedOfTheMonthColumns,
    theMostRecognizedOfTheMonthRows,
  };
};
