import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '../../../context';
import { cliente, DEFAULT_STALE_TIME } from '../../constants/constants';
import { getClientCatalogoCountry } from '../../../services/clientCatalogoCountry';

export const useClientCatalogoCountry = () => {
  const appContext = useContext(AppContext);
  const idUsuario: number = appContext?.user?.idUsuario;

  const clientCatalogoCountryQuery = useQuery({
    queryKey: ['client-catalogo-country', { idUsuario }],
    queryFn: async () => await getClientCatalogoCountry(cliente),
    staleTime: DEFAULT_STALE_TIME,
  });

  return { clientCatalogoCountryQuery };
};
