import { CSSProperties } from 'styled-components';

export const SELECT_CURRENCY_STYLES = {
  mainContainer: {
    display: 'flex',
    paddingBottom: '10px',
    width: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
  } as CSSProperties,
  select: {
    width: '240px',
  } as CSSProperties,
};
