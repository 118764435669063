import { Backdrop, Box, Button, Fade, Modal, Typography } from '@mui/material';
import { NavigateFunction } from 'react-router-dom';
import { IRecognitionsDictionary } from '../../../../common/types/recognitions';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

type Props = {
  navigate: NavigateFunction;
  dictionary: IRecognitionsDictionary;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const RecognitionModal: React.FC<Props> = ({ isModalOpen, dictionary, setIsModalOpen, navigate }) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalOpen}
        sx={{ zIndex: 20000 }}
        slots={{ backdrop: Backdrop }}
      >
        <Fade in={isModalOpen}>
          <Box sx={style} textAlign={'center'}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {dictionary.form.modalTitle}
            </Typography>
            <Box marginTop={3} display={'flex'} gap={3} justifyContent={'center'}>
              <Button variant="contained" color="success" onClick={() => setIsModalOpen(!isModalOpen)}>
                {dictionary.form.create.buttons.add}
              </Button>
              <Button variant="contained" color="error" onClick={() => navigate('/')}>
                {dictionary.form.create.buttons.noAdd}
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
