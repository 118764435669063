import { CSSProperties } from 'styled-components';

export const STYLE_PRE_LOG_IN = {
  boxContainer: {
    minHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '50px',
  } as CSSProperties,
};
