import { API_CATALOGO_URL } from '../../common/constants';
import { Pais } from '../../common/interfaces/GiftCardInterfaces';
import { ICatalogo, ICatalogoProps } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getCatalogo = async ({ body, idCliente, paises }: ICatalogoProps): Promise<ICatalogo> => {
  const URL: string = API_CATALOGO_URL.catalogo.replace('{idCliente}', String(idCliente));

  try {
    if (body.paises === null) {
      const codigoPaises = paises?.map((el: Pais) => el.id);
      body = { ...body, paises: codigoPaises };
    }

    const response: ICatalogo = await fetchContent(URL, {
      method: 'POST',
      body: body,
      headers: { 'x-api-version': '2.0' },
    });

    return response;
  } catch (error) {
    console.error('[getCatalogo]: ', error);
    throw handleServiceError(error);
  }
};
