import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import useLanguage from "../../../common/hooks/useLanguage";
import ES from "../../../common/language/shared/es.json";
import EN from "../../../common/language/shared/en.json";

interface MenuDropdownProps {
  buttonText: string;
  buttonIcon: React.ReactNode;
  subMenu: { ruta: string; dictionario: string; codigo: string }[];
}

const MenuDropdown: React.FC<MenuDropdownProps> = ({
  buttonText,
  buttonIcon,
  subMenu,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const appContext = React.useContext(AppContext);
  const { dictionary } = useLanguage({ EN, ES });
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = (ruta?: string) => {
    setAnchorEl(null);
    if (ruta) {
      navigate(ruta);
    }
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="text"
        size="small"
        aria-controls={open ? "dropdown-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          textTransform: "capitalize",
          color: "white",
          margin: 0,
          padding: "0px !important",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
          variant="subtitle2"
        >
          {buttonIcon}
          {buttonText} {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Typography>
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        sx={{ mt: 1 }}
        MenuListProps={{
          "aria-labelledby": "dropdown-button",
        }}
      >
        {subMenu.filter((el) =>
          appContext?.permisos?.find((permiso) => permiso.codigo === el.codigo)
        ).map((el, index) => (
          <MenuItem key={index} onClick={() => handleClose(el.ruta)}>
            <Typography color="default">
              {dictionary?.header[el.dictionario]}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuDropdown;
