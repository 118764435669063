import { API_CLIENT_URL } from '../../common/constants';
import { ClientAmountPerPointsProps } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getAmountPerPoints = async ({
  idCliente,
  idUsuario,
  idTipoMovimiento,
  puntos,
}: ClientAmountPerPointsProps): Promise<number> => {
  const URL: string = API_CLIENT_URL.amountPerPoints
    .replace('{idCliente}', String(idCliente))
    .replace('{idUsuario}', String(idUsuario))
    .replace('{idTipoMovimiento}', String(idTipoMovimiento))
    .replace('{puntos}', String(puntos));

  try {
    const response: number = await fetchContent(URL, {
      method: 'GET',
    });

    return response;
  } catch (error: any) {
    console.error('[getAmountPerPoints]: ' + error);
    throw handleServiceError(error);
  }
};
