import React from 'react';
import fondoDefecto from '../../../../common/assets/images/Reconocimientos/fondoDefecto.jpg';
import { ITypeOfRecognitions } from '../../../../common/types/recognitions';
import { formatNumber } from '../../../../utils/functions';
import MiniLoader from '../../../../components/Loaders/MiniLoader/MiniLoader';

interface Props {
  data: ITypeOfRecognitions;
  loaded: boolean;
  isSelected: boolean;
  handleClick: (idTipoReconocimiento: number) => void;
  onLoad: () => void;
}

export const TypeOfRecognitionCard: React.FC<Props> = ({ data, loaded, isSelected, handleClick, onLoad }) => {
  return (
    <li
      className={`type-of-recognition-card ${isSelected && 'type-of-recognition-card--active'}`}
      onClick={() => handleClick(data.idTipoReconocimiento)}
    >
      <img
        style={{ display: loaded ? 'block' : 'none' }}
        onLoad={onLoad}
        src={data?.imagen ?? fondoDefecto}
        alt={data?.titulo}
        onError={(e) => e.currentTarget.setAttribute('src', fondoDefecto)}
      />
      {!loaded && <MiniLoader />}

      <div className="recognition-card-text">
        <div className="recognition-card-title">{data.descripcion}</div>
        <div style={{ fontWeight: 600 }}>{data?.puntos > 0 ? formatNumber(data.puntos) : 'Sin'} puntos</div>
      </div>
    </li>
  );
};
