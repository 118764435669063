import React from 'react';
import { Box, Typography } from '@mui/material';
import { TITLE_CONTAINER_STYLES } from '..';
import { TitleContainerProps } from '../../../../common/types';

interface Props {
  titleContainer: TitleContainerProps;
}

export const TitleContainer: React.FC<Props> = ({
  titleContainer: { brandDescription, isSale, discount, description },
}) => {
  return (
    <>
      <Box sx={TITLE_CONTAINER_STYLES.titleContainer}>
        <Typography color="initial" fontSize={35}>
          {brandDescription}
        </Typography>
        {isSale && (
          <Typography color="primary" fontSize={20} fontWeight={600} sx={TITLE_CONTAINER_STYLES.discount}>
            {discount}
          </Typography>
        )}
      </Box>

      <Typography variant="body1">{description}</Typography>
    </>
  );
};
