import { API_TRANSACTIONS_URL, headerOptions } from '../../common/constants';
import { NewTransactionResponse, PostTransaction } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const postTransaction = async (transaction: PostTransaction): Promise<NewTransactionResponse> => {
  const URL: string = API_TRANSACTIONS_URL.transaction;

  try {
    const newTransaction: NewTransactionResponse = await fetchContent(URL, {
      method: 'POST',
      body: JSON.stringify(transaction),
      headers: headerOptions,
    });
    return newTransaction;
  } catch (error: any) {
    console.error('[postTransaction]: ' + error);
    throw handleServiceError(error);
  }
};
