import { Box, Button, Typography, Modal, Divider, Grid } from '@mui/material/';
import { CardGiftcard as CardGiftcardIcon, Close as CloseIcon } from '@mui/icons-material';
import { GiftModalProps } from '../../../../common/types';
import './styles.css';

type Props = {
  giftModal: GiftModalProps;
};

export const GiftModal: React.FC<Props> = ({
  giftModal: {
    product,
    isError,
    precioHasta,
    isUniqueProduct,
    isRangeOfPrices,
    form: {
      title,
      buttons: { addToCart },
      inputs: { recipient, email, message },
    },
    isGiftModalOpen,
    handleOnClickModal,
    handleChange,
    handleSubmit,
  },
}) => {
  return (
    <Grid item sm={6} md={6}>
      <Button
        disabled={
          !product || (isError && !precioHasta && isUniqueProduct) || (isError && precioHasta && isRangeOfPrices)
        }
        variant="outlined"
        color="btnPrimary"
        fullWidth
        onClick={() => handleOnClickModal(isGiftModalOpen)}
      >
        {title}
      </Button>
      <Modal
        open={isGiftModalOpen}
        onClose={handleOnClickModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Box className="modalBox">
          <div className="closeBtnContainer">
            <Button variant="text" color="primary" onClick={() => handleOnClickModal(isGiftModalOpen)}>
              <CloseIcon />
            </Button>
          </div>
          <CardGiftcardIcon sx={{ fontSize: '70px' }} color="primary" />
          <Typography id="modal-modal-title" variant="h5" component="h3" color="primary">
            {title}
          </Typography>
          <Divider sx={{ width: '100%' }} />

          <div className="inputContainer">
            <Typography variant="subtitle1" color="primary">
              {recipient}
            </Typography>
            <input name="PersonaRegalo" onChange={(e) => handleChange(e)}></input>
          </div>
          <div className="inputContainer">
            <Typography variant="subtitle1" color="primary">
              {email}
            </Typography>
            <input name="Email" onChange={(e) => handleChange(e)}></input>
          </div>
          <div className="inputContainer">
            <Typography variant="subtitle1" color="primary">
              {message}
            </Typography>
            <textarea name="Comentario" onChange={(e) => handleChange(e)}></textarea>
          </div>

          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {addToCart}
          </Button>
        </Box>
      </Modal>
    </Grid>
  );
};
