import { SelectChangeEvent } from '@mui/material';
import { CryptoExchangeCurrencyTypes, CustomerOrdersFormatTable, ICustomSimpleTableColumn } from '.';

export interface LiriumCustomerOrders {
  orders: LiriumCustomerOrder[];
  pagination: Pagination;
}

export interface LiriumCustomerOrder {
  operation: OrderOperation;
  asset: Asset;
  id: string;
  state: State;
  created_at: Date;
  last_updated_at: Date;
  customer_id: string;
  submitted_at: Date;
  reference_id: string;
  buy: Buy | null;
}

interface Asset {
  currency: CryptoExchangeCurrencyTypes;
  amount: string;
  operation: AssetOperation;
}

enum AssetOperation {
  Credit = 'credit',
  Debit = 'debit',
}

interface Buy {
  settlement: Asset;
  customer: Customer;
  requires_confirmation_code: boolean;
  expires_at: Date;
}

interface Customer {
  currency: string;
  amount: string;
}

export enum OrderOperation {
  Buy = 'buy',
  Sell = 'sell',
}

enum State {
  Completed = 'completed',
}

interface Pagination {
  starting_after: string;
  ending_before: string;
  limit: number;
}

export interface TransactionsInCryptoProps {
  customerOrdersTableColumns: ICustomSimpleTableColumn[];
  customerOrdersTableRows: CustomerOrdersFormatTable[];
  isCustomerOrdersLoading: boolean;
  isCustomerOrdersError: boolean;
  noResults: string;
}

export interface LiriumOrdersExchangeRates {
  quote_currency: string;
  quotes: ExchangeRatesQuote[];
}

export interface ExchangeRatesQuote {
  currency: string;
  ask: string;
  bid: string;
}

export interface PostLiriumOrderAPIExternal {
  reference_id: string;
  operation: OrderOperation;
  asset: AssetProps;
  buy?: ExchangeProps;
  sell?: ExchangeProps;
}

export interface AssetProps {
  currency: CryptoExchangeCurrencyTypes;
  amount?: string;
}

interface ExchangeProps {
  settlement: Settlement;
}

interface Settlement {
  currency: SettlementCurrency;
  amount: string;
}

export enum SettlementCurrency {
  ARS = 'ARS',
  USD = 'USD',
}

export type BuyOrSell = PostLiriumOrderAPIExternalBuyOperation | PostLiriumOrderAPIExternalSellOperation;

interface PostLiriumOrderAPIExternalBuyOperation {
  buy: ExchangeProps;
}

interface PostLiriumOrderAPIExternalSellOperation {
  sell: ExchangeProps;
}

export interface LiriumOrderExternalAPI {
  id: string;
  operation: string;
  reference_id: string;
  state: string;
  created_at: Date;
  customer_id: string;
  last_updated_at: Date;
  asset: Asset;
  buy: LiriumOrderExternalAPIBuy | null;
  sell: LiriumOrderExternalAPIBuy | null;
}

interface LiriumOrderExternalAPIBuy {
  requires_confirmation_code: boolean;
  expires_at: Date;
  settlement: Asset;
}

export interface LiriumOrderInternalAPI {
  referenceId: string;
  id: string;
  idUsuario: number;
  fechaAlta: string;
  tipoOrden: string;
  monedaOrigen: string;
  montoOrigen: string;
  monedaDestino: string;
  montoDestino: string;
  montoOrigenDecimal: number;
  montoDestinoDecimal: number;
  estado: string;

  //REQUERIDOS EN BACK, PERO EL FRONT NO LO ENVÍA
  //TODO VER EL POR QUÉ
  externalcustomerId?: string;
  externalId?: string;
  idLiriumOrder?: number;

  //OPCIONALES EN BACK
  fechaActualizacion?: string;
  idMovimiento?: number;
}

export interface LiriumOrderTextFields {
  points: number;
  crypto: number;
}

export interface LiriumOrderInternalAPIResponse {
  idLiriumOrder: number;
  referenceId: string;
  id: string;
  idUsuario: number;
  fechaAlta: Date;
  tipoOrden: string;
  montoOrigen: string;
  monedaOrigen: string;
  montoDestino: string;
  monedaDestino: string;
  estado: string;
  externalcustomerId: null;
  externalId: null;
  fechaActualizacion: null;
  idMovimiento: null;
  montoOrigenDecimal: number;
  montoDestinoDecimal: number;
}

export interface ExternalAPIConfirmOrderProps {
  confirmation_code?: string;
  customer: Customer;
}

export interface ExternalAPIConfirmOrderResponse {
  confirmation_code: string | null;
  customer: Settlement | null;
  state: string;
}

export interface ConfirmOrderProps {
  idPersona: number;
  customerId: string;
  ordenId: string;
  currency: CryptoExchangeCurrencyTypes;
  amount: string;
  points: number;
  quantity: string;
  operation: string;
}

export interface CryptoPointsExchangerDictionary {
  buyCrypto: CryptoPointsExchanger;
  sellCrypto: CryptoPointsExchanger;
  buttons: Buttons;
  selectCurrency: string;
  exchangeRateFooter: string;
  linkText: string;
  emptyField: string;
  success: string;
  error: string;
}

interface CryptoPointsExchanger {
  title: string;
  form: Form;
  isSwitchingExchange: string;
}

interface Form {
  typeOfCurrency: TypeOfCurrency;
  points: Points;
}

interface Points {
  footer: string;
}

interface TypeOfCurrency {
  title: string;
  points: string;
}

export interface OrderExchangeDictionary {
  title: string;
  form: OrderExchangeForm;
  buttons: OrderExchangeFormButtons;
}

interface Buttons {
  next: string;
}

interface OrderExchangeFormButtons extends Buttons {
  resendEmail: string;
}

interface OrderExchangeForm {
  isEmailNotSent: string;
  success: string;
  error: string;
}

export interface SelectCurrencyProps {
  selectCurrencyTitle: string;
  currentCurrency: CryptoExchangeCurrencyTypes;
  selectCurrency: string;
  exchangeRates: LiriumOrdersExchangeRates;
  handleCurrencyOnSelect: (event: SelectChangeEvent) => void;
}

export interface ExchangeInputsProps {
  title: string;
  points: number;
  crypto: number;
  limit: number;
  pointsText: string;
  exchangeRateText: string;
  handleOnChange: (event: any, points: number) => void;
}

export interface ButtonSubmitProps {
  isExchangeDisabled: boolean;
  isRedeeming: boolean;
  buttonText: string;
  setIsRedeeming: (isRedeeming: boolean) => void;
  handleSubmit: () => Promise<void>;
}

export interface SwitchCryptoPointsExchangerProps {
  switchTitle: string;
  linkText: string;
  route: string;
}

export interface OrderExchangeFormProps {
  textFields: LiriumOrderTextFields;
}

export interface postLiriumOrderProps {
  idCustomer: string;
  currency: string;
  buyOrSell: BuyOrSell;
  operation: OrderOperation;
}

export interface ConfirmLiriumOrderProps {
  order: ConfirmOrderProps;
  successText: string;
  errorText: string;
}
export interface NewLiriumOrderProps {
  order: NewLiriumOrder;
  successMessage: string;
  errorMessage: string;
}

interface NewLiriumOrder {
  idCustomer: string;
  idUsuario: number;
  operation: OrderOperation;
  asset: AssetProps;
  buyOrSell: BuyOrSell;
}
