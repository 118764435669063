import { API_OH_GIFT_CARD_URL } from '../../common/constants';
import { OhGiftCard } from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const getBranches = async (codigoProductoProveedor: string): Promise<OhGiftCard[]> => {
  const URL: string = API_OH_GIFT_CARD_URL.branches.replace('{codigoProductoProveedor}', codigoProductoProveedor);

  try {
    const response: OhGiftCard[] = await fetchContent(URL, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.error('[getBranches]: ' + error);
    throw handleServiceError(error);
  }
};
