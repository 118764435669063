import { IMyTeamData, IMyTeamFormatTableRow } from '../../common/types/recognitions';

export const myTeamFormatTableRows = (rows: IMyTeamData[]): IMyTeamFormatTableRow[] => {
  return rows?.map(({ nombre, apellido, email }) => {
    return {
      nombre,
      apellido,
      email,
    };
  });
};
