import { Box, Typography } from '@mui/material';
import ShortLine from '../ShortLine/ShortLine';
import CustomSearch from '../CustomSearch/CustomSearch';
import CustomTable from '../CustomTable/CustomTable';
import { ITable } from '../../common/types';

export const ViewTable = (table: ITable) => {
  const {
    title,
    searchBarPlaceHolder,
    isLoading,
    rows,
    page,
    totalPages,
    noResults,
    columns,
    sort,
    actions,
    showSearchBar,
    details,
    navbar,
    changeSort,
    setSearch,
    setPage,
    handleChangePage,
  } = table;

  return (
    <Box sx={{ padding: '30px 20px' }}>
      <Typography
        variant="h4"
        color="primary"
      >
        {title}
        <ShortLine position="left" />
      </Typography>

      <Box padding={'0 0 20px 0'}>{details && details}</Box>

      <Box
        border={2}
        borderColor={'primary.main'}
        borderRadius={3}
        padding={'30px'}
        width={'100%'}
      >
        {navbar && navbar}

        {showSearchBar && (
          <CustomSearch
            placeHolder={searchBarPlaceHolder}
            setSearch={setSearch}
            setPage={setPage}
          />
        )}

        <CustomTable
          columns={columns}
          data={rows}
          isLoading={isLoading}
          page={page}
          totalPages={totalPages}
          sort={sort}
          noResults={noResults}
          handleChangePage={handleChangePage}
          changeSort={changeSort}
        />

        {actions && actions}
      </Box>
    </Box>
  );
};
