import {
  IRecognitionsData,
  IApprovedRecognitionsRows,
  IApprovedRecognitionsActions,
} from '../../common/types/recognitions';
import { formatNumber } from '../functions';

export const showButtonApprove = (data: any[], status: string): boolean => {
  const isAnyPending = data?.some((el) => el.estado === status);
  return isAnyPending;
};

export const approvedRecognitionsFormatTableRows = (
  rows: IRecognitionsData[],
  actions: IApprovedRecognitionsActions[]
): IApprovedRecognitionsRows[] => {
  return rows?.map(
    ({
      idReconocimiento,
      fechaString,
      descripcionTipoReconocimiento,
      nombreUsuarioAsignador,
      nombreUsuarioReconocido,
      puntos,
    }) => {
      return {
        idReconocimiento,
        aprobar: actions.find((el) => el.idReconocimiento === idReconocimiento)?.aprobarIcono,
        fechaString: fechaString ?? '-',
        descripcionTipoReconocimiento,
        nombreUsuarioAsignador,
        nombreUsuarioReconocido,
        puntos: formatNumber(puntos) || '-',
        estado: actions.find((el) => el.idReconocimiento === idReconocimiento)?.estadoBoton,
        descripcion: actions.find((el) => el.idReconocimiento === idReconocimiento)?.detalle,
      };
    }
  );
};
