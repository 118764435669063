import React from 'react';
import Swal from 'sweetalert2';
import { IButtonDetail } from '../../common/types';
import './styles.css';

export const ViewDetail: React.FC<IButtonDetail> = ({ title, text, cancelButtonText, image, html }): React.ReactElement => {
 
  Swal.fire({
    position: window.screen.width > 600 ? 'center' : 'top',
    title,
    text,
    html,
    showCancelButton: true,
    cancelButtonText,
    imageHeight: '19rem',
    showConfirmButton: false,
    imageUrl: image || null,
    allowEscapeKey: true,
    customClass: {
      popup: 'popup',
      title: 'title',
      cancelButton: 'cancelBtn',
    },
  });
  return <></>;
};
 