import { CSSProperties } from 'styled-components';

export const EXCHANGE_FORM_CONTAINER_STYLES = {
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
    margin: 1,
    minWidth: 120,
    width: '100%',
  } as CSSProperties,
};
