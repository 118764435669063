import { Routes, Route } from 'react-router-dom';
import { ThemeConfig } from './context/ThemeContext';
import { Loading } from './components';
import GlobalStyles from './globals';
import Layout from './components/Layout/Layout';
import Login from './pages/Login/Login';
import RecuperarForm from './pages/Login/components/RecuperarForm/RecuperarForm';
import LoginForm from './pages/Login/components/LoginForm/LoginForm';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import { LOGGED_ROUTER_DOM_ROUTES, ROUTER_DOM_ROUTES } from './common/constants/rutas';
import CambioPassword from './pages/Login/components/RecuperarForm/CambioPassword';
import PrivateRoute from './components/PrivateRoute';
import { Home } from './pages/Home';
import { useApp } from './hooks/app/useApp';
import './App.css';

function App() {
  const { theme, footer, user, permissions, isLoading, themeContext } = useApp();

  return (
    <>
      <GlobalStyles
        bgHeader={themeContext?.theme ? themeContext?.theme[4]?.color : null}
        bgFooter={themeContext?.theme ? themeContext?.theme[5]?.color : null}
        colorTitle={themeContext?.theme ? themeContext?.theme[0]?.color : null}
        colorDefault={themeContext?.theme ? themeContext?.theme[1]?.color : null}
        bgLowerBar={themeContext?.theme ? themeContext?.theme[8]?.color : null}
        bgMainButton={themeContext?.theme ? themeContext?.theme[2]?.color : null}
        colorFooter={themeContext?.theme ? themeContext?.theme[7]?.color : null}
        footerLane={themeContext?.theme ? themeContext?.theme[6]?.color : null}
      />
      <ThemeConfig colors={theme}>
        <Routes>
          <Route path="/" element={<Layout footerInfo={footer} />}>
            <Route index element={<Home />} />
            <Route element={<PrivateRoute />}>
              <Route index element={isLoading ? <Loading /> : <Home />} />
              {/*  rutas LOGGEADO  */}
              {user &&
                LOGGED_ROUTER_DOM_ROUTES.filter((route) =>
                  permissions?.find((permiso) => permiso.codigo === route.codigo)
                ).map((route) => <Route key={crypto.randomUUID()} path={route.ruta} element={<route.componente />} />)}
              <Route path="*" element={<ErrorPage />} />
            </Route>
            {ROUTER_DOM_ROUTES.map((el) => (
              <Route key={crypto.randomUUID()} path={el.ruta} element={<el.componente />} />
            ))}
          </Route>
          {/* paginas LOGIN */}
          <Route
            path="/login"
            element={
              isLoading ? (
                <Loading />
              ) : (
                <Login
                  theme={theme}
                  logoProp={{
                    logoUrl: footer?.logoUrl ?? '',
                    tipoImagenLogo: footer?.tipoImagenLogo ?? '',
                    logo: footer?.logo ?? '',
                  }}
                />
              )
            }
          >
            <Route index element={<LoginForm />} />
            <Route path="/login/recuperar" element={<RecuperarForm />} />

            <Route
              path="cambio-password"
              element={
                <CambioPassword
                  theme={theme}
                  logoProp={{
                    logoUrl: footer?.logoUrl ?? '',
                    tipoImagenLogo: footer?.tipoImagenLogo ?? '',
                    logo: footer?.logo ?? '',
                  }}
                />
              }
            />
          </Route>
          <Route
            path="/web/PrimerAcceso/index"
            element={
              <CambioPassword
                theme={theme}
                logoProp={{
                  logoUrl: footer?.logoUrl ?? '',
                  tipoImagenLogo: footer?.tipoImagenLogo ?? '',
                  logo: footer?.logo ?? '',
                }}
              />
            }
          />
        </Routes>
      </ThemeConfig>
    </>
  );
}

export default App;
