import { useQuery } from '@tanstack/react-query';
import { HistoricalPriceByCurrencyTypeProps } from '../../types';
import { getHistoricalPriceByCurrencyType } from '../../../services/lirium_historical_price';

interface LiriumHistoricalPrice {
  historicalPrice?: HistoricalPriceByCurrencyTypeProps;
}

export const useLiriumHistoricalPrice = ({ historicalPrice }: LiriumHistoricalPrice) => {
  const currencyPriceQuery = useQuery({
    queryKey: [
      'historical-price-by-currency-type',
      { currency: historicalPrice.currency, currencyType: historicalPrice.currencyType },
    ],
    queryFn: async () => await getHistoricalPriceByCurrencyType(historicalPrice),
    enabled: Boolean(historicalPrice),
  });

  return { currencyPriceQuery };
};
