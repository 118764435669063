import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import InputForm from "../../../../components/Inputs/InputForm/InputForm";
import {
  GetLocalidadByProvinciaForSelect,
  getPaisForSelect,
  getProvinciaForSelect,
} from "../../../../services/MisDatosService";
import {
  PaisInterface,
  localidadInterface,
  provinciaInterface,
} from "../../../../common/interfaces/MisDatosInterface";
import Loading from "../../../../components/Loaders/Loading";
import "./DatosDemograficos.css";
import useLanguage from "../../../../common/hooks/useLanguage";
import ES from "../../../../common/language/users/es.json";
import EN from "../../../../common/language/users/en.json";

type Props = {
  form: any;
  setForm: Dispatch<SetStateAction<any>>;
};
const DatosDemograficos: React.FC<Props> = ({ form, setForm }) => {
  const [paises, setPaises] = useState<PaisInterface[] | null>(null);
  const [provincias, setProvincias] = useState<provinciaInterface[] | null>(
    null
  );
  const [localidades, setLocalidades] = useState<localidadInterface[] | null>(
    null
  );

  const { dictionary } = useLanguage({ EN, ES });

  const [paisOption, setPaisOption] = useState<PaisInterface | null>(null);
  const [provinciaOption, setProvinciaOption] =
    useState<provinciaInterface | null>(null);
  const [localidadesOption, setLocalidadesOption] =
    useState<localidadInterface | null>(null);

  useEffect(() => {
    getPaisForSelect()
      .then((dataPais) => {
        setPaises(dataPais);
        getProvinciaForSelect().then((dataProv) => {
          setPaisOption(
            dataPais.filter((el: PaisInterface) => el.id === form.pais)[0]
          );
          setProvinciaOption(
            dataProv.filter(
              (el: provinciaInterface) => el.id === form.idProvincia
            )[0]
          );
          form.idProvincia &&
            form.idProvincia.trim() !== "" &&
            GetLocalidadByProvinciaForSelect(form.idProvincia).then(
              (dataLocalidades) => {
                setLocalidades(dataLocalidades);
                setLocalidadesOption(
                  dataLocalidades.filter(
                    (el: localidadInterface) => el.id === form.idLocalidad
                  )[0]
                );
              }
            );
          setProvincias(dataProv);
        });
      })
      .catch(() => console.error("Error al traer la información geográfica"));
  }, [form]);

  const handlePais = (event: any, value: any) => {
    const selectedId = value ? value.id : null;
    setPaisOption(value);
    setForm({ ...form, idPais: selectedId, pais: value.nombre }); // Aquí puedes hacer lo que necesites con el valor del id seleccionado
  };

  const handleProvincia = (event: any, value: any) => {
    const selectedId = value ? value.id : null;
    setProvinciaOption(value);
    GetLocalidadByProvinciaForSelect(selectedId)
      .then((dataLocalidades) => setLocalidades(dataLocalidades))
      .catch(() => console.error("error al traer la localidades"));
    setForm({ ...form, idProvincia: selectedId, Provincia: selectedId }); // Aquí puedes hacer lo que necesites con el valor del id seleccionado
  };

  const handleLocalidad = (event: any, value: any) => {
    const selectedId = value ? value.id : null;
    setLocalidadesOption(value); // Aquí puedes hacer lo que necesites con el valor del id seleccionado
    setForm({ ...form, idLocalidad: selectedId, Localidad: selectedId });
  };

  return (
    <>
      {!provincias && !paises ? (
        <Loading />
      ) : (
        <div className="datos-demograficos-section">
          <Typography variant="h5" color="initial" paddingBottom={"20px"}>
            {dictionary?.datosDemograficos.titulo}
          </Typography>
          <Divider sx={{ marginBottom: "30px" }} />
          <Grid container columns={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              {/* select con search */}
              {/* <div> */}
              <div className="input-container">
                <label>
                  {dictionary?.perfil.pais}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete
                  id="country-select-demo"
                  fullWidth
                  size="small"
                  sx={{
                    height: "40px",

                    "& .MuiAutocomplete-option::before": {
                      transition: "none !important",
                    },
                    "& .MuiAutocomplete-option::after": {
                      transition: "none",
                    },
                  }}
                  options={paises ? paises : []}
                  autoHighlight
                  value={paisOption}
                  onChange={handlePais}
                  getOptionLabel={(option) => option.nombre}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        "& > img": {
                          mr: 2,
                          flexShrink: 0,
                        },
                        transition: "none !important",
                      }}
                      {...props}>
                      {option.nombre}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={
                        paisOption
                          ? ""
                          : dictionary?.datosDemograficos.seleccionarPais
                      }
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>

              <div className="input-container">
                <label>
                  {dictionary?.perfil.localidad}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete
                  disabled={!localidades ? true : false}
                  id="localidad-select"
                  fullWidth
                  size="small"
                  options={localidades ? localidades : []}
                  autoHighlight
                  value={localidadesOption}
                  onChange={handleLocalidad}
                  getOptionLabel={(option) =>
                    option.nombre ? option.nombre : ""
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}>
                      {option.nombre}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={
                        localidadesOption
                          ? ""
                          : dictionary?.datosDemograficos.seleccionarProvincia
                      }
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
              {/* </div> */}
              <InputForm
                readonly={false}
                name="domicilio"
                required
                label={dictionary?.perfil.calle}
                type="text"
                form={form}
                setForm={setForm}
              />
              <InputForm
                readonly={false}
                name="callePiso"
                label={dictionary?.perfil.piso}
                type="text"
                form={form}
                setForm={setForm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="input-container">
                <label>
                  {dictionary?.perfil.provincia}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete
                  id="provincia-select"
                  fullWidth
                  size="small"
                  options={provincias ? provincias : []}
                  autoHighlight
                  value={provinciaOption}
                  onChange={handleProvincia}
                  getOptionLabel={(option) => option.nombre}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}>
                      {option.nombre}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={
                        provinciaOption
                          ? ""
                          : dictionary?.datosDemograficos.seleccionarLocalidad
                      }
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
              <InputForm
                readonly={false}
                name="cp"
                label={dictionary?.perfil.codigoPostal}
                type="text"
                required
                form={form}
                setForm={setForm}
              />
              <InputForm
                readonly={false}
                name="calleAltura"
                label={dictionary?.perfil.calleAltura}
                type="text"
                form={form}
                setForm={setForm}
              />
              <InputForm
                readonly={false}
                name="calleDepartamento"
                label={dictionary?.perfil.departamento}
                type="text"
                form={form}
                setForm={setForm}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default DatosDemograficos;
