import { CardPoints } from '../../../components/Home';
import { PointsCard, ProgressBar } from '..';
import { IFinancialEducation, IFormatCardPoint, IHomeDictionary } from '../../../common/types';

interface IDashboardPointsCardContainer {
  points: IFormatCardPoint[];
  dictionary: IHomeDictionary;
  educationLevel: IFinancialEducation;
}

export const DashboardPointsCardContainer: React.FC<IDashboardPointsCardContainer> = ({
  points,
  dictionary,
  educationLevel,
}) => {
  return (
    <>
      {points?.map((card) => {
        if (!card.isPermisos) return null;

        return (
          <PointsCard
            key={card.title}
            card={
              card.isPointsCard ? (
                <CardPoints points={card.points as number} subtitle={card.subtitle} />
              ) : (
                <ProgressBar
                  subtitle={dictionary.cards.financialEducationLevel.subtitle}
                  body={dictionary.cards.financialEducationLevel.body}
                  percentage={card.points as number}
                  footer={dictionary.cards.financialEducationLevel.footer}
                  status={educationLevel?.estado}
                  idEducationLevel={educationLevel?.idNivelEducacion}
                  levelsCompleted={dictionary.cards.financialEducationLevel.levelsCompleted}
                />
              )
            }
            isLoading={card.isLoading}
            isError={card.isError}
            title={card.title}
            noResults={card.noResults}
            route={card.route}
          />
        );
      })}
    </>
  );
};
