import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '../../../context';
import { DEFAULT_STALE_TIME, MOST_CONSUMED_PRODUCTS } from '../../constants/constants';
import { ICategoriesRedeemedProps } from '../../types';
import { getCategoriesRedeemed } from '../../../services/transactions/getCategoriesRedeemed';

interface ICategoriesRedeemed {
  redeemedCategories?: ICategoriesRedeemedProps;
}

export const useTransactionsDashboard = ({ redeemedCategories }: ICategoriesRedeemed) => {
  const appContext = useContext(AppContext);
  const idUsuario: number = appContext?.user?.idPersona;
  const idPersona: number = appContext?.user?.idPersona;

  const categoriesRedeemedQuery = useQuery({
    queryKey: ['dashboard-redeemed-categories-idPersona', idPersona],
    queryFn: async () =>
      await getCategoriesRedeemed({
        idPersona,
        idioma: redeemedCategories.idioma,
        ordenamiento: MOST_CONSUMED_PRODUCTS.sort,
        limit: MOST_CONSUMED_PRODUCTS.limit,
      }),
    staleTime: DEFAULT_STALE_TIME,
    enabled: Boolean(idUsuario && redeemedCategories),
  });

  return { categoriesRedeemedQuery };
};
