import React from 'react';
import { Box, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { CryptoExchangeCurrencyTypes, LiriumOrdersExchangeRates } from '../../../../common/types';
import { SELECT_CURRENCY_STYLES } from '../..';

interface SelectCurrencyProps {
  title: string;
  selectCurrency: string;
  currentCurrency: CryptoExchangeCurrencyTypes;
  handleCurrencyOnSelect: (event: SelectChangeEvent) => void;
  exchangeRates: LiriumOrdersExchangeRates;
}

export const SelectCurrency: React.FC<SelectCurrencyProps> = ({
  title,
  currentCurrency,
  selectCurrency,
  exchangeRates,
  handleCurrencyOnSelect,
}) => {
  return (
    <Box borderBottom={2} borderColor={'primary.main'} sx={SELECT_CURRENCY_STYLES.mainContainer}>
      <Typography variant="h6" color="primary" width={'150px'}>
        {title}
      </Typography>
      <Select
        sx={SELECT_CURRENCY_STYLES.select}
        value={currentCurrency || 'default'}
        onChange={handleCurrencyOnSelect}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value="default" disabled>
          {selectCurrency}
        </MenuItem>

        {exchangeRates?.quotes
          .filter((exchangeRate) => exchangeRate.currency !== 'GTETH' && exchangeRate.currency !== 'TBTC')
          .map(({ currency }) => (
            <MenuItem key={`menu-item-${currency}`} value={currency}>
              {currency}
            </MenuItem>
          ))}
      </Select>
    </Box>
  );
};
