import CatalogoCard from '../CatalogoCard/CatalogoCard';
import './CatalogoCards.css';
import { ICatalogoData } from '../../../../common/types';

type Props = {
  productos: ICatalogoData[];
};

const CatalogoCards: React.FC<Props> = ({ productos }) => {
  return (
    <div className="catalogo-cards-container-width">
      <div className="catalogo-cards-container">
        {productos && productos.map((prod) => <CatalogoCard info={prod} key={`prod-${prod.idProducto}`} />)}
      </div>
    </div>
  );
};

export default CatalogoCards;
