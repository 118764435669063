import { IRecentTransaction, IRecentTransactionTable } from '../../common/types';
import { formatNumber } from '../functions';

export const formatCustomSimpleTableRows = (rows: IRecentTransaction[]): IRecentTransactionTable[] => {
  return rows?.map(({ idMovimiento, fechaCorta, tipoMovimientoDescripcion, puntos }) => {
    return {
      idMovimiento,
      fecha: fechaCorta ?? '-',
      tipoMovimientoDescripcion,
      puntos: formatNumber(puntos) || '-',
    };
  });
};
