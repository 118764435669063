import { linearProgressClasses } from '@mui/material';
import { CSSProperties } from 'styled-components';

export const STYLES_MOST_CONSUMED = {
  borderLinearProgress: (color: string) =>
    ({
      height: '10px',
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: color,
      },
    } as CSSProperties),
  mostConsumedContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px',
  } as CSSProperties,
  categories: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  } as CSSProperties,
  progressBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '70%',
  } as CSSProperties,
  percentage: {
    width: '10%',
  } as CSSProperties,
  noResults: { 
   color: "primary",
   marginTop: "0"
  } as CSSProperties
};
