import { useSellCrypto } from '../../../hooks/crypto';
import { CryptoPointsExchanger } from '..';
import { RUTAS } from '../../../common/constants/rutas';

export const SellCrypto = () => {
  const {
    balance,
    currentCurrency,
    dictionary,
    exchangeRates,
    textFields,
    order,
    isRedeeming,
    isExchangeDisabled,
    pointsPerAmount,
    setIsRedeeming,
    handleCurrencyOnSelect,
    handlePointsOnChange,
    handleSellCrypto,
  } = useSellCrypto();

  return (
    <CryptoPointsExchanger
      mainTitle={dictionary.sellCrypto.title}
      order={order}
      selectCurrency={{
        selectCurrencyTitle: dictionary.sellCrypto.form.typeOfCurrency.title,
        currentCurrency,
        selectCurrency: dictionary.selectCurrency,
        exchangeRates,
        handleCurrencyOnSelect,
      }}
      exchangeInputs={{
        title: dictionary.sellCrypto.form.typeOfCurrency.points.replace('{n}', String(balance)),
        points: textFields.points,
        crypto: textFields.crypto,
        pointsText: dictionary.sellCrypto.form.points.footer,
        exchangeRateText: dictionary.exchangeRateFooter,
        limit: pointsPerAmount,
        handleOnChange: handlePointsOnChange,
      }}
      buttonSubmit={{
        isExchangeDisabled,
        isRedeeming,
        buttonText: dictionary.buttons.next,
        setIsRedeeming,
        handleSubmit: handleSellCrypto,
      }}
      switchCryptoPointsExchanger={{
        switchTitle: dictionary.sellCrypto.isSwitchingExchange,
        linkText: dictionary.linkText,
        route: RUTAS.compraCripto,
      }}
      orderExchangeForm={{
        textFields,
      }}
    />
  );
};
