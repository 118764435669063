import { IRecognitionPagination, IRecognitionSearch, ISortDirections } from './share';

export interface IMyTeamFormatTableColumn {
  nombre: string;
  apellido: string;
  email: string;
}

export interface IMyTeamFormatTableRow extends IMyTeamFormatTableColumn {}

interface Form {
  search: IRecognitionSearch;
}

interface Table {
  column: IMyTeamFormatTableColumn;
  row: IMyTeamFormatTableRow;
  loading: string;
  noResult: string;
}

interface Details {
  subtitle: string;
}

export interface IMyTeamDictionary {
  title: string;
  details: Details;
  table: Table;
  pagination: IRecognitionPagination;
  form: Form;
}

export interface IMyTeamData {
  idUsuario: number;
  id: number;
  nombre: string;
  apellido: string;
  email: string;
  intentosFallidos: number;
  bloqueado: boolean;
  cultura: string;
  usuarioDvh: string;
  expiredToken: Date;
  token: null;
  avatar: null | string;
  dni: string;
  idRol: number;
  esSupervisor: boolean | null;
  idUsuarioSupervisor: number;
  idCliente: null;
  url: null;
  fechaIngreso: null;
  esEmpleado: null;
  idCategoriaUsuario: null;
  idNivelUsuario: null;
  idUsuarioCliente: null;
  fechaIngresoEmpresa: null;
  idSector: number | null;
  fechaAlta: Date;
  idUsuarioReferidor: number | null;
  gestionaDesbloqueos: boolean | null;
  fechaUltLogin: Date | null;
  estado: number;
  esAdmin: null;
}

export interface IMyTeam {
  cantidadTotalRegistros: number;
  cantidadPaginas: number;
  data: IMyTeamData[];
  cantidadRegistros: number;
  pagina: number;
}

export enum Action {
  Resta = 'RESTA',
  Suma = 'SUMA',
}

export interface IdMovementNavigationType {
  idTipoMovimiento: number;
  id: number;
  descripcion: string;
  accion: Action;
  esIngreso: boolean;
}

export interface Movement {
  idMovimientoPresupuesto: number;
  idCliente: number;
  idMovimientoDetalle: number | null;
  puntos: number;
  idTipoMovimiento: number;
  idUsuario: number;
  idSupervisor: number;
  fechaAlta: Date;
  comentarios: null | string;
  idMovimientoDetalleNavigation: null;
  idTipoMovimientoNavigation: IdMovementNavigationType;
}

export interface DetailBudgetMovements {
  saldo: number;
  saldoSuma: number;
  saldoResta: number;
  movimientos: Movement[];
}

export interface IMyTeamSupervisorBudget {
  idSupervisor: number;
  idUsuario: number;
  idPersona: number;
  idSector: number;
  nombre: string;
  apellido: string;
  email: string;
  puntos: number;
  presupuesto: number;
  clientes: number[];
  detalleMovimientosPresupuesto: DetailBudgetMovements;
}

export enum MyTeamSortColumns {
  nombre = 'nombre',
  apellido = 'apellido',
}

export enum MyTeamExcludeSortingColumns {
  email = 'email',
}

export interface IMyTeamSort {
  column: MyTeamSortColumns;
  direction: ISortDirections;
}
