import { ViewTable } from '../../../components/ViewTable/ViewTable';
import { useMadeRecognitions } from '../../../hooks/recognitions';

export const MadeRecognitions = () => {
  const {
    madeRecognitions,
    isLoading,
    dictionary,
    columns,
    page,
    sort,
    totalPages,
    handleChangePage,
    changeSort,
    setPage,
    setSearch,
  } = useMadeRecognitions();

  return (
    <ViewTable
      title={dictionary.title}
      searchBarPlaceHolder={dictionary.form.search.placeHolder}
      isLoading={isLoading}
      rows={madeRecognitions}
      page={page}
      totalPages={totalPages}
      noResults={dictionary.table.noResult}
      columns={columns}
      sort={sort}
      showSearchBar={true}
      setPage={setPage}
      changeSort={changeSort}
      setSearch={setSearch}
      handleChangePage={handleChangePage}
    />
  );
};
