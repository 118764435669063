import { ITransactionDetailFormateadData } from '../../common/types';
import { formatDate, formatNumber } from '..';

export const formatCommonTransactionDetail = (date: Date, points: number): ITransactionDetailFormateadData => {
  const isPointsGreaterThanZero: boolean = Boolean(points >= 1);

  const formateadTransaction: ITransactionDetailFormateadData = {
    date: formatDate(date),
    points: isPointsGreaterThanZero ? formatNumber(points) : '-',
  };

  return formateadTransaction;
};
