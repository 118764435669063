import { API_LIRIUM_ORDER_URL } from '../../common/constants';
import { LiriumCustomerOrders } from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const getLiriumCustomerOrders = async (idCustomer: string): Promise<LiriumCustomerOrders> => {
  try {
    const URL: string = API_LIRIUM_ORDER_URL.ordersByIdCustomer.replace('{idCustomer}', String(idCustomer));

    const orders: LiriumCustomerOrders = await fetchContent(URL, {
      method: 'GET',
    });

    return orders;
  } catch (error: any) {
    console.error('[getLiriumCustomerOrders]: ' + error);
    throw handleServiceError(error);
  }
};
