export const ERROR_MESSAGES: { [key: number]: string } = {
  204: 'La solicitud es inválida o ha expirado. Por favor, inténtalo de nuevo.',
  400: 'Solicitud incorrecta: \nLa solicitud no pudo ser procesada debido a una sintaxis inválida.',
  401: 'No autorizado: \nLa solicitud requiere autenticación del usuario.',
  403: 'Prohibido: \nNo tiene permisos para acceder a este recurso.',
  404: 'No encontrado: \nEl recurso solicitado no se pudo encontrar.',
  408: 'Tiempo de espera agotado: \nEl servidor agotó el tiempo de espera de la solicitud.',
  422: 'Entidad no procesable: \n Datos de solicitud inválidos. \n Compruebe la solicitud e inténtelo de nuevo',
  429: 'Demasiadas solicitudes: \nHa enviado demasiadas solicitudes en un periodo corto de tiempo.',
  500: 'Error interno del servidor: \nOcurrió un error inesperado en el servidor.',
  502: 'Bad Gateway: \nEl servidor recibió una respuesta inválida mientras actuaba como puerta de enlace o proxy.',
  503: 'Servicio no disponible: \nEl servidor no está disponible en este momento (sobrecargado o en mantenimiento).',
  504: 'Gateway Timeout: \nEl servidor no recibió una respuesta a tiempo mientras actuaba como puerta de enlace o proxy.',
};
