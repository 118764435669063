import React from 'react';
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  SelectChangeEvent,
} from '@mui/material';
import { RedeemOutlined as RedeemOutlinedIcon, AttachMoney as AttachMoneyIcon } from '@mui/icons-material';
import { formatNumber } from '../../../../utils';
import { Product } from '../../../../common/types';
import { SELECT_GIFT_CARD_STYLES } from '..';

type Props = {
  products: Product[];
  giftCardPrice: string;
  selectAGiftCardButtonText: string;
  handleOnSelectGiftCardChange: (event: SelectChangeEvent) => void;
  handleOnClick: (product: Product) => void;
};

const DefaultSelectedMenuItem = ({ isDisabled, text }: { isDisabled: boolean; text: string }) => {
  return (
    <MenuItem disabled={isDisabled} value="">
      <Typography sx={SELECT_GIFT_CARD_STYLES.valueContainer}>
        <RedeemOutlinedIcon />
        {text}
      </Typography>
    </MenuItem>
  );
};

const MenuItemOptions = ({
  key,
  value,
  option,
  onClick,
}: {
  value: number;
  option: string;
  key?: number;
  onClick?: () => void;
}) => {
  return (
    <MenuItem key={key || 'menu-item-option'} value={value} onClick={onClick ?? undefined}>
      <ListItemIcon>
        <AttachMoneyIcon />
      </ListItemIcon>
      <ListItemText primary={option} />
    </MenuItem>
  );
};

export const SelectGiftCard: React.FC<Props> = ({
  products,
  giftCardPrice,
  selectAGiftCardButtonText,
  handleOnSelectGiftCardChange,
  handleOnClick,
}) => {
  return (
    <FormControl sx={SELECT_GIFT_CARD_STYLES.formControl}>
      <Box sx={SELECT_GIFT_CARD_STYLES.mainContainer}>
        <Select
          labelId="gift-card-select-label"
          size="small"
          displayEmpty
          value={giftCardPrice}
          onChange={handleOnSelectGiftCardChange}
          input={<OutlinedInput />}
          MenuProps={SELECT_GIFT_CARD_STYLES.menuProps}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <DefaultSelectedMenuItem isDisabled={false} text={selectAGiftCardButtonText} />;
            }
            return <MenuItemOptions value={Number(selected)} option={selected} />;
          }}
        >
          <DefaultSelectedMenuItem isDisabled={true} text={selectAGiftCardButtonText} />;
          {products?.map((product) => {
            return (
              <MenuItemOptions
                key={product?.idProducto}
                value={product?.precio}
                option={formatNumber(product?.precio)}
                onClick={() => handleOnClick(product)}
              />
            );
          })}
        </Select>
      </Box>
    </FormControl>
  );
};
