import { Box, Grid, Typography } from '@mui/material';
import ShortLine from '../../../components/ShortLine/ShortLine';
import { DashboardPointsCardContainer, DashboardSummaryTable, MostConsumedProducts } from '..';
import { RUTAS } from '../../../common/constants/rutas';
import { STYLES_DASHBOARD } from './styles';
import { useHome } from '../../../hooks/home';

export const Dashboard = () => {
  const { dictionary, points, educationLevel, isPermisosInCrypto, categoriesRedeemedQuery, dashboardSummaryTableData } =
    useHome();

  return (
    <Box sx={STYLES_DASHBOARD.dashboardContainer}>
      <Box sx={STYLES_DASHBOARD.titleContainer}>
        <Typography variant="h4" color="primary">
          {dictionary.dashboard}
          <ShortLine position="left" />
        </Typography>
      </Box>

      <Box sx={STYLES_DASHBOARD.contentContainer}>
        <Grid container spacing={2}>
          <DashboardPointsCardContainer points={points} dictionary={dictionary} educationLevel={educationLevel} />
          <MostConsumedProducts
            categoriesRedeemed={categoriesRedeemedQuery.data ?? null}
            isLoading={categoriesRedeemedQuery.isLoading}
            isError={categoriesRedeemedQuery.isError}
            title={dictionary.tables.mostConsumedProducts.title}
            isPermisos={isPermisosInCrypto}
            noResults={dictionary.noResults}
            route={RUTAS.catalogo}
          />
          {dashboardSummaryTableData?.map(
            ({ columns, rows, isPermisos, isRendering, isError, isLoading, noResults, route, title }) => {
              if (!isRendering) return null;

              return (
                <DashboardSummaryTable
                  key={title}
                  columns={columns}
                  rows={rows}
                  title={title}
                  isLoading={isLoading}
                  isError={isError}
                  noResults={noResults}
                  route={route}
                  isPermisos={isPermisos}
                />
              );
            }
          )}
        </Grid>
      </Box>
    </Box>
  );
};
