import { API_PERMISSIONS_URL } from '../../common/constants';
import { PermissionsEnvironment } from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const getPermissionsByEnvironment = async (
  idUser: number,
  environment: string
): Promise<PermissionsEnvironment[]> => {
  const URL: string = API_PERMISSIONS_URL.environment
    .replace('{idUsuario}', String(idUser))
    .replace('{entorno}', environment);

  try {
    const permissions: PermissionsEnvironment[] = await fetchContent(URL);

    return permissions;
  } catch (error: any) {
    console.error('[getPermissionsByEnvironment]: ' + error);
    throw handleServiceError(error);
  }
};
