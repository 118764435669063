import React from 'react';
import { Button, Grid } from '@mui/material';
import { PinDropOutlined as PinDropOutlinedIcon } from '@mui/icons-material';
import { BranchesButtonProps } from '../../../../common/types';

interface Props {
  branchesButton: BranchesButtonProps;
}

export const BranchesButton: React.FC<Props> = ({
  branchesButton: { product, branches, isError, esOhGiftCard, isBranchesModalOpen, setIsBranchesModalOpen },
}) => {
  return (
    <>
      {esOhGiftCard && (
        <Grid item columns={12}>
          <Button
            disabled={!product || isError}
            variant="text"
            color="btnPrimary"
            startIcon={<PinDropOutlinedIcon />}
            onClick={() => setIsBranchesModalOpen(!isBranchesModalOpen)}
          >
            {branches}
          </Button>
        </Grid>
      )}
    </>
  );
};
