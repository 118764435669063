import { fetchContent } from '../utils/fetchUtils';
import { handleServiceError } from './GiftCardService';

export const categoriasPopularesService = (idCliente: number, idioma: string) => {
  try {
    let response = fetchContent(`/api/categoria/populares/${idCliente}?idioma=${idioma.toLocaleLowerCase()}`, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    throw new Error('[HomeService, categoriasPopularesService error]: ' + error);
  }
};

export const eduFinancieraService = () => {
  try {
    let response = fetchContent(`/api/NivelEducacionVideoUsuario/porcentaje`, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.log('[Home], eduFinancieraService error]: ' + error);
    throw handleServiceError(error);
  }
};
