import { useQueryClient } from '@tanstack/react-query';
import { useCatalogoAPI } from '../../common/hooks';
import { ICatalogoProps } from '../../common/types';
import { getCatalogo } from '../../services/catalogo';
import { DEFAULT_STALE_TIME } from '../../common/constants';

interface ICatalogoFiltersProps {
  filteredProducts: ICatalogoProps;
}
export const useCatalogoProduct = ({ filteredProducts }: ICatalogoFiltersProps) => {
  const queryClient = useQueryClient();

  const { catalogoQuery: products } = useCatalogoAPI({ catalogo: filteredProducts });

  const preFetchCatalogoProducts = () =>
    queryClient.prefetchQuery({
      queryKey: ['catalogo', { catalogo: filteredProducts }],
      queryFn: async () => await getCatalogo(filteredProducts),
      staleTime: DEFAULT_STALE_TIME,
    });

  return {
    products: products.data ?? null,
    isLoading: products.isLoading,
    preFetchCatalogoProducts,
  };
};
