import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { EXCHANGE_INPUTS_STYLES } from '../..';

interface ExchangeInputsProps {
  title: string;
  points: number;
  crypto: number;
  pointsText: string;
  exchangeRateText: string;
  limit: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, points: number) => void;
}

export const ExchangeInputs: React.FC<ExchangeInputsProps> = ({
  title,
  limit,
  crypto,
  pointsText,
  exchangeRateText,
  points,
  onChange,
}) => {
  return (
    <>
      <Typography variant="subtitle1" color="initial">
        {title}
      </Typography>
      <Box sx={EXCHANGE_INPUTS_STYLES.inputsContainer}>
        <TextField
          type="number"
          value={points}
          inputProps={{ min: 0, step: 1 }}
          helperText={pointsText}
          onChange={(e) => onChange(e, limit)}
          color="primary"
          size="medium"
        />
        <TextField
          type="number"
          value={crypto}
          select={false}
          helperText={exchangeRateText}
          color="primary"
          size="medium"
        />
      </Box>
    </>
  );
};
