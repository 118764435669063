import { API_USERS_URL } from '../../../common/constants';
import { IListOfConsumers } from '../../../common/types/recognitions';
import { fetchContent } from '../../../utils/fetchUtils';
import { handleServiceError } from '../../GiftCardService';

export const getListOfConsumers = async (idCliente: number): Promise<IListOfConsumers> => {
  const URL: string = `${API_USERS_URL.listOfConsumers.replace('{idCliente}', String(idCliente))}`;

  try {
    const response: IListOfConsumers = await fetchContent(URL, {
      method: 'GET',
    });

    return response;
  } catch (error: any) {
    console.error('[getListOfConsumers]: ' + error);
    throw handleServiceError(error);
  }
};
