import { CSSProperties } from 'styled-components';

export const AMOUNT_SELECTOR_STYLES = {
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: "5px"
  } as CSSProperties,
  button: {
    height: '40px',
    minWidth: '40px',
    maxWidth: '40px',
  } as CSSProperties,
  addButton: {
    borderRadius: '10px 0 0 10px',
  } as CSSProperties,
  removeButton: {
    borderRadius: '0 10px 10px 0',
  } as CSSProperties,
  input: {
    width: '130px',
    textAlign: 'center',
    height: '40px',
    borderRadius: '0px',
    borderTop: '1px solid #80A3E3',
    borderBottom: '1px solid #80A3E3',
    color: '#535955',
  } as CSSProperties,
  formControl: {
    m: 1,
    width: 80,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
  } as CSSProperties,
  inputContainer: {
    display: 'flex',
  } as CSSProperties,
};
