import { Box, Grid } from '@mui/material';
import DashboardBox from '../DashboardBox/DashboardBox';
import {
  ICustomSimpleTableColumn,
  IRecentRecognitionTable,
  IRecentTransactionTable,
  IRecognitionDashboardRankingTable,
} from '../../../../common/types';
import { CustomSimpleTable } from '../../../../components/CustomSimpleTable/CustomSimpleTable';
import { STYLE_DASHBOARD_SUMMARY_TABLE } from './style';

interface IDashboardSummaryTable {
  columns: ICustomSimpleTableColumn[];
  rows: IRecentTransactionTable[] | IRecentRecognitionTable[] | IRecognitionDashboardRankingTable[];
  title: string;
  isLoading: boolean;
  isError: boolean;
  noResults: string;
  route: string;
  isPermisos: boolean | null;
}

export const DashboardSummaryTable: React.FC<IDashboardSummaryTable> = ({
  columns,
  rows,
  title,
  isLoading,
  isError,
  noResults,
  route,
  isPermisos,
}) => {
  return (
    <Grid item xs={12} sm={12} md={typeof isPermisos === 'boolean' ? (isPermisos ? 8 : 12) : 6}>
      <DashboardBox
        title={title}
        ruta={route ?? ''}
        color="white"
        {...(!route && STYLE_DASHBOARD_SUMMARY_TABLE.defaultCursor)}
        {...STYLE_DASHBOARD_SUMMARY_TABLE.dashboardBox}
      >
        <Box sx={STYLE_DASHBOARD_SUMMARY_TABLE.tableContainer}>
          <CustomSimpleTable
            columns={columns}
            rows={rows}
            isLoading={isLoading}
            isError={isError}
            noResults={noResults}
          />
        </Box>
      </DashboardBox>
    </Grid>
  );
};
