export const updateMetaTags = (descripcion: string, imagen: string, tituloTipoReconocimiento: string): void => {
  const extendedDescription =
    descripcion.length < 100
      ? `${descripcion}... (más detalles sobre este reconocimiento para cumplir con el requisito de longitud mínima de descripción en LinkedIn)`
      : descripcion;

  document.getElementById('og-title')?.setAttribute('content', tituloTipoReconocimiento);
  document.getElementById('og-description')?.setAttribute('content', extendedDescription);
  document.getElementById('og-image')?.setAttribute('content', imagen);
  document.getElementById('og-url')?.setAttribute('content', window.location.href);
};
