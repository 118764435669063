import { useEffect, ReactNode, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import EN from '../../../common/language/recognitions/my_recognitions/en.json';
import ES from '../../../common/language/recognitions/my_recognitions/es.json';
import useLanguage from '../../../common/hooks/useLanguage';
import {
  IMyRecognitionDictionary,
  IFormatTableColumns,
  RecognitionExcludeSortingColumns,
  IMyRecognitionActions,
  IMyRecognitionRows,
  IRecognitionsData,
} from '../../../common/types/recognitions';
import { CANTIDAD_REGISTROS_POR_PAGINA } from '../../../common/constants';
import { formatTableColumns, myRecognitionFormatTableRows } from '../../../utils/recognitions';
import { useRecognitionsTable } from '../../../common/hooks/useRecognitionsTable';
import { getMyRecognitions } from '../../../services/recognitions';
import { shareOnLinkedIn, updateMetaTags } from '../../../utils';
import { Chip } from '@mui/material';

export const useMyRecognitions = () => {
  const {
    idUsuario,
    language,
    page,
    totalPages,
    sort,
    setPage,
    setSearch,
    setTotalPages,
    changeSort,
    handleChangePage,
  } = useRecognitionsTable();
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: IMyRecognitionDictionary };
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRecognition, setSelectedRecognition] = useState<IRecognitionsData>(null);

  // TODO PASAR ESTA QUERY
  const myRecognitionsQuery = useQuery({
    queryKey: ['my-recognitions', { language: language.language, idUsuario, page, sort }],
    queryFn: async () =>
      await getMyRecognitions({
        idioma: language.language,
        idUsuario,
        pagina: page,
        ordenamiento: `${sort.column} ${sort.direction}`,
        cantidadRegistrosPorPagina: CANTIDAD_REGISTROS_POR_PAGINA,
      }),
    enabled: Boolean(idUsuario),
  });

  // TODO USAR ESTA QUERY
  // const { recognitionsByUserQuery } = useRecognition({
  //   myRecognitions: {
  //     idioma: language.language,
  //     ordenamiento: sort.column,
  //     pagina: page,
  //   },
  // });

  const columns: IFormatTableColumns[] = formatTableColumns(dictionary.table.column, [
    RecognitionExcludeSortingColumns.descripcion,
  ]);

  const handleModal = (recognition: IRecognitionsData): void => {
    setSelectedRecognition(recognition);
    updateMetaTags(recognition?.descripcion, recognition?.imagen, recognition?.tituloTipoReconocimiento);
    setIsModalOpen(!isModalOpen);
  };

  const status: IMyRecognitionActions[] = myRecognitionsQuery.data?.data.map((recognition) => {
    let detalle: ReactNode = (
      <Chip onClick={() => handleModal(recognition)} color="primary" label={dictionary.table.actions.detail.title} />
    );

    return {
      idReconocimiento: recognition.idReconocimiento,
      detalle,
    };
  });

  const myRecognitions: IMyRecognitionRows[] = myRecognitionFormatTableRows(myRecognitionsQuery.data?.data, status);

  //TODO ver como crear una función reutilizable para useMadeRecognitions

  useEffect(() => {
    if (myRecognitionsQuery.data?.cantidadPaginas) {
      setTotalPages(myRecognitionsQuery.data.cantidadPaginas);
      return;
    }
  }, [myRecognitionsQuery.data?.cantidadPaginas, setTotalPages]);

  return {
    myRecognitions,
    isLoading: myRecognitionsQuery.isLoading,
    dictionary,
    columns,
    page,
    totalPages,
    sort,
    language: language.language,
    isModalOpen,
    selectedRecognition,
    linkedInBtn: dictionary.table.actions.detail.buttons.linkedIn,
    shareOnLinkedIn: shareOnLinkedIn,
    changeSort,
    setPage,
    handleChangePage,
    setSearch,
    setIsModalOpen,
  };
};
