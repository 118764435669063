import { formatDate } from '..';
import { IRecentRecognitionTable, IRecognitionsData } from '../../common/types';

export const formatTableRowsRecentRecognitions = (rows: IRecognitionsData[]): IRecentRecognitionTable[] => {
  return rows?.map(({ fecha, tituloTipoReconocimiento, descripcion }) => {
    return {
      fecha: formatDate(fecha),
      tituloTipoReconocimiento,
      descripcion,
    };
  });
};
