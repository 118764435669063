import React from 'react';
import { Grid } from '@mui/material';
import DashboardBox from '../DashboardBox/DashboardBox';
import MiniLoader from '../../../../components/Loaders/MiniLoader/MiniLoader';
import { NoResults } from '../../../../components/NoResults/NoResults';
import { POINTS_CARD_STYLES } from './style';

interface IPointsCard {
  card: React.ReactNode;
  isLoading: boolean;
  isError: boolean;
  title: string;
  noResults: string;
  route: string;
}

export const PointsCard: React.FC<IPointsCard> = ({ card, isLoading, isError, title, noResults, route }) => {
  return (
    <Grid item xs={12} sm={12} md={4}>
      <DashboardBox title={title} ruta={route}>
        <>
          {isLoading && <MiniLoader sx={POINTS_CARD_STYLES.miniLoader} />}

          {isError && <NoResults text={noResults} />}

          {card && card}
        </>
      </DashboardBox>
    </Grid>
  );
};
