import { usePurchaseCrypto } from '../../../hooks/crypto';
import { CryptoPointsExchanger } from '..';
import { RUTAS } from '../../../common/constants/rutas';

export const PurchaseCrypto = () => {
  const {
    currentCurrency,
    userPoints,
    exchangeRates,
    textFields,
    dictionary,
    isRedeeming,
    order,
    isExchangeDisabled,
    setIsRedeeming,
    handleCurrencyOnSelect,
    handlePointsOnChange,
    handleBuyCrypto,
  } = usePurchaseCrypto();

  return (
    <CryptoPointsExchanger
      mainTitle={dictionary.buyCrypto.title}
      order={order}
      selectCurrency={{
        selectCurrencyTitle: dictionary.buyCrypto.form.typeOfCurrency.title,
        currentCurrency,
        selectCurrency: dictionary.selectCurrency,
        exchangeRates,
        handleCurrencyOnSelect,
      }}
      exchangeInputs={{
        title: dictionary.buyCrypto.form.typeOfCurrency.points.replace('{n}', String(userPoints)),
        limit: userPoints,
        points: textFields.points,
        crypto: textFields.crypto,
        pointsText: dictionary.buyCrypto.form.points.footer,
        exchangeRateText: dictionary.exchangeRateFooter,
        handleOnChange: handlePointsOnChange,
      }}
      buttonSubmit={{
        isExchangeDisabled,
        isRedeeming,
        buttonText: dictionary.buttons.next,
        setIsRedeeming,
        handleSubmit: handleBuyCrypto,
      }}
      switchCryptoPointsExchanger={{
        switchTitle: dictionary.buyCrypto.isSwitchingExchange,
        linkText: dictionary.linkText,
        route: RUTAS.ventaCripto,
      }}
      orderExchangeForm={{
        textFields,
      }}
    />
  );
};
