import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AppProvider } from './context/AppContext';
import { AppThemeProvider } from './context/ThemeContext';
import { LanguageProvider } from './context/LanguageContext';
import { persistor, store } from './common/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { TanStackProvider } from './context';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AppProvider>
    <Provider store={store}>
      <TanStackProvider>
        <PersistGate loading={null} persistor={persistor}>
          <LanguageProvider>
            <BrowserRouter>
              <AppThemeProvider>
                <App />
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
              </AppThemeProvider>
            </BrowserRouter>
          </LanguageProvider>
        </PersistGate>
      </TanStackProvider>
    </Provider>
  </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
