import { useContext } from 'react';
import { cliente, PERMISOS_CODIGOS } from '../../common/constants/constants';
import { IHomeDictionary } from '../../common/types/home/home';
import { AppContext, LanguageContext } from '../../context';
import useLanguage from '../../common/hooks/useLanguage';
import { Permiso } from '../../common/interfaces/Permisos';
import { RUTAS } from '../../common/constants/rutas';
import { formatPointsData } from '../../utils';
import { formatDashboardSummaryTables } from '../../utils/home';
import {
  useCryptoPoints,
  useEducationLevel,
  useFinancialEducation,
  useMyRecentTransactions,
  useRecentRecognitions,
  useTheMostConsumedProducts,
  useTheMostRecognizedOfTheMonth,
} from './dashboard';
import { useHomeCarrouselClientLanguage } from '.';
import { usePerson } from '../../common/hooks';
import EN from '../../common/language/home/en.json';
import ES from '../../common/language/home/es.json';

export const useHome = () => {
  const appContext = useContext(AppContext); //? necesario
  const language = useContext(LanguageContext);
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: IHomeDictionary };
  const permisos: Permiso[] = appContext?.permisos;
  const isPermisosInDashboardWeb: boolean =
    permisos?.filter((el) => el.codigo === PERMISOS_CODIGOS.reconocimientosEnDashboardWeb).length > 0;

  const { clientPointsQuery } = usePerson({ isFetchingUserPoints: true });

  const { cryptoPointsQuery, isPermisosInCrypto } = useCryptoPoints({
    permisos,
  });
  const { percentageQuery } = useFinancialEducation();
  const { educationLevelVideoQuery } = useEducationLevel();
  const { categoriesRedeemedQuery } = useTheMostConsumedProducts({
    idioma: language.language,
  });
  const { consolidatedHomeQuery, myRecentTransactionsColumns, myRecentTransactionsRows } = useMyRecentTransactions({
    idioma: language.language,
    columns: dictionary.tables.myRecentTransactions.table.column,
  });
  const { dashboardRecognitionsRankingQuery, theMostRecognizedOfTheMonthColumns, theMostRecognizedOfTheMonthRows } =
    useTheMostRecognizedOfTheMonth({
      idioma: language.language,
      columns: dictionary.tables.theMostRecognizedOfTheMonth.table.column,
      isPermisosInDashboardWeb,
    });
  const { recognitionsByUserQuery, recentRecognitionsColumns, recentRecognitionsRows } = useRecentRecognitions({
    idioma: language.language,
    columns: dictionary.tables.latestRecognitions.table.column,
    isPermisosInDashboardWeb,
  });

  const { carrouselByClientLanguageQuery } = useHomeCarrouselClientLanguage({ cliente, lenguaje: language.language });

  const points = formatPointsData(
    [
      {
        data: clientPointsQuery,
        cardType: 'availablePoints',
        route: RUTAS.misMovimientos,
        isPermisos: true,
        isPointsCard: true,
      },
      {
        data: cryptoPointsQuery,
        cardType: 'cryptoPoints',
        route: RUTAS.cripto,
        isPermisos: isPermisosInCrypto,
        isPointsCard: true,
      },
      {
        data: percentageQuery,
        cardType: 'financialEducationLevel',
        route: RUTAS.educacion,
        isPermisos: isPermisosInCrypto,
        isPointsCard: false,
      },
    ],
    dictionary
  );

  const dashboardSummaryTableData = formatDashboardSummaryTables(
    [
      {
        data: consolidatedHomeQuery,
        columns: myRecentTransactionsColumns,
        rows: myRecentTransactionsRows,
        name: 'myRecentTransactions',
        route: RUTAS.misMovimientos,
        isPermisos: isPermisosInCrypto,
        isRendering: true,
      },
      {
        data: dashboardRecognitionsRankingQuery,
        columns: theMostRecognizedOfTheMonthColumns,
        rows: theMostRecognizedOfTheMonthRows,
        name: 'theMostRecognizedOfTheMonth',
        route: null,
        isPermisos: null,
        isRendering: isPermisosInDashboardWeb,
      },
      {
        data: recognitionsByUserQuery,
        columns: recentRecognitionsColumns,
        rows: recentRecognitionsRows,
        name: 'latestRecognitions',
        route: RUTAS.misReconocimientos,
        isPermisos: null,
        isRendering: isPermisosInDashboardWeb,
      },
    ],
    dictionary
  );

  return {
    cryptoPointsQuery,
    points,
    dictionary,
    isPermisosInCrypto,
    isPermisosInDashboardWeb,
    educationLevel: educationLevelVideoQuery.data ?? null,
    categoriesRedeemedQuery,
    myRecentTransactionsColumns,
    consolidatedHomeQuery,
    myRecentTransactionsRows,
    dashboardRecognitionsRankingQuery,
    theMostRecognizedOfTheMonthColumns,
    theMostRecognizedOfTheMonthRows,
    recognitionsByUserQuery,
    recentRecognitionsColumns,
    recentRecognitionsRows,
    dashboardSummaryTableData,
    carrouselByClientLanguageQuery,
  };
};
