import { API_TYPE_OF_RECOGNITION_URL } from '../../../common/constants';
import { ITypeOfRecognitions } from '../../../common/types/recognitions';
import { fetchContent } from '../../../utils/fetchUtils';
import { handleServiceError } from '../../GiftCardService';

interface IRecognitions {
  idSector: number;
  lang: string;
}

export const getRecognitionsBySector = async (recognition: IRecognitions): Promise<ITypeOfRecognitions[]> => {
  const { idSector, lang } = recognition;
  const URL: string = `${API_TYPE_OF_RECOGNITION_URL.bySector.replace('{idSector}', String(idSector))}?idioma=${lang}`;

  try {
    const response: ITypeOfRecognitions[] = await fetchContent(URL, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.error('[getRecognitionsBySector]: ' + error);
    throw handleServiceError(error);
  }
};
