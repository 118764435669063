import { API_TRANSACTIONS_URL } from '../../common/constants';
import { ICategoriesRedeemed } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { setQueryParams } from '../../utils/functions';
import { handleServiceError } from '../GiftCardService';

interface ICategoriesRedeemedParams {
  idPersona: number;
  idioma: string;
  ordenamiento: string;
  limit: number;
}

export const getCategoriesRedeemed = async (params: ICategoriesRedeemedParams): Promise<ICategoriesRedeemed[]> => {
  const { idPersona, idioma, ordenamiento, limit } = params;
  const setIdPersona: string = API_TRANSACTIONS_URL.redeemedCategories.replace('{idPersona}', String(idPersona));
  const URL: string = setQueryParams(setIdPersona, { idioma, ordenamiento, limit });

  try {
    const response: ICategoriesRedeemed[] = await fetchContent(URL, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.error('[getCategoriesRedeemed]: ' + error);
    throw handleServiceError(error);
  }
};
