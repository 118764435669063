import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';
import './PopCategories.css';
import ShortLine from '../../../../components/ShortLine/ShortLine';
import { useNavigate } from 'react-router-dom';
import useCatalogo from '../../../../hooks/catalogo/useCatalogo';

export const PopCategories = () => {
  const navigate = useNavigate();
  const { preFetchCatalogoProducts } = useCatalogo();

  const handleOnMouseEnter = () => {
    preFetchCatalogoProducts();
  };

  return (
    <div className="PopCategoriesSection" onMouseEnter={handleOnMouseEnter}>
      <Typography variant="h4" color="primary">
        Categorías más populares de Gift Cards
      </Typography>
      <ShortLine />
      {/* <Grid container md={12} spacing={{ xs: 1, sm: 2, md: 4 }} justifyContent="center"></Grid> */}
      <Button variant="contained" color="btnPrimary" sx={{ marginTop: '50px' }} onClick={() => navigate('/catalogo')}>
        Descubrí más categorías
      </Button>
    </div>
  );
};
