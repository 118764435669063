import { API_CLIENT_URL } from '../../common/constants';
import { ClientTheme } from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const getClientTheme = async (idCliente: number): Promise<ClientTheme[]> => {
  const URL: string = API_CLIENT_URL.theme.replace('{idCliente}', String(idCliente));
  try {
    const theme: ClientTheme[] = await fetchContent(URL);

    return theme;
  } catch (error: any) {
    console.error('[getClientTheme]: ' + error);
    throw handleServiceError(error);
  }
};
