import React from 'react';
import { Grid } from '@mui/material';
import InputForm from '../../../../../components/Inputs/InputForm/InputForm';
import SelectForm from '../../../../../components/Inputs/SelectForm/SelectForm';
import { City, Country, CustomerBody, State } from '../../../../../common/types';

interface GeoLocationProps {
  fullAddress: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
  countries: Country[];
  states: State[];
  cities: City[];
  form: CustomerBody;
  setForm: (form: CustomerBody) => void;
}

export const GeoLocation: React.FC<GeoLocationProps> = ({
  fullAddress,
  country,
  state,
  city,
  zipCode,
  countries,
  states,
  cities,
  form,
  setForm,
}) => {
  return (
    <Grid container columns={12} spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
        <SelectForm
          form={form}
          setForm={setForm}
          label={country}
          children={countries?.map(({ id, nombre }) => (
            <option key={id} value={id}>
              {nombre}
            </option>
          ))}
          name={'country'}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4} spacing={2}>
        <SelectForm
          form={form}
          setForm={setForm}
          label={state}
          children={states?.map(({ id, nombre }) => (
            <option key={id} value={id}>
              {nombre}
            </option>
          ))}
          name={'state'}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <SelectForm
          form={form}
          setForm={setForm}
          label={city}
          children={cities?.map(({ id, nombre }) => (
            <option key={id} value={id}>
              {nombre}
            </option>
          ))}
          name={'city'}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <InputForm name="address_line1" label={fullAddress} type="text" form={form} setForm={setForm} />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <InputForm name="zip_code" label={zipCode} type="text" form={form} setForm={setForm} />
      </Grid>
    </Grid>
  );
};
