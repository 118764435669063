import { formatCommonTransactionDetail } from '..';
import {
  IDsTypeOFTransaction,
  ITransactionDetail,
  ITransactionPointsAssignmentDetailFormateadData,
  ITransactionsDictionary,
} from '../../common/types';

export const formatPointsAssignmentDetail = (
  transaction: ITransactionDetail,
  dictionary: ITransactionsDictionary
): ITransactionPointsAssignmentDetailFormateadData => {
  if (!transaction?.asignacionDePuntos) return null;

  const { date, points } = formatCommonTransactionDetail(transaction?.fecha, transaction?.asignacionDePuntos?.puntos);

  const title: string =
    transaction?.idTipoMovimiento === IDsTypeOFTransaction.asignacionPuntos
      ? dictionary.detailModal.assignment.assignmentPointsTitle
      : dictionary.detailModal.assignment.subtractPointsTitle;

  const formateadTransaction: ITransactionPointsAssignmentDetailFormateadData = {
    title,
    date,
    points,
  };

  return formateadTransaction;
};
