import { CSSProperties } from 'styled-components';

export const DASHBOARD_BOX_STYLE = {
  mainContainer: {
    cursor: 'pointer',
    flexDirection: 'column',
    minHeight: '180px',
    height: 'fit-content',
    padding: '20px',
    borderRadius: '10px',
  } as CSSProperties,
  mainContainerHover: (color: string) =>
    ({
      '&:hover': {
        backgroundColor: color === 'white' ? '#e5e4e8' : 'primary.dark',
        transform: 'scale(1.008)',
      },
    } as CSSProperties),
};
