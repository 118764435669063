import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  CurrencyBitcoinOutlined as CurrencyBitcoinOutlinedIcon,
  AttachMoneyOutlined as AttachMoneyOutlinedIcon,
} from '@mui/icons-material/';

interface CryptoExchangeDetailProps {
  currencyTitle: string;
  amountTitle: string;
  currency: string;
  amount: string;
}

export const CryptoExchangeDetail: React.FC<CryptoExchangeDetailProps> = ({
  currencyTitle,
  amountTitle,
  currency,
  amount,
}) => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        py={1}
      >
        <CurrencyBitcoinOutlinedIcon color="primary" />
        <Typography variant="body2">
          {currencyTitle} {currency}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        gap={1}
        py={1}
      >
        <AttachMoneyOutlinedIcon color="primary" />
        <Typography variant="body2">
          {amountTitle} {amount}
        </Typography>
      </Box>
    </>
  );
};
