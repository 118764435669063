import { useMyTeam } from '../../../hooks/recognitions';
import { MyTeamBudget } from '../../../components/Recognitions';
import { ViewTable } from '../../../components/ViewTable/ViewTable';

export const MyTeam = () => {
  const {
    myTeam,
    budgetSupervisor,
    isLoading,
    dictionary,
    columns,
    page,
    totalPages,
    sort,
    changeSort,
    setPage,
    handleChangePage,
    setSearch,
  } = useMyTeam();

  return (
    <ViewTable
      title={dictionary.title}
      searchBarPlaceHolder={dictionary.form.search.placeHolder}
      isLoading={isLoading}
      rows={myTeam}
      page={page}
      totalPages={totalPages}
      noResults={dictionary.table.noResult}
      columns={columns}
      sort={sort}
      details={budgetSupervisor && <MyTeamBudget text={dictionary.details.subtitle} budget={budgetSupervisor} />}
      showSearchBar={true}
      setPage={setPage}
      changeSort={changeSort}
      setSearch={setSearch}
      handleChangePage={handleChangePage}
    />
  );
};
