import React from 'react';
import { LinearProgress, Grid, Box, Typography } from '@mui/material';
import parse from 'html-react-parser';
import { STYLES } from './styles';
import { Status } from '../../../../common/types';

interface IProgressBarNew {
  subtitle: string;
  body: string;
  percentage: number;
  footer: string;
  status: string;
  idEducationLevel: number;
  levelsCompleted: string;
}

export const ProgressBar: React.FC<IProgressBarNew> = ({
  subtitle,
  percentage,
  body,
  status,
  idEducationLevel,
  footer,
  levelsCompleted,
}) => {
  return (
    <Box sx={STYLES.mainBox}>
      <Grid container spacing={2} sx={STYLES.gridMainContent}>
        <Grid item sm={2} sx={STYLES.text}>
          <Typography variant="subtitle1">{subtitle}</Typography>
        </Grid>
        <Grid item xs={8}>
          <LinearProgress color="secondary" sx={STYLES.linearProgress} variant="determinate" value={percentage} />
        </Grid>
        <Grid item sm={2} sx={STYLES.text}>
          <Typography variant="subtitle1">{body.replace('{points}', percentage?.toString())} </Typography>
        </Grid>
      </Grid>
      <Box sx={STYLES.footer}>
        <Typography>
          {status !== Status.Finalizado ? parse(footer.replace('{level}', String(idEducationLevel))) : levelsCompleted}
        </Typography>
      </Box>
    </Box>
  );
};
