import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BurgerBtn from '../BurgerBtn/BurgerBtn';
import { Avatar, Hidden } from '@mui/material';
import { AppContext } from '../../../context/AppContext';
import { useNavigate } from 'react-router-dom';
import MenuDrawerItemsLogin from '../MenuDrawerItemsLogin/MenuDrawerItemsLogin';
import MenuDrawerItemsPreLogin from '../MenuDrawerItemsPreLogin/MenuDrawerItemsPreLogin';
import './MenuDrawer.css';
import { AVATAR_ROUTES } from '../../../common/constants/constants';
import ES from '../../../common/language/shared/es.json';
import EN from '../../../common/language/shared/en.json';
import useLanguage from '../../../common/hooks/useLanguage';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

export const MenuDrawer = () => {
  const [open, setOpen] = React.useState(false);
  const appContext = React.useContext(AppContext);
  const navigate = useNavigate();
  const { dictionary } = useLanguage({ EN, ES });

  const handleCerrarSesion = () => {
    appContext?.cerrarSession();
    navigate('/login');
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(!open);
  };

  return (
    <Box>
      <Hidden mdUp>
        <BurgerBtn open={open} setOpen={setOpen} />
      </Hidden>
      <Hidden mdUp>
        <Drawer open={open} onClose={toggleDrawer(true)} sx={{ zIndex: '6000' }}>
          <Box sx={{ width: 300 }} role="presentation" onKeyDown={toggleDrawer(false)}>
            {/* esto solo se muestra si estas logeado */}
            <Hidden xsUp={appContext?.user ? false : true}>
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <Avatar
                        src={
                          AVATAR_ROUTES.filter((el) => el.avatar === appContext?.user?.avatar)[0]
                            ? AVATAR_ROUTES.filter((el) => el.avatar === appContext?.user?.avatar)[0].icono
                            : ''
                        }
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Box className="menu-drawer-user-container">
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: '15px',
                            fontWeight: 500,
                          }}
                          primary={`${appContext?.user?.nombre} ${appContext?.user?.apellido}`}
                        />
                        <ListItemText primaryTypographyProps={{ fontSize: '13px' }} primary={appContext?.user?.email} />
                      </Box>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </List>
              <Divider />
            </Hidden>
            <List>
              {appContext?.user ? (
                <MenuDrawerItemsLogin setOpen={setOpen} />
              ) : (
                <MenuDrawerItemsPreLogin setOpen={setOpen} />
              )}
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleCerrarSesion()} sx={{ display: 'flex', gap: '10px' }}>
                  <LogoutOutlinedIcon sx={{ fontSize: '20px', color: '#404040' }} />
                  <ListItemText
                    primary={appContext?.user ? dictionary?.header.cerrarSesion : dictionary?.header.ingresa}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </Hidden>
    </Box>
  );
};
