import { CSSProperties } from 'styled-components';

export const STYLE_TERMS_AND_CONDITIONS = {
  title: {
    cursor: 'pointer',
  } as CSSProperties,
  termsModalBox: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    backgroundColor: 'white',
    borderRadius: '15px',
    boxShadow: '24',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    '@media screen and (max-width: 800px)': {
      width: '95%',
      padding: '20px',
    },
  } as CSSProperties,
  termsModalTitle: {
    fontSize: '14px',
  } as CSSProperties,
  termsModalTermsDescription: {
    fontSize: '12px',
  } as CSSProperties,
  termsModalOnCloseBtnBox: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  } as CSSProperties,
  imgProductDetail: {
    maxWidth: '750px',
    maxHeight: '18rem',
    width: '95%',
    borderRadius: '10px',
  } as CSSProperties,
};
