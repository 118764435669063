import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Clear } from '@mui/icons-material';

const DELAY: number = 1000;
interface Props {
  setSearch: (value: string) => void;
  setPage: (value: number) => void;
  placeHolder?: string;
}
const useDebounce = (value: string, delay: number): string => {
  const [debouncedValue, setDebouncedValue] = useState<string>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      const inputHave3Chars: boolean = value.length > 2;
      const isInputEmpty: boolean = value.length <= 0;
      if (inputHave3Chars || isInputEmpty) {
        setDebouncedValue(value);
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

const CustomSearch = ({ placeHolder, setSearch, setPage }: Props) => {
  const [inputValue, setInputValue] = useState<string>('');
  const debouncedValue: string = useDebounce(inputValue, DELAY);

  const onInputChange = (value: string): void => {
    setInputValue(value);
  };

  useEffect(() => {
    setSearch(debouncedValue);
    setPage(1);
  }, [debouncedValue, setSearch, setPage]);

  return (
    <TextField
      type="text"
      value={inputValue}
      className="input-form input-buscar"
      placeholder={placeHolder}
      onChange={(e) => {
        onInputChange(e.target.value);
      }}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {inputValue && (
              <IconButton onClick={() => onInputChange('')}>
                <Clear />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};
export default CustomSearch;
