import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import { STYLE_PRE_LOG_IN } from './style';

interface PreLoginProps {
  title: string;
  buttonText: string;
  route: string;
}

export const PreLogIn: React.FC<PreLoginProps> = ({ title, buttonText, route }) => {
  const navigate = useNavigate();

  const handleNavigate = (): void => {
    navigate(route);
  };

  return (
    <Grid item sm={12}>
      <Box sx={STYLE_PRE_LOG_IN.boxContainer}>
        <Typography variant="h6" color="initial">
          {title}
        </Typography>
        <Button variant="contained" color="primary" onClick={handleNavigate}>
          {buttonText}
        </Button>
      </Box>
    </Grid>
  );
};
