import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '../../../context';
import { getProductDetailWeb } from '../../../services/product';
import { DetailWeb } from '../../types';

interface Products {
  detailWeb?: DetailWeb;
}

export const useProduct = ({ detailWeb }: Products) => {
  const appContext = useContext(AppContext);
  const idUsuario: number = appContext?.user?.idUsuario;

  const detailWebQuery = useQuery({
    queryKey: ['detalleWeb', { detailWeb }],
    queryFn: async () => await getProductDetailWeb(detailWeb),
    enabled: Boolean(idUsuario && detailWeb),
  });

  return { detailWebQuery };
};
