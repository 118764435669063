import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCity, useCountry, useLiriumUserMutation, usePerson, useStateAPI } from '../../common/hooks';
import { CustomerBody, InternalAPILiriumUserResponse, SignUpCryptoDictionary } from '../../common/types';
import useLanguage from '../../common/hooks/useLanguage';
import { validarCamposAltaCripto } from '../../utils/validaciones';
import { checkFormProperties } from '../../utils';
import EN from '../../common/language/crypto_/sign_up_crypto/en.json';
import ES from '../../common/language/crypto_/sign_up_crypto/es.json';

const initForm: CustomerBody = {
  reference_id: '',
  national_id: '',
  first_name: '',
  last_name: '',
  address_line1: '',
  zip_code: '',
  email: '',
  cellphone: '',
  city: '',
  state: '',
  date_of_birth: '',
  middle_name: '',
  country: 'AR',
  national_id_country: 'AR',
  national_id_type: 'national_id',
  citizenship: 'AR',
};

export const useSignUpCrypto = () => {
  const [form, setForm] = useState<CustomerBody>(initForm);
  const [isCreatingCustomer, setIsCreatingCustomer] = useState<boolean>(false);
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: SignUpCryptoDictionary };
  const { mutation } = useLiriumUserMutation();
  const location = useLocation();
  const [isHasCryptoAccount, setIsHasCryptoAccount] = useState<boolean>(location.state);

  const {
    idUsuario,
    personUserQuery: { data: user },
  } = usePerson({ isFetchingUser: true });

  const {
    countriesQuery: { data: countries },
  } = useCountry({ isFetchingCountries: true });

  const {
    statesQuery: { data: states },
  } = useStateAPI({
    isFetchingStates: true,
  });

  const {
    citiesQuery: { data: cities },
  } = useCity({
    citiesByIdProvincia: {
      idProvincia: form.state,
    },
  });

  const handleSubmit = async (): Promise<InternalAPILiriumUserResponse> => {
    if (validarCamposAltaCripto(form)) {
      const newCustomer: InternalAPILiriumUserResponse = await mutation.mutateAsync({
        customer: {
          idUsuario,
          customerBody: form,
        },
        successText: dictionary.success,
        errorText: dictionary.error,
        setIsHasCryptoAccount,
        setForm,
      });

      return newCustomer;
    }
  };

  const isFormFilled: boolean = checkFormProperties(form);

  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      reference_id: user?.idUsuarioNavigation.usuarioDvh,
      national_id: user?.idUsuarioNavigation.dni,
      first_name: user?.nombre,
      last_name: user?.apellido,
      address_line1: user?.domicilio,
      zip_code: user?.cp,
      email: user?.email,
      cellphone: user?.telefonoMovil,
    }));
  }, [user]);

  return {
    isHasCryptoAccount,
    dictionary,
    form,
    countries,
    cities,
    states,
    isCreatingCustomer,
    isFormFilled,
    setIsCreatingCustomer,
    setForm,
    handleSubmit,
  };
};
