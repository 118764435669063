import { CSSProperties } from 'styled-components';

export const RIGHT_NAVBAR_STYLES = {
  mainContainer: {
    color: 'white',
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
  } as CSSProperties,
  routerLink: {
    textDecoration: 'none',
    color: 'white',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '5px',
  } as CSSProperties,
  icon: {
    fontSize: '20px',
    width: '20px',
  },
};
