import { API_LIRIUM_ORDER_URL } from '../../common/constants';
import { LiriumOrdersExchangeRates } from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const getLiriumExchangeRates = async (currency: string): Promise<LiriumOrdersExchangeRates> => {
  const URL: string = API_LIRIUM_ORDER_URL.exchangeRates.replace('{currency}', currency);
  try {
    const orders: LiriumOrdersExchangeRates = await fetchContent(URL, {
      method: 'GET',
    });

    return orders;
  } catch (error: any) {
    console.error('[getLiriumExchangeRates]: ' + error);
    throw handleServiceError(error);
  }
};
