import { API_TRANSACTIONS_URL } from '../../common/constants';
import { ITransactionDetail } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getTransactionDetail = async (idMovimiento: number): Promise<ITransactionDetail> => {
  try {
    const URL: string = API_TRANSACTIONS_URL.transactionDetail.replace('{idMovimiento}', String(idMovimiento));

    const response: ITransactionDetail = await fetchContent(URL);
    return response;
  } catch (error: any) {
    console.error('[getTransactionDetail]:' + error);
    throw handleServiceError(error);
  }
};
