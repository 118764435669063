import { Button, Typography } from '@mui/material';
import { FC } from 'react';

interface IMyTeamBudget {
  text: string;
  budget: string | boolean;
}

export const MyTeamBudget: FC<IMyTeamBudget> = ({ budget, text }) => {
  return (
    <Button variant="contained" sx={{cursor: "default"}}>
      <Typography variant="h6">
        {text}: ${budget}
      </Typography>
    </Button>
  );
};
