import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { ICustomSimpleTableColumn, ICustomSimpleTableRows } from '../../common/types';
import CustomTableRow from '../CustomTable/CustomTableRow';
import MiniLoader from '../Loaders/MiniLoader/MiniLoader';
import { NoResults } from '../NoResults/NoResults';
import { CUSTOM_SIMPLE_TABLE_STYLES } from './style';

interface ICustomSimpleTable {
  columns: ICustomSimpleTableColumn[];
  rows: ICustomSimpleTableRows[];
  isLoading: boolean;
  isError: boolean;
  noResults: string;
}

export const CustomSimpleTable: React.FC<ICustomSimpleTable> = ({ columns, rows, isLoading, isError, noResults }) => {
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow sx={CUSTOM_SIMPLE_TABLE_STYLES.tableRow}>
            {columns?.map((column) => {
              return (
                <TableCell key={`header-${column.label}`} align="center" sx={CUSTOM_SIMPLE_TABLE_STYLES.columnBox}>
                  <Typography fontSize={14} sx={CUSTOM_SIMPLE_TABLE_STYLES.columnText}>
                    {column.label}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={columns.length} style={CUSTOM_SIMPLE_TABLE_STYLES.columnBox}>
                <MiniLoader />
              </TableCell>
            </TableRow>
          ) : (
            (isError || !rows?.length) && (
              <TableRow>
                <TableCell align="center" colSpan={columns.length} sx={CUSTOM_SIMPLE_TABLE_STYLES.loaderBox}>
                  <NoResults text={noResults} {...CUSTOM_SIMPLE_TABLE_STYLES.noResults} />
                </TableCell>
              </TableRow>
            )
          )}

          {rows?.map((row, index) => (
            <CustomTableRow key={`table-row-${index}`} {...row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
