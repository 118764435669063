import React from 'react';
import { Grid } from '@mui/material';
import InputForm from '../../../../../components/Inputs/InputForm/InputForm';
import { CustomerBody } from '../../../../../common/types';

interface ContactFieldsProps {
  email: string;
  phoneNumber: string;
  form: CustomerBody;
  setForm: (form: CustomerBody) => void;
}

export const ContactFields: React.FC<ContactFieldsProps> = ({ email, phoneNumber, form, setForm }) => {
  return (
    <Grid container columns={12} spacing={2}>
      <Grid item xs={12} sm={12} md={8}>
        <InputForm name="email" label={email} type="email" form={form} setForm={setForm} />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <InputForm name="cellphone" label={phoneNumber} type="text" form={form} setForm={setForm} />
      </Grid>
    </Grid>
  );
};
