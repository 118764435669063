import { CSSProperties } from 'styled-components';

export const UPLOAD_IMAGES_FORM_STYLES = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    alignItems: 'center',
    justifyContent: 'center',
  } as CSSProperties,
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  } as CSSProperties,
  fileInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(178, 178, 178)',
    borderRadius: '15px',
    width: '300px',
    height: '100px',
    position: 'relative',
    cursor: 'pointer',
    fontSize: '12px',
    textAlign: 'center',
    padding: '10px 20px',
  } as CSSProperties,
  fileLoadInput: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '10000',
    width: '300px',
    height: '100px',
    opacity: '0',
    cursor: 'pointer',
    content: 'none',
  } as CSSProperties,
};
