import { useHomeCarrousel } from '../../../common/hooks';

interface IHomeCarrouselClientLanguage {
  cliente: number;
  lenguaje: string;
}

export const useHomeCarrouselClientLanguage = (props: IHomeCarrouselClientLanguage) => {
  const { cliente, lenguaje } = props;

  const { carrouselByClientLanguageQuery } = useHomeCarrousel({
    clientAndLanguage: { cliente, lenguaje },
  });

  return { carrouselByClientLanguageQuery };
};
