import { useQuery } from '@tanstack/react-query';
import { DEFAULT_STALE_TIME } from '../../constants';
import { getCatalogo, getCatalogoMetadata } from '../../../services/catalogo';
import { IFiltroCatalogo } from '../../interfaces/GiftCardInterfaces';
import { ICatalogoProps } from '../../types';

interface ICatalogo {
  metadata?: IFiltroCatalogo;
  catalogo?: ICatalogoProps;
}

export const useCatalogoAPI = ({ metadata, catalogo }: ICatalogo) => {
  const catalogoMetadataQuery = useQuery({
    queryKey: ['catalogo-metadata', { metadata }],
    queryFn: async () => await getCatalogoMetadata(metadata),
    staleTime: DEFAULT_STALE_TIME,
    enabled: Boolean(metadata),
  });

  const catalogoQuery = useQuery({
    queryKey: ['catalogo', { catalogo }],
    queryFn: async () => await getCatalogo(catalogo),
    staleTime: DEFAULT_STALE_TIME,
    enabled: Boolean(catalogo),
  });

  return { catalogoMetadataQuery, catalogoQuery };
};
