import { API_CATALOGO_URL, cliente } from '../../common/constants';
import { IFiltroCatalogo } from '../../common/interfaces/GiftCardInterfaces';
import { ICatalogoMetadata } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getCatalogoMetadata = async (metadata: IFiltroCatalogo): Promise<ICatalogoMetadata> => {
  const URL: string = API_CATALOGO_URL.catalogoMetadata.replace('{idCliente}', String(cliente));

  try {
    const response: ICatalogoMetadata = await fetchContent(URL, {
      method: 'POST',
      body: metadata,
      headers: { 'x-api-version': '2.0' },
    });

    return response;
  } catch (error: any) {
    console.error('[getCatalogoMetadata]: ' + error);
    throw handleServiceError(error);
  }
};
