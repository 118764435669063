import { API_TRANSACTIONS_URL } from '../../common/constants';
import { IRecentTransaction } from '../../common/types';
import { fetchContent, setQueryParams } from '../../utils';
import { handleServiceError } from '../GiftCardService';

interface IConsolidatedPersonParams {
  idioma: string;
  cantidadRegistros: number;
}

export const getConsolidatedPersonHome = async (params: IConsolidatedPersonParams): Promise<IRecentTransaction[]> => {
  const URL: string = setQueryParams(API_TRANSACTIONS_URL.consolidatedPersonHome, params);

  try {
    const consolidatedPerson: IRecentTransaction[] = await fetchContent(URL, {
      method: 'GET',
    });

    return consolidatedPerson;
  } catch (error: any) {
    console.error('[getConsolidatedPersonHome]: ' + error);
    throw handleServiceError(error);
  }
};
