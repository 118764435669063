import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLanguage from '../../common/hooks/useLanguage';
import {
  CryptoDictionary,
  CryptoExchangeCurrencyTypes,
  CustomerOrdersFormatTable,
  ICustomSimpleTableColumn,
} from '../../common/types';
import { useClient, useLiriumOrder, useLiriumUser } from '../../common/hooks';
import { balanceAmount, formatSimpleOrdersTransactionRows, formatTableSimpleColumns, getEnumValues } from '../../utils';
import { AppContext } from '../../context';
import ES from '../../common/language/crypto_/es.json';
import EN from '../../common/language/crypto_/en.json';

export const useCrypto = () => {
  const [currentCurrency, setCurrentCurrency] = useState<CryptoExchangeCurrencyTypes>(
    CryptoExchangeCurrencyTypes.bitcoin
  );
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: CryptoDictionary };
  const navigate = useNavigate();
  const { user } = useContext(AppContext);

  const {
    cryptoCustomerQuery: { data: customer, isLoading: isCustomerLoading },
    cryptoUserQuery: { data: cryptoUser, isLoading: isUserLoading },
    cryptoCustomerAccountsQuery: { data: customerAccounts },
  } = useLiriumUser({
    isFetchingUser: true,
    isFetchingCustomer: true,
    isFetchingCustomerAccounts: true,
  });

  const {
    clientCurrencyQuery: { data: currency },
  } = useClient({ clientCurrency: true });

  const {
    cryptoCustomerOrdersQuery: {
      data: customerOrders,
      isLoading: isCustomerOrdersLoading,
      isError: isCustomerOrdersError,
    },
  } = useLiriumOrder({ customerOrders: cryptoUser?.id });

  const onNavigate = (route: string, isHasCryptoAccount?: boolean): void =>
    navigate(route, isHasCryptoAccount && { state: isHasCryptoAccount });
  const amount: string = balanceAmount(customerAccounts, currentCurrency, dictionary.buttons.selectCurrency);

  //* customer: cuenta en API Lirium
  //* cryptoUser: cuenta en nuestra DDBB
  const isHasCryptoAccount: boolean = Boolean(user && customer && cryptoUser);

  const customerOrdersTableColumns: ICustomSimpleTableColumn[] = formatTableSimpleColumns(dictionary.table.column);

  const customerOrdersTableRows: CustomerOrdersFormatTable[] = formatSimpleOrdersTransactionRows(
    customerOrders?.orders ?? null
  );

  const cryptoExchangeCurrencyTypes: string[] = getEnumValues(CryptoExchangeCurrencyTypes);

  return {
    dictionary,
    isLoading: isCustomerLoading || isUserLoading,
    amount,
    currentCurrency,
    cryptoExchangeCurrencyTypes,
    currency,
    customerOrdersTableColumns,
    customerOrdersTableRows,
    isCustomerOrdersLoading,
    isCustomerOrdersError,
    user,
    cryptoUser,
    isHasCryptoAccount,
    setCurrentCurrency,
    onNavigate,
  };
};
