import { formatDate, formatNumber } from '..';
import { IConsolidatedPersonActions, IConsolidatedPersonData, ITransactionFormatTableRows } from '../../common/types';

export const recentTransactionsFormatTableRows = (
  rows: IConsolidatedPersonData[],
  actions: IConsolidatedPersonActions[]
): ITransactionFormatTableRows[] => {
  return rows?.map(({ idMovimiento, fecha, tipoMovimientoDescripcion, puntos }) => {
    return {
      idMovimiento,
      fecha: formatDate(fecha),
      tipoMovimientoDescripcion,
      puntos: formatNumber(puntos),
      comentario: actions.find((transactions) => transactions.idMovimiento === idMovimiento)?.detalle,
    };
  });
};
