import React from 'react';
import { AppBar, Box, Toolbar } from '@mui/material';
import CenterNav from './CenterNav/CenterNav';
import { MenuDrawer } from '../Menu/MenuDrawer/MenuDrawer';
import { useHeader } from '../../hooks/header';
import { RightNavbar } from './RightNavbar/RightNavbar';
import { HEADER_STYLES } from '.';
import './Header.css';

type Props = {
  logo: {
    logoUrl: string;
    tipoImagenLogo: string | undefined;
    logo: string | undefined;
  };
  direction?: string;
  open?: boolean;
};

const Header: React.FC<Props> = ({ logo }) => {
  const {
    isHeaderVisible,
    dictionary,
    user,
    isHasRecognitionsPermission,
    isHasCryptoPermission,
    avatar,
    email,
    lastName,
    userName,
    anchor,
    isMenuOpen,
    puntos,
    handleOnNavigate,
    handleLogout,
    handleOnClick,
    handleOnClose,
  } = useHeader();

  if (!isHeaderVisible) return <></>;

  return (
    <Box sx={HEADER_STYLES.mainContainer}>
      <AppBar sx={HEADER_STYLES.appBar}>
        <Toolbar sx={HEADER_STYLES.toolBar}>
          <Box sx={HEADER_STYLES.burgerContainer}>
            <MenuDrawer />
            <Box
              sx={{
                ...HEADER_STYLES.logoContainer,
                ...HEADER_STYLES.logoContainerHover(),
              }}
            >
              <img
                id="logo"
                src={logo?.logoUrl}
                onClick={() => handleOnNavigate('/')}
                alt="kiwin-logo"
                loading="lazy"
              />
            </Box>
          </Box>
          <CenterNav
            user={user}
            isHasCryptoPermission={isHasCryptoPermission}
            isHasRecognitionsPermission={isHasRecognitionsPermission}
            catalog={dictionary.centerNavbar.headers.catalog}
            crypto={dictionary.centerNavbar.headers.crypto}
            cup={dictionary.centerNavbar.headers.cup}
            recognitions={dictionary.centerNavbar.headers.recognitions}
          />
          <RightNavbar
            user={user}
            getInTouch={dictionary.rightNavbar.headers.getInTouch}
            logIn={dictionary.rightNavbar.headers.logIn}
            handleLogout={handleLogout}
            accountMenu={{
              userName,
              lastName,
              email,
              avatar,
              anchor,
              isMenuOpen,
              tooltipTitle: dictionary.rightNavbar.accountMenu.tooltipTitle,
              transactions: dictionary.rightNavbar.accountMenu.transactions,
              personalInformation: dictionary.rightNavbar.accountMenu.personalInformation,
              getInTouch: dictionary.rightNavbar.accountMenu.getInTouch,
              logOut: dictionary.rightNavbar.accountMenu.logOut,
              puntos,
              handleOnClick,
              handleOnNavigate,
              handleLogout,
              handleOnClose,
            }}
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
