import { API_CLIENT_URL } from '../../common/constants';
import { ClientFooterData } from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const getClientFooterData = async (idCliente: number): Promise<ClientFooterData> => {
  const URL: string = API_CLIENT_URL.footer.replace('{idCliente}', String(idCliente));

  try {
    const footer: ClientFooterData = await fetchContent(URL);

    return footer;
  } catch (error: any) {
    console.error('[getClientFooterData]: ' + error);
    throw handleServiceError(error);
  }
};
