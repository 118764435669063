import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { SWITCH_CRYPTO_POINTS_EXCHANGER_STYLES } from '../..';

interface SwitchCryptoPointsExchangerProps {
  title: string;
  linkText: string;
  route: string;
}

export const SwitchCryptoPointsExchanger: React.FC<SwitchCryptoPointsExchangerProps> = ({ title, linkText, route }) => {
  return (
    <Box sx={SWITCH_CRYPTO_POINTS_EXCHANGER_STYLES.mainContainer}>
      <Typography variant="subtitle1" color="primary">
        {title}
      </Typography>
      <Link to={route}>
        <Typography variant="subtitle1" color="primary" fontWeight={'bold'}>
          &nbsp;{linkText}
        </Typography>
      </Link>
    </Box>
  );
};
