import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '../../../context';
import { DEFAULT_STALE_TIME } from '../../constants/constants';
import { getEducationLevelVideo } from '../../../services/video';

export const useEducationLevelVideo = () => {
  const appContext = useContext(AppContext);
  const idUsuario: number = appContext?.user?.idUsuario;

  const educationLevelVideoQuery = useQuery({
    queryKey: ['education-level-video', idUsuario],
    queryFn: async () => await getEducationLevelVideo(),
    staleTime: DEFAULT_STALE_TIME,
    enabled: Boolean(idUsuario),
  });

  return { educationLevelVideoQuery };
};
