import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStates } from '../../../services/state';
import { AppContext } from '../../../context';

interface StateProps {
  isFetchingStates?: boolean;
}

export const useStateAPI = ({ isFetchingStates }: StateProps) => {
  const appContext = useContext(AppContext);
  const idUsuario: number = appContext?.user?.idUsuario;

  const statesQuery = useQuery({
    queryKey: ['states', { idUsuario }],
    queryFn: async () => await getStates(),
    enabled: Boolean(idUsuario && isFetchingStates),
  });

  return { statesQuery };
};
