import { useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const useAutoLogout = (token: string | null | undefined) => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const handleLogout = useCallback(() => {
    appContext?.cerrarSession();
    navigate("/login?expiredToken=true");
  }, [appContext, navigate]);

  useEffect(() => {
    if (!token) {
      return; // No hay token, no se necesita auto logout
    }
    (async () => {
      if (sessionStorage.getItem("expiredToken") !== null) {
        const expiredToken = await sessionStorage.getItem("expiredToken");
        const expirationDate = expiredToken
          ? new Date(JSON.parse(expiredToken)).getTime()
          : Date.now();
        // Calcular el tiempo restante hasta la expiración
        const currentTime = new Date().getTime();
        const timeRemaining = expirationDate - currentTime;

        // Configurar un temporizador para el logout
        const logoutTimer = setTimeout(() => {
          handleLogout();
        }, timeRemaining);

        // Limpieza al desmontar el componente o cuando el token cambie
        return () => {
          clearTimeout(logoutTimer);
        };
      }
    })();
  }, [token, appContext, handleLogout]);

  return null; // Este hook no renderiza nada
};

export default useAutoLogout;
