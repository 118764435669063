import React from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import { Close as CloseIcon, LinkedIn as LinkedInIcon } from '@mui/icons-material/';
import { SHARE_ON_LINKEDIN_MODAL_STYLES } from './styles';

interface DetailModalProps {
  image: string;
  title: string;
  description: string;
  linkedInBtn: string;
  points: string;
  isModalOpen: boolean;
  shareOnLinkedIn: () => void;
  setIsModalOpen: (isModalOpen: boolean) => void;
}

export const ShareOnLinkedInModal: React.FC<DetailModalProps> = ({
  image,
  title,
  description,
  points,
  linkedInBtn,
  isModalOpen,
  shareOnLinkedIn,
  setIsModalOpen,
}) => {
  return (
    <>
      <Box sx={SHARE_ON_LINKEDIN_MODAL_STYLES.modalContainer}>
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Box sx={SHARE_ON_LINKEDIN_MODAL_STYLES.modal}>
            <Box sx={SHARE_ON_LINKEDIN_MODAL_STYLES.closeIconContainer} onClick={() => setIsModalOpen(false)}>
              <IconButton>
                <CloseIcon />
              </IconButton>
            </Box>

            {image && <img src={image} alt="Imagen" style={SHARE_ON_LINKEDIN_MODAL_STYLES.image} />}

            <Typography sx={SHARE_ON_LINKEDIN_MODAL_STYLES.title}>{title}</Typography>

            <Typography sx={SHARE_ON_LINKEDIN_MODAL_STYLES.description}>{description}</Typography>

            {points && <Typography sx={SHARE_ON_LINKEDIN_MODAL_STYLES.points}>{points}</Typography>}

            <Box sx={SHARE_ON_LINKEDIN_MODAL_STYLES.linkedInBtnContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={shareOnLinkedIn}
                style={SHARE_ON_LINKEDIN_MODAL_STYLES.linkedInBtn}
                startIcon={<LinkedInIcon />}
              >
                {linkedInBtn}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};
