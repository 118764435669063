import { CSSProperties } from 'styled-components';

export const SELECT_GIFT_CARD_STYLES = {
  formControl: {
    m: 1,
    width: '17rem',
    display: 'flex',
  } as CSSProperties,
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px',
  } as CSSProperties,
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: "'Montserrat',sans-serif",
    fontSize: 14,
    gap: '5px',
    color: 'primary.main',
  } as CSSProperties,
  menuProps: {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 120,
      } as CSSProperties,
    },
  },
};
