import {
  IMadeRecognitionsActions,
  IMadeRecognitionsRows,
  IRecognitionsData,
} from '../../common/types/recognitions';
import { formatNumber } from '../functions';

export const madeRecognitionsFormatTableRows = (
  rows: IRecognitionsData[],
  actions: IMadeRecognitionsActions[]
): IMadeRecognitionsRows[] => {
  return rows?.map(
    ({
      idReconocimiento,
      fechaString,
      descripcionTipoReconocimiento,
      nombreUsuarioReconocido,
      nombreUsuarioSupervisor,
      fechaAprobacionString,
      puntos,
    }) => {
      return {
        idReconocimiento,
        fechaString: fechaString ?? '-',
        descripcionTipoReconocimiento,
        nombreUsuarioReconocido,
        nombreUsuarioSupervisor,
        puntos: formatNumber(puntos) || '-',
        estado: actions.find((el) => el.idReconocimiento === idReconocimiento)?.estadoBoton,
        fechaAprobacionString: fechaAprobacionString || '-',
        descripcion: actions.find((el) => el.idReconocimiento === idReconocimiento)?.detalle,
      };
    }
  );
};
