import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '../../../context';
import { getCountries } from '../../../services/county';

interface CountryProps {
  isFetchingCountries?: boolean;
}

export const useCountry = ({ isFetchingCountries }: CountryProps) => {
  const appContext = useContext(AppContext);
  const idUsuario: number = appContext?.user?.idUsuario;

  const countriesQuery = useQuery({
    queryKey: ['countries', { idUsuario }],
    queryFn: async () => await getCountries(),
    enabled: Boolean(idUsuario && isFetchingCountries),
  });

  return { countriesQuery };
};
