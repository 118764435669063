import React, { useState } from 'react';

export const useRightSide = () => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleOnClose = (): void => {
    setAnchor(null);
    setIsMenuOpen(false);
  };

  return {
    anchor,
    isMenuOpen,
    handleOnClick,
    handleOnClose,
  };
};
