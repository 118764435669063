import { useQuery } from '@tanstack/react-query';
import { IConsolidatedPersonProps, ITransactionsPerson } from '../../types';
import { getTransactionDetail, getConsolidatedPerson, getConsolidatedPersonHome } from '../../../services/transactions';
import { useAppContext } from '../useAppContext';

interface ITransactionsPersonProps {
  transactionsPersonHome?: ITransactionsPerson;
  transactionsPerson?: IConsolidatedPersonProps;
  idMovimiento?: number;
}

// ? CAMBIAR EL NOMBRE DE ESTE HOOK por useTransaction
export const useTransactionsPerson = ({
  transactionsPersonHome,
  transactionsPerson,
  idMovimiento,
}: ITransactionsPersonProps) => {
  const { idUsuario } = useAppContext();

  const consolidatedHomeQuery = useQuery({
    queryKey: ['transactions-person-consolidated-home', { idUsuario, idioma: transactionsPersonHome?.idioma }],
    queryFn: async () => {
      const res = await getConsolidatedPersonHome({
        idioma: transactionsPersonHome?.idioma,
        cantidadRegistros: transactionsPersonHome?.cantidadRegistros,
      });
      return res;
    },
    enabled: Boolean(idUsuario && transactionsPersonHome),
  });

  const consolidatedQuery = useQuery({
    queryKey: [
      'transactions-person-consolidated',
      {
        idUsuario,
        idioma: transactionsPerson?.idioma,
        pagina: transactionsPerson?.pagina,
        ordenamiento: transactionsPerson?.ordenamiento,
        ...(transactionsPerson?.idTipoMovimiento && {
          idTipoMovimiento: transactionsPerson.idTipoMovimiento,
        }),
      },
    ],
    queryFn: async () => {
      const data = await getConsolidatedPerson({
        idioma: transactionsPerson?.idioma,
        ordenamiento: transactionsPerson?.ordenamiento,
        pagina: transactionsPerson?.pagina,
        cantidadRegistrosPorPagina: transactionsPerson?.cantidadRegistrosPorPagina,
        ...(transactionsPerson?.idTipoMovimiento && {
          idTipoMovimiento: transactionsPerson.idTipoMovimiento,
        }),
      });
      return data;
    },
    enabled: Boolean(idUsuario && transactionsPerson),
  });

  const transactionDetailQuery = useQuery({
    queryKey: ['transaction-detail', { idMovimiento }],
    queryFn: async () => await getTransactionDetail(idMovimiento),
    enabled: Boolean(idUsuario && idMovimiento > 0),
  });

  return {
    consolidatedHomeQuery,
    consolidatedQuery,
    transactionDetailQuery,
  };
};
