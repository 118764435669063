import styled from "styled-components";

// const ComponentName = styled.DOMElementTag`
//     cssProperty: cssValue
// `

type Props = {
    bgColor?: string,
}

export const PopCategoriesSection = styled.section`
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const PopCategoriesTitle = styled.h1`
    font-size: 3vw;
    text-align: center;
`
export const PopCategoriesContainer = styled.div`
    width: 80%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-bottom: 60px;
`

export const PopCategoryBox = styled.div`
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    transition: 0.3s;
    flex: 1 1 180px;
    max-width: 250px;
    margin: 15px;
    border-radius: 20px;
    cursor: pointer;
    overflow: hidden;

    @media (max-width:768px) {
        & {
            max-width: 300px;
            min-width: 210px;
        }
    }
`
export const PopCategoryImage = styled.img`
    object-fit: contain;
    max-height: 300px;
    min-width: 210px;
    max-width: 100%;
`

export const PopCategoryTitle = styled.span`
    height: 85px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const PopCatergoriesButton = styled.button<Props>`
    background-color: ${({bgColor}) => bgColor || 'orange'};
    border: none;
    color: #ffffff;
    font-size: 22px;
    padding: 10px 40px;
    border: 1px solid ${({bgColor}) => bgColor || 'orange'};
    border-radius: 20px;

    &:hover {
        background-color: #ffffff;
        color: ${({bgColor}) => bgColor || 'orange'};
    }
`