import React from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import ButtonSubmit from '../../../../components/ButtonSubmit/ButtonSubmit';
import { IRecognitionsDictionary, IRecognitionsForm } from '../../../../common/types';
import './addComment.css';

interface Comment {
  dictionary: IRecognitionsDictionary;
  form: IRecognitionsForm;
  isSendingRecognition: boolean;
  setForm: (form: IRecognitionsForm) => void;
  handleSubmit: () => void;
  setIsSendingRecognition: (isSendingRecognition: boolean) => void;
}

export const AddComment: React.FC<Comment> = ({
  dictionary,
  form,
  isSendingRecognition,
  setIsSendingRecognition,
  setForm,
  handleSubmit,
}) => {
  return (
    <Grid item xs={12}>
      <Typography fontSize={18} color="primary" pb={2}>
        {dictionary.form.addComment} (*)
      </Typography>
      <TextField
        id="outlined-multiline-static"
        multiline
        rows={4}
        value={form.descripcion}
        className="recognition-comment"
        onChange={(e: any) => setForm({ ...form, descripcion: e.target.value })}
      />
      <Box textAlign={'center'}>
        <ButtonSubmit
          disabled={!form.descripcion || !form.idTipoReconocimiento || form.usuariosReconocidos.length === 0}
          isLoading={isSendingRecognition}
          setIsLoading={setIsSendingRecognition}
          onClick={handleSubmit}
        >
          {dictionary.form.create.buttons.send}
        </ButtonSubmit>
      </Box>
    </Grid>
  );
};
