import React from 'react';
import { ProductsDetailWeb } from '../../../../common/types';
import { useProductDetail } from '../../../../hooks/product';
import { BranchesModal, ProductDetailCard } from '..';

type Props = {
  productDetail: ProductsDetailWeb;
};

export const ProductDetail: React.FC<Props> = ({
  productDetail: {
    idProducto,
    bigPicture,
    terminosYCondiciones,
    marcaDescripcion,
    descripcion,
    productos,
    precioDesde,
    precioHasta,
    esOhGiftCard,
  },
}) => {
  const {
    product,
    isOffer,
    points,
    prevPoints,
    amount,
    dictionary,
    branches,
    isBranchesModalOpen,
    isTermsModalOpen,
    state,
    isError,
    maxPrice,
    isGiftModalOpen,
    isBranchesLoading,
    amountOfGiftCards,
    giftCard,
    handleOnClickModal,
    setIsTermsModalOpen,
    setIsBranchesModalOpen,
    getCurrency,
    handleOnClick,
    handleArrowOnClick,
    handleOnInputChange,
    handleChange,
    handleSubmit,
    handleRedeem,
    handleOnClickSelectGiftCardButton,
    handleOnSelectGiftCardChange,
  } = useProductDetail(String(idProducto));

  return (
    <>
      <BranchesModal
        open={isBranchesModalOpen}
        title={dictionary.branchesModal.title}
        branches={branches}
        isBranchesLoading={isBranchesLoading}
        handleClose={() => setIsBranchesModalOpen(!isBranchesModalOpen)}
      />

      <ProductDetailCard
        termsAndConditions={{
          image: bigPicture,
          title: dictionary.terms,
          terms: terminosYCondiciones,
          isOpen: isTermsModalOpen,
          setIsOpen: setIsTermsModalOpen,
        }}
        titleContainer={{
          brandDescription: marcaDescripcion,
          isSale: state?.oferta,
          discount: dictionary.discount.replace('{discount}', String(state?.porcentajeDescuento)),
          description: descripcion,
        }}
        saleContainer={{
          points,
          sale: prevPoints,
          pointsText: dictionary.points.sale,
          hasOffer: product && prevPoints && isOffer,
        }}
        amountSelector={{
          amountText: dictionary.amountSelector.title,
          isShowingAmountSelector: Boolean(amountOfGiftCards > 0),
          amountOfGiftCards,
          handleOnClickSelectGiftCardButton,
        }}
        productsToRedeem={{
          amount,
          isError,
          maxPrice,
          exchangeNotAllowed: dictionary.exchangeNotAllowed,
          isShowingGiftCardList: Boolean(productos?.length > 1),
          isRangeOfPrices: precioDesde !== precioHasta,
          currency: getCurrency(product?.codigoMoneda),
          product,
          products: productos,
          salePrice: precioDesde,
          minPriceText: dictionary.prices.min,
          maxPriceText: dictionary.prices.max,
          giftCardPrice: giftCard,
          selectAGiftCardButtonText: dictionary.buttons.selectAGiftCard,
          handleOnInputChange,
          handleOnClick,
          handleArrowOnClick,
          handleOnSelectGiftCardChange,
        }}
        giftModal={{
          product,
          isError,
          precioHasta,
          isUniqueProduct: !isError,
          isRangeOfPrices: precioDesde !== precioHasta,
          form: {
            title: dictionary.giftModal.title,
            buttons: dictionary.giftModal.form.buttons,
            inputs: dictionary.giftModal.form.inputs,
          },
          isGiftModalOpen,
          handleOnClickModal,
          handleChange,
          handleSubmit,
        }}
        redeemButton={{
          redeemText: dictionary.buttons.redeem,
          product,
          precioHasta,
          isError,
          isUniqueProduct: !isError,
          isRangeOfPrices: precioDesde !== precioHasta,
          handleRedeem,
        }}
        branchesButton={{
          branches: dictionary.buttons.branches,
          product,
          isError,
          esOhGiftCard,
          isBranchesModalOpen,
          setIsBranchesModalOpen,
        }}
      />
    </>
  );
};
