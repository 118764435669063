import { useContext } from 'react';
import { AppContext } from '../../context';
import { cliente } from '../constants';

export const useAppContext = () => {
  const { user, permisos, setToken, cerrarSession, setUser, guardarPermisos } = useContext(AppContext);

  const handleLogout = (): void => cerrarSession();

  return {
    user,
    userName: user?.nombre,
    lastName: user?.apellido,
    email: user?.email,
    idUsuario: user?.idUsuario,
    idPersona: user?.idPersona,
    idCliente: cliente,
    permissions: permisos,
    handleLogout,
    setToken,
    setUser,
    setPermissions: guardarPermisos,
  };
};
