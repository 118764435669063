import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import './SelectForm.css';
import EN from '../../../common/language/shared/en.json';
import ES from '../../../common/language/shared/es.json';
import useLanguage from '../../../common/hooks/useLanguage';

type Props = {
  readonly?: boolean;
  name: string;
  label: string;
  children: ReactNode;
  //   type: string;
  form: any;
  required?: boolean;
  setForm: Dispatch<SetStateAction<any>>;
  err?: boolean;
};
const SelectForm: React.FC<Props> = ({ form, setForm, label, children, name, readonly, required, err }) => {
  const { dictionary } = useLanguage({ EN, ES });
  const handleChange = (e: any) => {
    setForm({ ...form, [name]: e.target.value });
  };

  return (
    <div className="input-container">
      <label htmlFor="">
        {label} {required && <b className="input-form--required">*</b>}
      </label>
      <select
        disabled={readonly}
        className={`select-form ${err && !form[name] ? 'select-form--error' : ''}`}
        name=""
        id=""
        onChange={handleChange}
        value={form[name] ? form[name] : -1}
      >
        <option disabled defaultChecked value={-1}>
          {dictionary?.inputs.seleccioneOpcion}
        </option>
        {children}
      </select>
    </div>
  );
};

export default SelectForm;
