import { TableCell, TableRow } from '@mui/material';
import { ICustomSimpleTableRows } from '../../common/types';

const CustomTableRow = (rows: ICustomSimpleTableRows) => {
  const values = Object.values(rows);

  return (
    <TableRow>
      {values?.map((prop, index) => {
        return (
          <TableCell key={index} align="center">
            {prop}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default CustomTableRow;
