import { formatCommonTransactionDetail } from '..';
import { ITransactionDetail, ITransactionDetailFormateadData } from '../../common/types';

export const formatRecognitionDetail = (transaction: ITransactionDetail): ITransactionDetailFormateadData => {
  if (!transaction?.reconocimiento) return null;

  const { date, points } = formatCommonTransactionDetail(transaction?.fecha, transaction?.reconocimiento?.puntos);

  const formateadTransaction: ITransactionDetailFormateadData = {
    date,
    points,
  };

  return formateadTransaction;
};
