import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import ShortLine from '../../../../../components/ShortLine/ShortLine';
import { City, Country, SignUpCryptoDictionary, State, CustomerBody } from '../../../../../common/types';
import ButtonSubmit from '../../../../../components/ButtonSubmit/ButtonSubmit';
import { BasicFields, ContactFields, GeoLocation, SIGN_UP_CRYPTO_FORM_STYLES } from '../../..';

interface SignUpCryptoFormProps {
  countries: Country[];
  cities: City[];
  states: State[];
  form: CustomerBody;
  isFormFilled: boolean;
  isCreatingCustomer: boolean;
  dictionary: SignUpCryptoDictionary;
  setIsCreatingCustomer: (isCreatingCustomer: boolean) => void;
  setForm: (form: CustomerBody) => void;
  handleSubmit: () => void;
}

export const SignUpCryptoForm: React.FC<SignUpCryptoFormProps> = ({
  countries,
  cities,
  states,
  form,
  isCreatingCustomer,
  isFormFilled,
  dictionary,
  setIsCreatingCustomer,
  setForm,
  handleSubmit,
}) => {
  return (
    <Box sx={SIGN_UP_CRYPTO_FORM_STYLES.formContainer}>
      <Typography variant="h6" color="primary" maxWidth={'550px'} textAlign={'center'}>
        {dictionary.subtitle}
        <ShortLine position="center" />
      </Typography>

      <Box
        p={'40px 20px'}
        border={3}
        borderColor={'primary.main'}
        maxWidth={'830px'}
        borderRadius={'15px'}
        width={'100%'}
      >
        <BasicFields
          name={dictionary.form.name}
          middleName={dictionary.form.middleName}
          lastName={dictionary.form.lastName}
          dni={dictionary.form.dni}
          dateOfBirth={dictionary.form.dateOfBirth}
          form={form}
          setForm={setForm}
        />

        <Box m={2}>
          <Divider />
        </Box>

        <GeoLocation
          fullAddress={dictionary.form.fullAddress}
          country={dictionary.form.country}
          state={dictionary.form.state}
          city={dictionary.form.city}
          zipCode={dictionary.form.zipCode}
          countries={countries}
          states={states}
          cities={cities}
          form={form}
          setForm={setForm}
        />

        <Box m={2}>
          <Divider />
        </Box>

        <ContactFields
          email={dictionary.form.email}
          phoneNumber={dictionary.form.phoneNumber}
          form={form}
          setForm={setForm}
        />

        <Box textAlign={'center'} pt={5}>
          <ButtonSubmit
            disabled={!isFormFilled}
            isLoading={isCreatingCustomer}
            setIsLoading={setIsCreatingCustomer}
            onClick={handleSubmit}
          >
            {dictionary.buttons.next}
          </ButtonSubmit>
        </Box>
      </Box>
    </Box>
  );
};
