import { Link as RouterLink } from 'react-router-dom';
import { Box, Hidden, Typography } from '@mui/material';
import {
  PhoneInTalkOutlined as PhoneInTalkOutlinedIcon,
  AccountCircleOutlined as AccountCircleOutlinedIcon,
} from '@mui/icons-material/';
import { LanguageSelect } from '../../LanguageSelect/LanguageSelect';
import { AccountMenu } from '../../AccountMenu/AccountMenu';
import { RUTAS } from '../../../common/constants/rutas';
import { AuthUser } from '../../../context';
import { AccountMenuProps } from '../../../common/interfaces/header';
import { RIGHT_NAVBAR_STYLES } from './styles';
import { ShoppingCart } from '../../ShoppingCart/ShoppingCart';

interface RightNavbarProps {
  user: AuthUser;
  getInTouch: string;
  logIn: string;
  accountMenu: AccountMenuProps;
  handleLogout: () => void;
}

export const RightNavbar: React.FC<RightNavbarProps> = ({ user, getInTouch, logIn, accountMenu, handleLogout }) => {
  return (
    <Box
      fontSize={14}
      color="primary.main"
      sx={RIGHT_NAVBAR_STYLES.mainContainer}
      justifyContent="center"
      display={'flex'}
      alignContent={'center'}
      zIndex={2000}
    >
      {!user && (
        <Box sx={RIGHT_NAVBAR_STYLES.mainContainer}>
          <Hidden mdDown={true}>
            <RouterLink to={RUTAS.contacto} style={RIGHT_NAVBAR_STYLES.routerLink}>
              <PhoneInTalkOutlinedIcon sx={RIGHT_NAVBAR_STYLES.icon} />
              <Typography variant="subtitle2">{getInTouch}</Typography>
            </RouterLink>
          </Hidden>
          <RouterLink to={RUTAS.login} style={RIGHT_NAVBAR_STYLES.routerLink} onClick={handleLogout}>
            <AccountCircleOutlinedIcon sx={RIGHT_NAVBAR_STYLES.icon} />
            <Hidden mdDown={true}>
              <Typography variant="subtitle2">{logIn}</Typography>
            </Hidden>
          </RouterLink>
        </Box>
      )}

      <LanguageSelect />

      {user && (
        <>
          <AccountMenu
            userName={accountMenu.userName}
            lastName={accountMenu.lastName}
            email={accountMenu.email}
            avatar={accountMenu.avatar}
            anchor={accountMenu.anchor}
            isMenuOpen={accountMenu.isMenuOpen}
            transactions={accountMenu.transactions}
            getInTouch={accountMenu.getInTouch}
            logOut={accountMenu.logOut}
            personalInformation={accountMenu.personalInformation}
            puntos={accountMenu.puntos}
            tooltipTitle={accountMenu.tooltipTitle}
            handleOnClick={accountMenu.handleOnClick}
            handleOnNavigate={accountMenu.handleOnNavigate}
            handleLogout={accountMenu.handleLogout}
            handleOnClose={accountMenu.handleOnClose}
          />
          <ShoppingCart />
        </>
      )}
    </Box>
  );
};
