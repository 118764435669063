import { CSSProperties } from 'styled-components';

export const STYLES_DASHBOARD = {
  dashboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  } as CSSProperties,
  titleContainer: {
    width: '100%',
    padding: '20px 0 0 20px',
  } as CSSProperties,
  contentContainer: {
    width: '100%',
    padding: '0 20px',
    marginBottom: '40px',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px',
  } as CSSProperties,
};
