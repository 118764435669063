import { Box, Grid, LinearProgress, linearProgressClasses, styled, Typography } from '@mui/material';
import DashboardBox from '../DashboardBox/DashboardBox';
import { ICategoriesRedeemed } from '../../../../common/types';
import MiniLoader from '../../../../components/Loaders/MiniLoader/MiniLoader';
import { NoResults } from '../../../../components/NoResults/NoResults';
import { formatNumber } from '../../../../utils/functions';
import { STYLES_MOST_CONSUMED } from '../..';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: '80%',
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

interface IMostConsumedProducts {
  categoriesRedeemed: ICategoriesRedeemed[];
  title: string;
  isPermisos: boolean;
  isLoading: boolean;
  isError: boolean;
  noResults: string;
  route: string;
}

export const MostConsumedProducts: React.FC<IMostConsumedProducts> = ({
  categoriesRedeemed,
  title,
  isPermisos,
  isLoading,
  isError,
  noResults,
  route,
}) => {
  return (
    <Grid item xs={12} sm={12} md={isPermisos ? 4 : 8}>
      <DashboardBox title={title} ruta={route} color="white">
        <Box sx={STYLES_MOST_CONSUMED.mostConsumedContainer}>
          {isLoading ? (
            <MiniLoader />
          ) : (
            (isError || !categoriesRedeemed?.length) && (
              <NoResults text={noResults} {...STYLES_MOST_CONSUMED.noResults} />
            )
          )}

          {categoriesRedeemed?.map(({ categoriaId, nombre, color, porcentaje }) => (
            <Box key={categoriaId} sx={STYLES_MOST_CONSUMED.categories}>
              <Typography variant="subtitle1">{nombre}</Typography>

              <Box sx={STYLES_MOST_CONSUMED.progressBarContainer}>
                <BorderLinearProgress
                  sx={STYLES_MOST_CONSUMED.borderLinearProgress(color)}
                  variant="determinate"
                  value={porcentaje}
                />
                <Typography sx={STYLES_MOST_CONSUMED.percentage}>{formatNumber(porcentaje)}%</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </DashboardBox>
    </Grid>
  );
};
