import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  IRecognitions,
  IApprovedRecognitionsDictionary,
  IApprovedRecognitionsUpdated,
  IApprovedRecognitionsUpdateForm,
  IRecognitionsSort,
  IMyTeamSort,
  IMyTeamSupervisorBudget,
  STATUS_APPROVED_RECOGNITION,
} from '../../../common/types/recognitions';
import { updateApprovedRecognition } from '../../../services/recognitions/approved/updateApprovedRecognition';
import { notificacion } from '../../../utils/functions';

interface Approved {
  recognition: IApprovedRecognitionsUpdateForm;
  sort: IRecognitionsSort | IMyTeamSort;
  dictionary: IApprovedRecognitionsDictionary;
  language: string;
  idUsuario: number;
  page: number;
  search: string;
  puntosTotales: number;
  setForm: (form: IApprovedRecognitionsUpdateForm) => void;
}

export const useApproveRecognitionMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (recognitions: Approved): Promise<IApprovedRecognitionsUpdated> =>
      await updateApprovedRecognition(recognitions.recognition),

    onMutate: async (recognitions) => {
      const { idUsuario, language, page, search, sort, recognition, puntosTotales } = recognitions;

      await queryClient.cancelQueries({
        queryKey: ['approved-recognitions', { language, idUsuario, page, sort, search }],
      });

      let currentData = queryClient.getQueryData([
        'approved-recognitions',
        { language, idUsuario, page, sort, search },
      ]);

      queryClient.setQueryData<IRecognitions>(
        ['approved-recognitions', { language, idUsuario, page, sort, search }],
        (old) => {
          old.data = old.data.map((oldRecognition) => {
            const getRecognition: boolean = recognition.idReconocimientos.includes(oldRecognition.idReconocimiento);
            if (getRecognition) {
              return (oldRecognition = { ...oldRecognition, estado: STATUS_APPROVED_RECOGNITION.approved });
            } else {
              return oldRecognition;
            }
          });
          return old;
        }
      );

      queryClient.setQueryData<IMyTeamSupervisorBudget>(['budget-supervisor', { idUsuario }], (old) => {
        const isSupervisor: boolean = old.idSupervisor === idUsuario;
        if (isSupervisor) {
          old.presupuesto -= puntosTotales;
        }
        return old;
      });

      return { currentData };
    },

    onSuccess(_, variables) {
      const { recognition, dictionary, setForm } = variables;

      notificacion(true, dictionary.form.create.success);
      setForm({
        ...recognition,
        idReconocimientos: [],
      });
    },

    onError(_, variables) {
      const { idUsuario, language, page, search, sort, recognition, dictionary, puntosTotales, setForm } = variables;

      notificacion(false, dictionary.form.create.error);
      setForm({
        ...recognition,
        idReconocimientos: [],
      });

      queryClient.setQueryData<IRecognitions>(
        ['approved-recognitions', { language, idUsuario, page, sort, search }],
        (old) => {
          old.data = old.data.map((oldRecognition) => {
            const getRecognition: boolean = recognition.idReconocimientos.includes(oldRecognition.idReconocimiento);

            if (getRecognition) {
              return (oldRecognition = { ...oldRecognition, estado: STATUS_APPROVED_RECOGNITION.pending });
            } else {
              return oldRecognition;
            }
          });
          return old;
        }
      );

      queryClient.setQueryData<IMyTeamSupervisorBudget>(['budget-supervisor', { idUsuario }], (old) => {
        const isSupervisor: boolean = old.idSupervisor === idUsuario;

        if (isSupervisor) {
          old.presupuesto += puntosTotales;
        }
        return old;
      });
    },
  });

  return { mutation };
};
