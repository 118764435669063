export interface PermissionsEnvironment {
  idPermiso: number;
  id: number;
  codigo: string;
  descripcion: string;
  idPermisoPadre: number | null;
}

export enum TypesOfPermissionsEnvironment {
  frontend = 'FRONTEND',
}
