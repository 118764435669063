import React from 'react';
import {
  CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
  AccessTimeOutlined as AccessTimeOutlinedIcon,
  PauseCircleOutlineOutlined as PauseCircleOutlineOutlinedIcon,
  LocalShippingOutlined as LocalShippingOutlinedIcon,
} from '@mui/icons-material/';
import { Box, Typography } from '@mui/material';
import { IDsTypeOfStatus } from '../../../../common/types';

const statusConfig = [
  {
    id: IDsTypeOfStatus.pendiente,
    icon: <AccessTimeOutlinedIcon color="warning" />,
  },
  {
    id: IDsTypeOfStatus.enPreparacion,
    icon: <PauseCircleOutlineOutlinedIcon color="info" />,
  },
  {
    id: IDsTypeOfStatus.enviado,
    icon: <CheckCircleOutlineOutlinedIcon color="success" />,
  },
  {
    id: IDsTypeOfStatus.entregado,
    icon: <LocalShippingOutlinedIcon color="success" />,
  },
];

const StatusIcon = ({ idStatus }: { idStatus: number }) => {
  const iconFound: React.ReactNode = statusConfig.find((item) => item.id === idStatus)?.icon;
  return iconFound;
};

interface ExchangeStatusProps {
  idStatus: number;
  statusTitle: string;
  status: string;
}

export const ExchangeStatus: React.FC<ExchangeStatusProps> = ({ idStatus, statusTitle, status }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      py={1}
      gap={1}
    >
      <StatusIcon idStatus={idStatus} />
      <Typography variant="body2">
        {statusTitle}
        {status}
      </Typography>
    </Box>
  );
};
