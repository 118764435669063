import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import MiniLoader from '../../../../components/Loaders/MiniLoader/MiniLoader';
import { useLiriumHistoricalPrice } from '../../../../common/hooks';
import { DEFAULT_CURRENCY_HISTORICAL_PRICE } from '../../../../common/constants/constants';
import { CURRENCY_GRAPHIC_STYLES } from '..';
import { formatCurrency } from '../../../../utils';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface CurrencyGraphicProps {
  currency: string;
  currencyType: string;
  title: string;
}

export const CurrencyGraphic: React.FC<CurrencyGraphicProps> = ({ currency, currencyType, title }) => {
  const {
    currencyPriceQuery: { data: currencyPrice },
  } = useLiriumHistoricalPrice({
    historicalPrice: {
      currency,
      amount: DEFAULT_CURRENCY_HISTORICAL_PRICE,
      currencyType,
    },
  });

  return (
    <Box sx={CURRENCY_GRAPHIC_STYLES.graphicContainer}>
      <Box textAlign={'center'} sx={CURRENCY_GRAPHIC_STYLES.mainBox}>
        <Typography variant="subtitle1" color="initial">
          {currencyType}
        </Typography>
        {currencyPrice ? (
          <>
            <Typography variant="caption" color="initial">
              {title}
            </Typography>
            <Line
              options={{
                scales: {
                  x: {
                    ticks: {
                      font: {
                        size: 10,
                      },
                    },
                  },
                  y: {
                    ticks: {
                      callback: function (value: any) {
                        const valueLegend = this.getLabelForValue(value);
                        valueLegend.replaceAll('.', '');
                        return valueLegend.replaceAll('.', '');
                      },
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                responsive: true,
                maintainAspectRatio: true,
              }}
              data={formatCurrency(currencyPrice)}
            />
          </>
        ) : (
          <MiniLoader />
        )}
      </Box>
    </Box>
  );
};
