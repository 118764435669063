import React from 'react';
import { Button, Grid } from '@mui/material';
import { RedeemButtonProps } from '../../../../common/types';

interface Props {
  redeemButton: RedeemButtonProps;
}

export const RedeemButton: React.FC<Props> = ({
  redeemButton: { redeemText, precioHasta, product, isRangeOfPrices, isUniqueProduct, isError, handleRedeem },
}) => {
  const isDisabled: boolean =
    !product || (isError && !precioHasta && isUniqueProduct) || (isError && precioHasta && isRangeOfPrices);

  return (
    <Grid item sm={6} md={6}>
      <Button disabled={isDisabled} variant="contained" color="btnPrimary" fullWidth onClick={handleRedeem}>
        {redeemText}
      </Button>
    </Grid>
  );
};
