import { useState, useEffect, ReactNode } from 'react';
import { useQuery } from '@tanstack/react-query';
import ES from '../../../common/language/recognitions/approved/es.json';
import EN from '../../../common/language/recognitions/approved/en.json';
import useLanguage from '../../../common/hooks/useLanguage';
import { getApprovedRecognitions, getBudgetSupervisor } from '../../../services/recognitions';
import {
  IApprovedRecognitionsDictionary,
  IApprovedRecognitionsUpdateForm,
  IApprovedRecognitionsActions,
  IApprovedRecognitionsRows,
  IFormatTableColumns,
  RecognitionExcludeSortingColumns,
  STATUS_APPROVED_RECOGNITION,
} from '../../../common/types/recognitions';
import { CANTIDAD_REGISTROS_POR_PAGINA, STALE_TIME_RECOGNITION } from '../../../common/constants';
import { notificacion } from '../../../utils/functions';
import { cliente } from '../../../common/constants/constants';
import {
  formatTableColumns,
  approvedRecognitionsFormatTableRows,
  showButtonApprove,
} from '../../../utils/recognitions';
import { Checkbox, Chip } from '@mui/material';
import { HowToReg } from '@mui/icons-material';
import { useApproveRecognitionMutation } from './useApproveRecognitionMutation';
import { useRecognitionsTable } from '../../../common/hooks/useRecognitionsTable';
import { ViewDetail } from '../../../components/ViewDetail/ViewDetail';

export const useApprovedRecognitions = () => {
  const [isApproving, setIsApproving] = useState<boolean>(false);
  const {
    idUsuario,
    search,
    language,
    page,
    totalPages,
    sort,
    setPage,
    setSearch,
    setTotalPages,
    changeSort,
    handleChangePage,
  } = useRecognitionsTable();
  const [form, setForm] = useState<IApprovedRecognitionsUpdateForm>({
    idReconocimientos: [],
    cliente,
    idUsuario,
  });
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: IApprovedRecognitionsDictionary };
  const { mutation } = useApproveRecognitionMutation();

  const budgetSupervisorQuery = useQuery({
    queryKey: ['budget-supervisor', { idUsuario }],
    queryFn: async () => await getBudgetSupervisor(idUsuario),
    staleTime: STALE_TIME_RECOGNITION,
    enabled: Boolean(idUsuario),
  });

  const approvedRecognitionsQuery = useQuery({
    queryKey: ['approved-recognitions', { language: language.language, idUsuario, page, sort, search }],
    queryFn: async () =>
      await getApprovedRecognitions({
        idioma: language.language,
        idUsuario,
        pagina: page,
        ordenamiento: `${sort.column} ${sort.direction}`,
        cantidadRegistrosPorPagina: CANTIDAD_REGISTROS_POR_PAGINA,
        ...(search && { usuario: search }),
      }),
    staleTime: STALE_TIME_RECOGNITION,
    enabled: Boolean(idUsuario),
  });

  const handleSubmit = async (): Promise<void> => {
    if (form.idReconocimientos.length === 0) {
      notificacion(false, dictionary.form.create.validationError);
      return;
    }
    const puntosTotales: number = form.idReconocimientos.reduce((total, idReconocimiento) => {
      const reco = approvedRecognitionsQuery.data?.data.find((rec) => rec.id === idReconocimiento);
      return total + (reco ? reco?.puntos : 0);
    }, 0);

    const isEnoughBudget: boolean = puntosTotales < Number(budgetSupervisorQuery.data?.presupuesto);

    if (!isEnoughBudget) {
      notificacion(false, dictionary.form.create.validationBudgetError);
      return;
    }

    await mutation.mutateAsync({
      recognition: form,
      language: language.language,
      idUsuario,
      page,
      sort,
      search,
      dictionary,
      puntosTotales,
      setForm,
    });
  };

  const handleCheckBox = (idReconocimiento: number): void => {
    const isAlreadyChecked: boolean = form.idReconocimientos.includes(idReconocimiento);
    if (isAlreadyChecked) {
      const filterChecked: number[] = form.idReconocimientos.filter((id) => id !== idReconocimiento);
      setForm({
        ...form,
        idReconocimientos: filterChecked,
      });
    } else {
      const addChecked: number[] = [...form.idReconocimientos, idReconocimiento];
      setForm({
        ...form,
        idReconocimientos: addChecked,
      });
    }
  };

  const columns: IFormatTableColumns[] = formatTableColumns(dictionary.table.column, [
    RecognitionExcludeSortingColumns.aprobar,
    RecognitionExcludeSortingColumns.descripcion,
  ]);

  const isAnyPending: boolean = showButtonApprove(
    approvedRecognitionsQuery.data?.data,
    STATUS_APPROVED_RECOGNITION.pending
  );

  //TODO ver como crear una función reutilizable para useMadeRecognitions

  const status: IApprovedRecognitionsActions[] = approvedRecognitionsQuery.data?.data.map((el) => {
    let aprobarIcono: ReactNode;
    let estadoBoton: ReactNode;
    let detalle: ReactNode = (
      <Chip
        onClick={() =>
          ViewDetail({
            title: el.nombreUsuarioAsignador,
            text: el.descripcion,
            cancelButtonText: dictionary.table.actions.detail.buttons.cancel,
            image: el.imagen,
          })
        }
        color="primary"
        label={dictionary.table.actions.detail.title}
      />
    );

    if (el.estado === STATUS_APPROVED_RECOGNITION.approved) {
      aprobarIcono = <HowToReg />;
      estadoBoton = <Chip color="success" label={dictionary.table.actions.status.approved} />;
    } else {
      aprobarIcono = (
        <Checkbox value={el.idReconocimiento} size="small" onChange={() => handleCheckBox(el.idReconocimiento)} />
      );
      estadoBoton = <Chip color="warning" label={dictionary.table.actions.status.pending} />;
    }

    return {
      idReconocimiento: el.idReconocimiento,
      aprobarIcono,
      estadoBoton,
      detalle,
    };
  });

  const approvedRecognitions: IApprovedRecognitionsRows[] = approvedRecognitionsFormatTableRows(
    approvedRecognitionsQuery.data?.data,
    status
  );

  useEffect(() => {
    if (approvedRecognitionsQuery.data?.cantidadPaginas) {
      setTotalPages(approvedRecognitionsQuery.data.cantidadPaginas);
      return;
    }
  }, [approvedRecognitionsQuery.data?.cantidadPaginas, setTotalPages]);

  return {
    budgetSupervisorQuery,
    approvedRecognitions,
    isLoading: approvedRecognitionsQuery.isLoading,
    dictionary,
    columns,
    page,
    totalPages,
    sort,
    form,
    isAnyPending,
    isApproving,
    language: language.language,
    changeSort,
    setPage,
    handleChangePage,
    setSearch,
    handleSubmit,
    setIsApproving,
  };
};
