import { API_LIRIUM_ORDER_URL, headerOptions } from '../../common/constants';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

interface ConfirmOrderProps {
  idMovimiento: number;
  idOrder: string;
  state: string;
}

export const patchConfirmOrder = async (props: ConfirmOrderProps): Promise<void> => {
  const { idMovimiento, idOrder, state } = props;

  const URL: string = API_LIRIUM_ORDER_URL.orderState
    .replace('{id}', idOrder)
    .replace('{state}', state)
    .replace('{idMovimiento}', String(idMovimiento));

  try {
    const order: void = await fetchContent(URL, {
      method: 'PATCH',
      headers: headerOptions,
    });

    return order;
  } catch (error: any) {
    console.error('[patchConfirmOrder]: ' + error);
    throw handleServiceError(error);
  }
};
