import { CSSProperties } from 'styled-components';
export const MY_TRANSACTIONS_DETAIL_CONTAINER_STYLES = {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    width: '35rem',
    maxWidth: '100%',
    boxSizing: 'border-box',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    backgroundColor: '#f8f9f4',
    borderRadius: '1rem',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    padding: '1rem',
  } as CSSProperties,
  closeIconContainer: {
    position: 'absolute',
    right: '0',
    top: '0',
  } as CSSProperties,
};
