import { Permiso } from '../../../common/interfaces/Permisos';
import { PERMISOS_CODIGOS } from '../../../common/constants/constants';
import { useLiriumUser } from '../../../common/hooks';

interface ICryptoPoints {
  permisos: Permiso[];
}

export const useCryptoPoints = (props: ICryptoPoints) => {
  const { permisos } = props;
  const isPermisosInCrypto: boolean = permisos?.filter((el) => el.codigo === PERMISOS_CODIGOS.criptomonedas).length > 0;

  const { cryptoPointsQuery } = useLiriumUser({ cryptoPoints: { isPermisosInCrypto } });

  return { cryptoPointsQuery, isPermisosInCrypto };
};
