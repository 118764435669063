import { Box, Typography } from '@mui/material';
import { IRecognitionsDictionary, IRecognitionsForm, ITypeOfRecognitions } from '../../../../common/types/recognitions';
import './typeOfRecognitionCard.css';
import { TypeOfRecognitionCard } from '../..';

interface Props {
  form: IRecognitionsForm;
  typeOfRecognitions: ITypeOfRecognitions[];
  dictionary: IRecognitionsDictionary;
  loaded: boolean;
  handleClick: (idTipoReconocimiento: number) => void;
  onLoad: () => void;
}

export const TypesOfRecognitions = (props: Props) => {
  const { form, typeOfRecognitions, dictionary, loaded, handleClick, onLoad } = props;

  return (
    <Box marginTop={4} width={'100%'} paddingY={'20px'}>
      <Typography variant="h5" color="primary">
        {dictionary.typeOfRecognitions}
      </Typography>
      <ul className="type-of-recognition-card-container">
        {typeOfRecognitions?.map((el) => (
          <TypeOfRecognitionCard
            key={`reco-card-${el.idTipoReconocimiento} `}
            data={el}
            loaded={loaded}
            isSelected={form.idTipoReconocimiento === el.idTipoReconocimiento}
            handleClick={handleClick}
            onLoad={onLoad}
          />
        ))}
      </ul>
    </Box>
  );
};
