import { API_EDUCATION_LEVEL_URL } from '../../common/constants';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getPercentageFinancialEducation = async (): Promise<number> => {
  const URL: string = API_EDUCATION_LEVEL_URL.financialEducation;
  try {
    const percentage: number = await fetchContent(URL, {
      method: 'GET',
    });

    return percentage;
  } catch (error: any) {
    console.error('[getPercentageFinancialEducation]: ' + error);
    throw handleServiceError(error);
  }
};
