import { useQuery } from '@tanstack/react-query';
import { getPersonByIdUser, getPointsByPerson } from '../../../services/person';
import { useAppContext } from '..';

interface IPerson {
  isFetchingUserPoints?: boolean;
  isFetchingUser?: boolean;
}

export const usePerson = ({ isFetchingUserPoints, isFetchingUser }: IPerson) => {
  const { idUsuario, idPersona, idCliente } = useAppContext();

  const clientPointsQuery = useQuery({
    queryKey: ['person-client-points', { idUsuario }],
    queryFn: async () => await getPointsByPerson({ idPersona, idCliente }),
    enabled: Boolean(idUsuario && isFetchingUserPoints),
  });

  const personUserQuery = useQuery({
    queryKey: ['person-user', { idUsuario }],
    queryFn: async () => await getPersonByIdUser(idUsuario),
    enabled: Boolean(idUsuario && isFetchingUser),
  });

  return { idUsuario, idPersona, clientPointsQuery, personUserQuery };
};
