import { formatCommonTransactionDetail } from '..';
import {
  ITransactionCryptoExchangeDetailFormateadData,
  ITransactionDetail,
  ITransactionsDictionary,
  OrderOperation,
} from '../../common/types';

export const formatCryptoExchangeDetail = (
  transaction: ITransactionDetail,
  dictionary: ITransactionsDictionary
): ITransactionCryptoExchangeDetailFormateadData => {
  if (!transaction?.crypto) return null;
  
  const { date, points } = formatCommonTransactionDetail(transaction?.fecha, transaction?.crypto?.puntos);

  const title: string =
    transaction?.crypto?.tipoOrden === OrderOperation.Buy
      ? dictionary.detailModal.cryptoExchange.buy.title
      : dictionary.detailModal.cryptoExchange.sell.title;

  const amount: string = parseFloat(transaction?.crypto?.montoDestino).toFixed(8);

  const formateadTransaction: ITransactionCryptoExchangeDetailFormateadData = {
    title,
    date,
    points,
    amount,
  };

  return formateadTransaction;
};
