import { API_CITY_URL } from '../../common/constants';
import { City, CityByIdProvincia } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getCities = async ({ idProvincia }: CityByIdProvincia): Promise<City[]> => {
  const URL: string = API_CITY_URL.city.replace('{idProvincia}', String(idProvincia));

  try {
    const cities: City[] = await fetchContent(URL, {
      method: 'GET',
    });

    return cities;
  } catch (error: any) {
    console.error('[getCities]: ' + error);
    throw handleServiceError(error);
  }
};
