import { CSSProperties } from 'styled-components';
export const CRYPTO_LOGIN_STYLES = {
  mainContainer: {
    padding: '20px',
  } as CSSProperties,
  buttonsContainer: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'end',
    padding: '10px 20px 30px',
  } as CSSProperties,
  graphicBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '50px',
    padding: '25px',
    '@media screen and (max-width: 1350px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@media screen and (max-width: 700px)': {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  } as CSSProperties,
};
