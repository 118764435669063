import { Box, CircularProgress } from '@mui/material';
import { STYLE_MINI_LOADER } from './style';

const MiniLoader = (style) => {
  return (
    <Box sx={STYLE_MINI_LOADER.mainContainer}>
      <CircularProgress size={50} {...style} />
    </Box>
  );
};

export default MiniLoader;
