import { useEffect, useState } from "react";
import UserStats from "./components/UserStats/UserStats";
import { Box, Typography } from "@mui/material";
import ShortLine from "../../components/ShortLine/ShortLine";
import "./EducacionFinanciera.css";
import EducacionFinancieraVideosSection from "./components/EducacionFinancieraVideosSection/EducacionFinancieraVideosSection";
import { eduFinancieraService } from "../../services/HomeService";
import useLanguage from "../../common/hooks/useLanguage";
import ES from "../../common/language/educacion/es.json";
import EN from "../../common/language/educacion/en.json";
import parse from "html-react-parser";
import { GetNivelAlcanzado } from "../../services/EducacionFinancieraService";
import { NivelEducacionVideoDTO } from "../../common/interfaces/EducacionFinancieraInterface";

const EducacionFinanciera = () => {
  const [eduNivelPorc, setEduNivelPorc] = useState<number>(0);
  const [nivelAlcanzdo, setNivelAlcanzado] =
    useState<NivelEducacionVideoDTO | null>(null);
  const { dictionary } = useLanguage({ EN, ES });

  useEffect(() => {
    eduFinancieraService()
      .then((data) => setEduNivelPorc(data))
      .catch(() => {
        setEduNivelPorc(0);
      });
    GetNivelAlcanzado()
      .then((data) => setNivelAlcanzado(data))
      .catch(() => console.error("error en nivel alcanzado"));
  }, []);

  return (
    <section className="educacion-financiera-section">
      <UserStats />
      <section className="nivel-actual-card-section">
        <Box width={"100%"}>
          <Typography variant="h4" color="primary" align="left">
            {dictionary?.titulo}
            <ShortLine position="left" />
          </Typography>
        </Box>
        <div className="edu-fin-nivel-actual-card">
          <div className="disk-progress">
            <Typography variant="h6" color="primary" fontWeight={"bold"}>
              {eduNivelPorc}%
            </Typography>
          </div>
          <div className="edu-fin-nivel-actual-card-text">
            <Typography variant="h6" color="primary">
              {dictionary?.nivel.titulo}
            </Typography>
            <Typography variant="subtitle1" color="initial">
              {parse(
                dictionary?.nivel.nivelUsuario.replace(
                  "{nivel}",
                  nivelAlcanzdo?.nivel
                )
              )}
            </Typography>
            {nivelAlcanzdo && (
              <Typography paragraph={true} color="initial">
                {dictionary?.nivel.consejo.replace(
                  "{nivel}",
                  nivelAlcanzdo?.idNivelEducacion &&
                    nivelAlcanzdo?.idNivelEducacion <= 4
                    ? nivelAlcanzdo?.idNivelEducacion &&
                        nivelAlcanzdo?.idNivelEducacion
                    : 4
                )}
              </Typography>
            )}
          </div>
        </div>
      </section>
      <EducacionFinancieraVideosSection />
    </section>
  );
};

export default EducacionFinanciera;
