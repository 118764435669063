import { API_CLIENT_URL } from '../../common/constants';
import { ClientPointsPerAmountProps } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getPointsPerAmount = async ({
  idCliente,
  idUsuario,
  idTipoMovimiento,
  importe,
}: ClientPointsPerAmountProps): Promise<number> => {
  const URL: string = API_CLIENT_URL.pointsPerAmount
    .replace('{idCliente}', String(idCliente))
    .replace('{idUsuario}', String(idUsuario))
    .replace('{idTipoMovimiento}', String(idTipoMovimiento))
    .replace('{importe}', String(importe));

  try {
    const response: number = await fetchContent(URL, {
      method: 'GET',
    });

    return response;
  } catch (error: any) {
    console.error('[getPointsPerAmount]: ' + error);
    throw handleServiceError(error);
  }
};
