import React from 'react';
import { ICustomSimpleTableColumn, ISortDirections } from '.';

export interface ITransactionsPerson {
  idioma: string;
  cantidadRegistros: number;
}

export interface IConsolidatedPerson {
  cantidadTotalRegistros: number;
  cantidadPaginas: number;
  data: IConsolidatedPersonData[];
  cantidadRegistros: number;
  pagina: number;
  idTipoMovimiento?: number;
}

export interface ITransactionsSort {
  column: ITransactionColumns;
  direction: ISortDirections;
}

export enum ITransactionColumns {
  idMovimiento = 'idMovimiento',
  fecha = 'fecha',
  idTipoMovimiento = 'idTipoMovimiento',
  puntos = 'puntos',
}

export interface ITransactionFormatTableColumn {
  idMovimiento: string;
  fecha: string;
  tipoMovimientoDescripcion: string;
  puntos: string;
  comentario: string;
}

export interface ITransactionFormatTableRows {
  idMovimiento: number;
  fecha: string;
  tipoMovimientoDescripcion: string;
  puntos: string;
  comentario: React.ReactNode;
}

export enum ITransactionExcludeSortingColumns {
  comentario = 'comentario',
}

export interface ITransactionsDictionary {
  title: string;
  table: Table;
  detailModal: IDictionaryModalClass;
  typeOfTransactionMenu: TypeOfTransactionMenu;
  pagination: Pagination;
  form: Form;
}

interface TypeOfTransactionMenu {
  title: string;
  idTypeOfTransaction: { [key: string]: string };
}
interface IDictionaryModalClass {
  commonItems: MyTransactionItems;
  pointsExchange: PointsExchange;
  recognition: RecognitionDetailModal;
  assignment: AssignmentDetailModal;
  cryptoExchange: CryptoDetailModal;
}

export interface MyTransactionItems {
  id: string;
  date: string;
  points: string;
}

interface RecognitionDetailModal {
  title: string;
  recognition: string;
  recognizedBy: string;
  comment: string;
}

interface CryptoDetailModal {
  buy: Operation;
  sell: Operation;
  pointsRedeemed: string;
  currency: string;
  amount: string;
}

interface Operation {
  title: string;
}

export type RecognitionDetailProps = Omit<RecognitionDetailModal, 'title'> & {
  recognitionTitle: string;
  recognizedByTitle: string;
  recognitionCommentTitle: string;
  image: string;
};

export interface AssignmentDetailProps {
  commentTitle: string;
  comment: string;
}

type AssignmentDetailModal = Pick<AssignmentDetailProps, 'commentTitle'> & {
  assignmentPointsTitle: string;
  subtractPointsTitle: string;
};

interface PointsExchange {
  title: string;
  status: string;
  statusConfig: { [key: string]: string };
  productsTableTitle: string;
  productsTable: PointsExchangeHeader;
}

export interface PointsExchangeHeader {
  id: string;
  product: string;
  price: string;
  points: string;
  quantity: string;
}

interface Form {
  search: Search;
}

interface Search {
  placeHolder: string;
}

interface Pagination {
  first: string;
  last: string;
  next: string;
  previous: string;
}

interface Table {
  column: ITransactionFormatTableColumn;
  row: ITransactionFormatTableRows;
  actions: Actions;
  noResults: string;
  loading: string;
}

interface Actions {
  status: ITransactionsStatus;
  detail: Detail;
}

interface ITransactionsStatus {
  approved: string;
  pending: string;
  delivered: string;
}

export enum ITransactionsStatusValues {
  pending = 'Pendiente',
  inPreparation = 'En preparación',
  delivered = 'Entregado',
}

interface Detail {
  title: string;
  text: string;
  buttons: Buttons;
}

interface Buttons {
  cancel: string;
}

export interface IConsolidatedPersonData {
  idMovimiento: number;
  puntos: number;
  fecha: Date;
  idTipoMovimiento: number;
  tipoMovimientoDescripcion: string;
  idEstado: IDsTypeOfStatus | null;
  estado: TypeOfStatus | null;
  tieneProductos: boolean;
  idsProductos: number[];
  comentario: null | string;
  idPersona: number;
  productosDescripcion: null | string;
  fechaCorta: string;
  horaCorta: string;
}

export interface IConsolidatedPersonProps {
  idioma: string;
  ordenamiento: string;
  pagina: number;
  cantidadRegistrosPorPagina: number;
  movimientoDescripcion?: string;
  idTipoMovimiento?: number | string;
}

export interface IConsolidatedPersonActions {
  idMovimiento: number;
  detalle: React.ReactNode;
}

export interface Transaction {
  idMovimiento: number;
  id: number;
  idCliente: number;
  fecha: Date;
  idPersona: number;
  idTipoMovimiento: number;
  idMovimientoRelacionado: null;
  idPuntoEntrega: number | null;
  idFormaEntrega: number | null;
  idUsuarioAsignador: number | null;
  comentario: string;
  idRegla: number | null;
  idEstado: IDsTypeOfStatus | null;
  idPedido: number | null;
  idClienteNavigation: null;
  idPersonaNavigation: null;
  idTipoMovimientoNavigation: null;
  movimientoDetalles: ITransactionDetail[];
  movimientoDomicilioEntregas: unknown[];
  idUsuarioAsignadorNavigation: null;
  idEstadoNavigation: null;
  ctcorders: null;
  tangoOrders: null;
}

export interface ITransactionDetail {
  idMovimiento: number;
  id: number;
  idTipoMovimiento: number;
  tipoMovimientoDescripcion: string;
  fecha: Date;
  idPersona: number;
  idUsuario: number;
  comentario: string;
  //* El detalle solo puede ser 1 de estos 4
  //* Los 3 restantes serán null
  canjePuntos: ITransactionDetailPointsExchange | null;
  reconocimiento: ITransactionRecognition | null;
  crypto: ITransactionCryptoExchange | null;
  asignacionDePuntos: ITransactionPointsAssignment | null;
}

interface ITransactionDetailPointsExchange {
  totalPuntos: number;
  cantidadItems: number;
  productos: IPointsExchangeProduct[];
}

interface IPointsExchangeProduct {
  id: number;
  producto: string;
  cantidad: number;
  precio: number;
  puntos: number;
  totalPuntos: number;
  esGiftCard: boolean;
  estado: string;
  idEstado: number;
}

interface ITransactionRecognition {
  idReconocimiento: number;
  id: number;
  idCliente: number;
  fecha: Date;
  idUsuarioAsignador: number;
  idUsuarioReconocido: number;
  idUsuarioSupervisor: number;
  idTipoReconocimiento: number;
  puntos: number;
  estado: string;
  fechaAprobacion: Date;
  idMovimiento: number;
  descripcion: string;
  nombreUsuarioAsignador: string;
  nombreUsuarioReconocido: null;
  nombreUsuarioSupervisor: null;
  descripcionTipoReconocimiento: null;
  tituloTipoReconocimiento: string;
  fechaString: string;
  horaString: string;
  fechaAprobacionString: null;
  imagen: string;
  cantidadTotalRegistros: number;
}

export interface ITransactionCryptoExchange {
  idLiriumOrder: number;
  referenceId: string;
  id: string;
  idUsuario: number;
  fechaAlta: Date;
  tipoOrden: string;
  montoOrigen: string;
  monedaOrigen: string;
  montoDestino: string;
  monedaDestino: string;
  estado: string;
  externalcustomerId: null;
  externalId: null;
  fechaActualizacion: Date;
  idMovimiento: number;
  montoOrigenDecimal: number;
  montoDestinoDecimal: number;
  comentario: string;
  puntos: number;
}

export interface ITransactionPointsAssignment {
  puntos: number;
  nombreUsuarioAsignadorReconocimiento: string;
  tipoMovimiento: string;
}

export interface PostTransaction {
  idCliente: number;
  fecha: Date | string;
  idPersona: number;
  idTipoMovimiento: number;
  comentario: string;
  idPuntoEntrega?: number;
  idFormaEntrega?: number;
  idUsuarioAsignador?: number;
  idMovimientoRelacionado?: number;
  idRegla?: number;
  idPedido?: number;
  idEstado: IDsTypeOfStatus | null;
  id?: number;
  movimientoDetalles: TransactionDetailProps[];
}

interface TransactionDetailProps {
  puntos?: number;
  importe?: number;
  idClienteConversion?: number;
  idProducto?: number;
  idEstado: IDsTypeOfStatus | null;
  cantidad?: number;
}

export interface NewTransactionResponse {
  idMovimiento: number;
  id: number;
  idCliente: number;
  fecha: Date;
  idPersona: number;
  idTipoMovimiento: number;
  idMovimientoRelacionado: null;
  idPuntoEntrega: number | null;
  idFormaEntrega: null;
  idUsuarioAsignador: null;
  comentario: string;
  idRegla: null;
  idEstado: IDsTypeOfStatus | null;
  idPedido: null;
  idClienteNavigation: IDClienteNavigation;
  idPersonaNavigation: null;
  idTipoMovimientoNavigation: ClienteConversionIDTipoMovimientoNavigation;
  movimientoDetalles: ITransactionDetail[];
  movimientoDomicilioEntregas: unknown[];
  idUsuarioAsignadorNavigation: null;
  idEstadoNavigation: null;
  ctcorders: null;
  tangoOrders: null;
}

interface BudgetTransaction {
  idMovimientoPresupuesto: number;
  id: number;
  idCliente: number;
  idMovimientoDetalle: number;
  puntos: number;
  idTipoMovimiento: number;
  idUsuario: number;
  idSupervisor: null;
  fechaAlta: Date;
  comentarios: string;
  idTipoMovimientoNavigation: BudgetTransactionIDTipoMovimientoNavigation;
  idMovimientoDetalleNavigation?: ITransactionDetail;
  idClienteNavigation?: IDClienteNavigation;
}

interface ClienteConversionIDTipoMovimientoNavigation {
  idTipoMovimiento: number;
  id: number;
  descripcion: string;
  accionSobreStock: string;
  validaPresupuesto: boolean;
  clienteConversions: Ion[];
  movimientos: unknown[];
}

interface Ion {
  idClienteConversion: number;
  id: number;
  idCliente: number;
  importe: number;
  puntos: number;
  fechaVigencia: Date;
  idTipoMovimiento: number;
  diasVencimiento: number | null;
  cotizacion: number;
  spread: number;
  idNivelUsuario: number;
  idCategoriaUsuario: number;
  idTipoMovimientoNavigation?: ClienteConversionIDTipoMovimientoNavigation | null;
  movimientoDetalles: ITransactionDetail[];
  idCategoriaUsuarioNavigation: null;
  idNivelUsuarioNavigation: null;
  idClienteNavigation?: IDClienteNavigation;
}

interface IDClienteNavigation {
  idCliente: number;
  id: number;
  razonSocial: string;
  cuit: string;
  email: string;
  domicilio: string;
  localidad: string;
  provincia: string;
  pais: string;
  cp: string;
  sfi: number;
  fechaAlta: Date;
  clienteDvh: string;
  logo: string;
  idFormaEnvioEmail: number;
  dominio: null;
  validaEnvioMailPorDominio: boolean;
  url: string;
  idProvincia: string;
  idLocalidad: string;
  idPais: string;
  idMoneda: string;
  idTipoGestionPresupuesto: number;
  asignaNivelUsuarioDinamico: boolean;
  cantidadReintentosBloqueoUsuario: number;
  emailNotificacionCliente: string;
  emailNotificacionAdmin: string;
  idModoReconocimiento: number;
  umbralPuntosPresupuesto: number;
  imagenBase64: string;
  minutosExpiracionSession: number;
  idModoReconocimientoNavigation: null;
  usuarioClientes: UsuarioCliente[];
  clienteConversion: Ion[];
  clienteProductos: unknown[];
  movimientos: unknown[];
  tipoProductos: unknown[];
  categoriaUsuarios: unknown[];
  nivelUsuarios: unknown[];
  clienteReglas: unknown[];
  tipoReconocimientos: unknown[];
  reconocimientos: unknown[];
  sectores: unknown[];
  videos: unknown[];
  clienteLiriumParametros: unknown[];
  colorEstilos: unknown[];
  clienteAccesos: unknown[];
  clienteCatalogoPais: unknown[];
  pedidos: unknown[];
  idMonedaNavigation: null;
  movimientoPresupuestos: BudgetTransaction[];
  clienteSpreads: unknown[];
  idTipoGestionPresupuestoNavigation: null;
}

interface BudgetTransactionIDTipoMovimientoNavigation {
  idTipoMovimiento: number;
  id: number;
  descripcion: string;
  accion: string;
  esIngreso: boolean;
  movimientoPresupuestos: unknown[];
}

interface UsuarioCliente {
  idUsuarioCliente: number;
  id: number;
  idUsuario: number;
  idCliente: number;
  idCategoriaUsuario: number;
  idNivelUsuario: number;
  fechaIngresoEmpresa: null;
  idUsuarioNavigation: null;
  idCategoriaUsuarioNavigation: null;
  idNiverlUsuarioNavigation: null;
}

export enum TypeOFTransaction {
  canjePuntos = 'Canje puntos',
  asignacionPuntos = 'Asignación puntos',
  transferenciaPuntos = 'Transferencia de puntos',
  puntosTransferidos = 'Puntos transferidos',
  vencimientoDePuntos = 'Vencimiento de puntos',
  regaloGiftCard = 'Regalo de Gift Card',
  canjePuntosPorCrypto = 'Canje puntos por Crypto',
  compraDePuntosConCrypto = 'Compra de puntos con crypto',
  ajusteResta = 'Ajuste por resta',
}

export enum IDsTypeOFTransaction {
  canjePuntos = 2,
  asignacionPuntos = 3,
  transferenciaPuntos = 7,
  puntosTransferidos = 8,
  vencimientoDePuntos = 9,
  regaloGiftCard = 10,
  canjePuntosPorCrypto = 11,
  compraDePuntosConCrypto = 12,
  ajusteResta = 14,
}

export interface IFormatProductsRowsData {
  id: number;
  precio: string;
  puntos: string;
  cantidad: string;
}

export interface ITransactionDetailFormateadData {
  date: string;
  points: string;
}

export interface ITransactionCryptoExchangeDetailFormateadData extends ITransactionDetailFormateadData {
  title: string;
  amount: string;
}

export interface ITransactionPointsAssignmentDetailFormateadData extends ITransactionDetailFormateadData {
  title: string;
}

export interface IFormatPointsExchangeDetailData extends ITransactionDetailFormateadData {
  productsTableHeaders: ICustomSimpleTableColumn[];
  products: IFormatProductsRowsData[];
}

enum TypeOfStatus {
  asignado = 'Asignado',
  pendiente = 'Pendiente',
  transferido = 'Transferido',
  vencido = 'Vencido',
  enPreparacion = 'En preparación',
  enviado = 'Enviado',
  entregado = 'Entregado',
}

export enum IDsTypeOfStatus {
  asignado = 1,
  pendiente = 2,
  transferido = 3,
  vencido = 4,
  enPreparacion = 5,
  enviado = 6,
  entregado = 7,
}
