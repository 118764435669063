import { API_LIRIUM_USER_URL, cliente, headerOptions } from '../../common/constants';
import { fetchContent, formateFullYear } from '../../utils';
import { handleServiceError } from '../GiftCardService';
import {
  ExternalAPILiriumCustomer,
  ExternalAPILiriumCustomerResponse,
  InternalAPILiriumUserResponse,
  PostCustomerProps,
} from '../../common/types';
import { postUser } from './postUser';
import { v4 as uuidv4 } from 'uuid';

export const postCustomer = async ({
  idUsuario,
  customerBody,
}: PostCustomerProps): Promise<InternalAPILiriumUserResponse> => {
  const URL: string = API_LIRIUM_USER_URL.customer;

  const {
    reference_id,
    first_name,
    middle_name,
    last_name,
    date_of_birth,
    national_id_country,
    national_id_type,
    national_id,
    citizenship,
    address_line1,
    city,
    state,
    country,
    zip_code,
    email,
    cellphone,
  } = customerBody;

  const body: ExternalAPILiriumCustomer = {
    reference_id,
    profile: {
      first_name,
      middle_name,
      last_name,
      date_of_birth,
      national_id_country,
      national_id_type,
      national_id,
      citizenship,
      address_line1,
      city,
      state,
      country,
      zip_code,
    },
    contact: {
      email,
      cellphone,
    },
  };

  try {
    const customer: ExternalAPILiriumCustomerResponse = await fetchContent(URL, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headerOptions,
    });

    const newUser: InternalAPILiriumUserResponse = await postUser({
      idUsuario,
      label: customer?.profile?.label || 'test',
      idCliente: cliente,
      //! _Parche: customer no devuelve data
      id: customer?.id || uuidv4(),
      estado: customer?.state || 'active',
      fechaActualizacion: customer?.created_at || formateFullYear(new Date()),
    });

    return newUser;
  } catch (error: any) {
    console.error('[postCustomer]: ', error);
    throw handleServiceError(error);
  }
};
