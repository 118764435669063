export interface IFinancialEducation {
  idNivelEducacionVideo: number;
  id: number;
  idNivelEducacion: number;
  idVideo: number;
  descripcionVideo: DescripcionVideo;
  imagenVideo: ImagenVideo | null;
  titulo: Titulo;
  ordenVisualizacion: number;
  porcentajeFinalizado: number;
  chequeado: boolean;
  nombreNivel: string;
  url: string;
  idNivelEducacionVideoUsuario: number;
  tiempoTranscurrido: number;
  estado: Status;
  enabled: boolean;
  nivel: number;
  cabecera: boolean;
}

enum DescripcionVideo {
  AprenderAInvertir = 'Aprender a invertir',
  ExchangeWalletStablecoins = 'Exchange, Wallet, stablecoins',
  OperarDentroDeKiwin = 'Operar dentro de Kiwin',
  SistemaCriptomonedas = 'Sistema criptomonedas',
}

export enum Status {
  Empty = '',
  Finalizado = 'FINALIZADO',
}

enum ImagenVideo {
  Empty = '-',
}

enum Titulo {
  VideoDelNivel1 = 'Video del nivel 1',
  VideoDelNivel2 = 'Video del nivel 2',
  VideoDelNivel3 = 'Video del nivel 3',
  VideoDelNivel4 = 'Video del nivel 4',
}
