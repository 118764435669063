import { useEffect, ReactNode } from 'react';
import { useQuery } from '@tanstack/react-query';
import ES from '../../../common/language/recognitions/made/es.json';
import EN from '../../../common/language/recognitions/made/en.json';
import useLanguage from '../../../common/hooks/useLanguage';
import { getMadeRecognitions } from '../../../services/recognitions';
import {
  IMadeRecognitionsActions,
  IMadeRecognitionsRows,
  IFormatTableColumns,
  IMadeRecognitionsDictionary,
  RecognitionExcludeSortingColumns,
  STATUS_APPROVED_RECOGNITION,
} from '../../../common/types/recognitions';
import { CANTIDAD_REGISTROS_POR_PAGINA, STALE_TIME_RECOGNITION } from '../../../common/constants';
import { formatTableColumns, madeRecognitionsFormatTableRows } from '../../../utils/recognitions';
import { Chip } from '@mui/material';
import { useRecognitionsTable } from '../../../common/hooks/useRecognitionsTable';
import { ViewDetail } from '../../../components/ViewDetail/ViewDetail';

export const useMadeRecognitions = () => {
  const {
    idUsuario,
    search,
    language,
    page,
    totalPages,
    sort,
    setPage,
    setSearch,
    setTotalPages,
    changeSort,
    handleChangePage,
  } = useRecognitionsTable();
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: IMadeRecognitionsDictionary };

  const madeRecognitionsQuery = useQuery({
    queryKey: ['made-recognitions', { language: language.language, idUsuario, page, sort, search }],
    queryFn: async () =>
      await getMadeRecognitions({
        idioma: language.language,
        pagina: page,
        ordenamiento: `${sort.column} ${sort.direction}`,
        cantidadRegistrosPorPagina: CANTIDAD_REGISTROS_POR_PAGINA,
        ...(search && { usuario: search }),
      }),
    staleTime: STALE_TIME_RECOGNITION,
    enabled: Boolean(idUsuario),
  });

  const columns: IFormatTableColumns[] = formatTableColumns(dictionary.table.column, [
    RecognitionExcludeSortingColumns.descripcion,
  ]);

  const status: IMadeRecognitionsActions[] = madeRecognitionsQuery.data?.data.map((el) => {
    let estadoBoton: ReactNode;
    let detalle: ReactNode = (
      <Chip
        onClick={() =>
          ViewDetail({
            title: el.nombreUsuarioAsignador,
            text: el.descripcion,
            cancelButtonText: dictionary.table.actions.detail.buttons.cancel,
            image: el.imagen,
          })
        }
        color="primary"
        label={dictionary.table.actions.detail.title}
      />
    );

    if (el.estado === STATUS_APPROVED_RECOGNITION.approved) {
      estadoBoton = <Chip color="success" label={dictionary.table.actions.status.approved} />;
    } else {
      estadoBoton = <Chip color="warning" label={dictionary.table.actions.status.pending} />;
    }

    return {
      idReconocimiento: el.idReconocimiento,
      estadoBoton,
      detalle,
    };
  });

  const madeRecognitions: IMadeRecognitionsRows[] = madeRecognitionsFormatTableRows(
    madeRecognitionsQuery.data?.data,
    status
  );

  useEffect(() => {
    if (madeRecognitionsQuery.data?.cantidadPaginas) {
      setTotalPages(madeRecognitionsQuery.data.cantidadPaginas);
      return;
    }
  }, [madeRecognitionsQuery.data?.cantidadPaginas, setTotalPages]);

  return {
    madeRecognitions,
    isLoading: madeRecognitionsQuery.isLoading,
    dictionary,
    columns,
    page,
    totalPages,
    sort,
    language: language.language,
    changeSort,
    setPage,
    handleChangePage,
    setSearch,
  };
};
