import { useState } from 'react';
import { bodyProductsInterface } from '../../common/interfaces/GiftCardInterfaces';
import { bodyFilterInit, cliente } from '../../common/constants/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../common/redux/store';
import { useCatalogoFilters, useCatalogoProduct } from '.';
import { useCountry } from '../country';

const useCatalogo = () => {
  const [limpiar, setLimpiar] = useState<boolean>(false);
  const [currentProducts, setCurrentProducts] = useState<bodyProductsInterface>(bodyFilterInit);
  const filters = useSelector((state: RootState) => state.filtroCatalogo);

  const { countries } = useCountry();
  const { catalogoFilters } = useCatalogoFilters({ filters });
  const {
    products,
    isLoading: isProductsLoading,
    preFetchCatalogoProducts
  } = useCatalogoProduct({
    filteredProducts: { body: currentProducts, idCliente: cliente, paises: countries },
  });

  return {
    products,
    countries,
    limpiar,
    loading: isProductsLoading,
    currentProducts,
    catalogoFilters,
    setLimpiar,
    setCurrentProducts,
    preFetchCatalogoProducts
  };
};

export default useCatalogo;
