import { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import {
  CryptoPointsExchangerDictionary,
  CryptoExchangeCurrencyTypes,
  LiriumOrderExternalAPI,
  LiriumOrderTextFields,
  OrderOperation,
  SettlementCurrency,
} from '../../common/types';
import useLanguage from '../../common/hooks/useLanguage';
import { useAppContext, useClient, useLiriumOrder, useLiriumOrderMutation, useLiriumUser } from '../../common/hooks';
import { modalError } from '../../utils';
import ES from '../../common/language/crypto_/purchase_crypto/es.json';
import EN from '../../common/language/crypto_/purchase_crypto/en.json';

export const useCryptoPointsExchanger = () => {
  const { idUsuario } = useAppContext();
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: CryptoPointsExchangerDictionary };
  const [currentCurrency, setCurrentCurrency] = useState<CryptoExchangeCurrencyTypes>(null);
  const [isRedeeming, setIsRedeeming] = useState<boolean>(false);
  const [order, setOrder] = useState<LiriumOrderExternalAPI>(null);
  const [textFields, setTextFields] = useState<LiriumOrderTextFields>({
    points: 0,
    crypto: 0,
  });
  const isExchangeDisabled: boolean = Boolean(!currentCurrency || textFields.points <= 0);

  const {
    cryptoUserQuery: { data: cryptoUser },
  } = useLiriumUser({
    isFetchingUser: true,
  });

  const {
    clientCurrencyQuery: { data: currency },
  } = useClient({
    clientCurrency: true,
  });

  const {
    cryptoExchangeRatesQuery: { data: exchangeRates },
  } = useLiriumOrder({ currency: currency?.alfa3 });

  const { postLiriumOrder } = useLiriumOrderMutation();

  const handleCurrencyOnSelect = (event: SelectChangeEvent): void => {
    event.preventDefault();
    const currencySelected: string = event.target.value;
    const findCurrency: CryptoExchangeCurrencyTypes = Object.values(CryptoExchangeCurrencyTypes).find(
      (value) => value === currencySelected
    );

    setCurrentCurrency(findCurrency);
  };

  const handlePointsOnChange = (event: any, points: number): void => {
    event.preventDefault();

    if (event.target.value === '') {
      setTextFields({
        points: 0,
        crypto: 0,
      });
      return;
    }

    const inputPoints: string = (event.target.value =
      Number(event.target.value) > points ? points : event.target.value);

    setTextFields({
      ...textFields,
      points: Number(inputPoints),
    });
  };

  const handleSubmit = async (operation: OrderOperation, points: number): Promise<void> => {
    const isTextFieldsPointsEmpty: boolean = !Boolean(textFields.points);

    if (isTextFieldsPointsEmpty) {
      modalError(dictionary.emptyField);
      return;
    }

    const foundCurrency: SettlementCurrency = Object.values(SettlementCurrency).find(
      (value) => value === currency?.alfa3
    );

    const operationType =
      operation === OrderOperation.Buy
        ? {
            buy: {
              settlement: {
                amount: String(points),
                currency: foundCurrency,
              },
            },
          }
        : {
            sell: {
              settlement: {
                amount: String(points),
                currency: foundCurrency,
              },
            },
          };

    const newOrder: LiriumOrderExternalAPI = await postLiriumOrder.mutateAsync({
      newOrder: {
        order: {
          idCustomer: cryptoUser?.id,
          idUsuario,
          operation,
          asset: { currency: currentCurrency },
          buyOrSell: operationType,
        },
        successMessage: dictionary.success,
        errorMessage: dictionary.error,
      },
    });
    setOrder(newOrder);
    return;
  };

  return {
    currentCurrency,
    textFields,
    order,
    dictionary,
    isRedeeming,
    isExchangeDisabled,
    exchangeRates,
    setTextFields,
    setIsRedeeming,
    handlePointsOnChange,
    handleCurrencyOnSelect,
    handleSubmit,
  };
};
