import { API_CLIENT_CATALOGO_COUNTRY_URL } from '../../common/constants';
import { IClientCatalogoCountry } from '../../common/types';
import { fetchNoJsonRes } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getClientCatalogoCountry = async (idCliente: number): Promise<IClientCatalogoCountry[]> => {
  const URL: string = API_CLIENT_CATALOGO_COUNTRY_URL.countries.replace('{idCliente}', String(idCliente));

  try {
    const response = await fetchNoJsonRes(URL, {
      method: 'GET',
    });
    const JSONresponse: IClientCatalogoCountry[] = await response.json();

    return JSONresponse;
  } catch (error: any) {
    console.error('[getClientCatalogoCountry]: ' + error);
    throw handleServiceError(error);
  }
};
