import { Button, CircularProgress } from '@mui/material';

type IProps = {
  children: React.ReactNode;
  isLoading: boolean;
  onClick: () => void;
  setIsLoading: (isLoading: boolean) => void;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  disabled?: boolean;
};

const ButtonSubmit: React.FC<IProps> = ({ children, color, isLoading, disabled, onClick, setIsLoading }) => {
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await onClick();
    } catch {
      console.error('Error en submit');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Button
      disabled={isLoading || disabled}
      variant="contained"
      color={disabled ? 'warning' : color ? color : 'primary'}
      onClick={handleSubmit}
      sx={{ minWidth: 150 }}
    >
      {isLoading ? <CircularProgress size={20} /> : children}
    </Button>
  );
};

export default ButtonSubmit;
