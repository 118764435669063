import { useContext, useEffect } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { useAppContext, useClient, usePermissions } from '../../common/hooks';
import { ClientFooterData, ClientTheme, TypesOfPermissionsEnvironment } from '../../common/types';

export const useApp = () => {
  const themeContext = useContext(ThemeContext);
  const { user, permissions, setPermissions } = useAppContext();

  const {
    themeAndFooterQuery: { data: staticData, isLoading: isLoadingStaticData },
  } = useClient({});

  const {
    permissionsEnvironmentQuery: { data: permissionsEnvironment, isLoading: isLoadingPermissions },
  } = usePermissions({ environment: TypesOfPermissionsEnvironment.frontend });

  useEffect(() => {
    themeContext?.setTheme(staticData?.theme as never[]);
    setPermissions(permissionsEnvironment);
  }, [permissionsEnvironment, staticData?.theme, themeContext, setPermissions]);

  return {
    theme: staticData?.theme as ClientTheme[],
    footer: staticData?.footer as ClientFooterData,
    isLoading: isLoadingStaticData || isLoadingPermissions,
    themeContext,
    user,
    permissions,
  };
};
