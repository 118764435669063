import { Box, Button, Grid, Typography } from '@mui/material';
import ShortLine from '../../../../components/ShortLine/ShortLine';
import { RUTAS } from '../../../../common/constants/rutas';
import { AuthUser } from '../../../../context';
import { CRYPTO_PRE_LOGIN_STYLES } from './styles';
import { LiriumUser } from '../../../../common/types';
import cryptoImg from '../../../../common/assets/images/Crypto/crypto-img-log.jpeg';
import parse from 'html-react-parser';

interface CryptoPreLogInProps {
  title: string;
  subtitle: string;
  next: string;
  user: AuthUser;
  cryptoUser: LiriumUser;
  onNavigate: (route: string, isHasCryptoAccount: boolean) => void;
}

export const CryptoPreLogIn: React.FC<CryptoPreLogInProps> = ({
  title,
  subtitle,
  next,
  user,
  cryptoUser,
  onNavigate,
}) => {
  return (
    <Grid container columns={12} spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <Box m={2}>
          <img src={cryptoImg} alt="crypto-img" style={CRYPTO_PRE_LOGIN_STYLES.image} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Box m={2} maxWidth={'1200px'}>
          <Typography variant="h4" color="primary">
            {title}
            <ShortLine position="left" />
          </Typography>
          <Typography variant="subtitle1" color="primary">
            {parse(subtitle)}
          </Typography>
          {user && (
            <Box textAlign="center" mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onNavigate(RUTAS.criptoAlta, Boolean(cryptoUser))}
              >
                {next}
              </Button>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
