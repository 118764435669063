import { Typography } from '@mui/material';
import { formatNumber } from '../../../utils/functions';

interface ICardPoints {
  points: number;
  subtitle: string;
}

export const CardPoints: React.FC<ICardPoints> = ({ points, subtitle }) => {
  return (
    <>
      <Typography variant="h4" color="white">
        {formatNumber(points)}
      </Typography>
      <Typography variant="h6" color="white">
        {subtitle}
      </Typography>
    </>
  );
};
