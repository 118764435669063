import { useQuery } from '@tanstack/react-query';
import { getCities } from '../../../services/city';
import { CityByIdProvincia } from '../../types';
import { useAppContext } from '../useAppContext';

interface CityProps {
  citiesByIdProvincia?: CityByIdProvincia;
}

export const useCity = ({ citiesByIdProvincia }: CityProps) => {
  const { idUsuario } = useAppContext();

  const citiesQuery = useQuery({
    queryKey: ['cities', { idUsuario, idProvincia: citiesByIdProvincia?.idProvincia }],
    queryFn: async () => await getCities(citiesByIdProvincia),
    enabled: Boolean(idUsuario && citiesByIdProvincia?.idProvincia),
  });

  return { citiesQuery };
};
