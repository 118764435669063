import { PopCategories, SectionFinancialLiteracy, SectionReconocimiento } from "..";

export const PreLogin = () => {
  return (
    <>
      <PopCategories />
      <SectionFinancialLiteracy />
      <SectionReconocimiento />
    </>
  );
};
