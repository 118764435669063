import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import ES from '../../../common/language/recognitions/my_team/es.json';
import EN from '../../../common/language/recognitions/my_team/en.json';
import useLanguage from '../../../common/hooks/useLanguage';
import {
  IMyTeamDictionary,
  IMyTeamFormatTableRow,
  IMyTeamSort,
  ISortDirections,
  IFormatTableColumns,
  MyTeamExcludeSortingColumns,
  MyTeamSortColumns,
} from '../../../common/types/recognitions';
import { getMyTeam } from '../../../services/recognitions';
import { CANTIDAD_REGISTROS_POR_PAGINA, STALE_TIME_RECOGNITION } from '../../../common/constants';
import { myTeamFormatTableRows, formatTableColumns } from '../../../utils/recognitions';
import { useRecognitionsTable } from '../../../common/hooks/useRecognitionsTable';
import { formatNumber } from '../../../utils/functions';
import { useApprovedRecognitions } from '../approved/useApprovedRecognitions';

export const useMyTeam = () => {
  const [sort, setSort] = useState<IMyTeamSort>({
    column: MyTeamSortColumns.nombre,
    direction: ISortDirections.asc,
  });
  const { idUsuario, search, language, page, totalPages, setPage, setSearch, setTotalPages, handleChangePage } =
    useRecognitionsTable();
  const { budgetSupervisorQuery } = useApprovedRecognitions();
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: IMyTeamDictionary };

  const changeSort = (column: string): void => {
    const getColumn = MyTeamSortColumns[column];
    const getDirection = sort.direction === ISortDirections.asc ? ISortDirections.desc : ISortDirections.asc;

    setSort({
      column: getColumn,
      direction: getDirection,
    });
  };

  const myTeamQuery = useQuery({
    queryKey: ['my-team', { idUsuario, page, sort, search }],
    queryFn: async () =>
      await getMyTeam({
        pagina: page,
        ordenamiento: `${sort.column} ${sort.direction}`,
        cantidadRegistrosPorPagina: CANTIDAD_REGISTROS_POR_PAGINA,
        ...(search && { usuario: search }),
      }),
    staleTime: STALE_TIME_RECOGNITION,
    enabled: Boolean(idUsuario),
  });

  const columns: IFormatTableColumns[] = formatTableColumns(dictionary.table.column, [
    MyTeamExcludeSortingColumns.email,
  ]);

  const myTeam: IMyTeamFormatTableRow[] = myTeamFormatTableRows(myTeamQuery.data?.data);

  const budgetSupervisor: string | boolean =
    idUsuario === budgetSupervisorQuery.data?.idSupervisor && formatNumber(budgetSupervisorQuery.data?.presupuesto);
  //TODO ver como crear una función reutilizable para useMadeRecognitions

  useEffect(() => {
    if (myTeamQuery.data?.cantidadPaginas) {
      setTotalPages(myTeamQuery.data.cantidadPaginas);
      return;
    }
  }, [myTeamQuery.data?.cantidadPaginas, setTotalPages]);

  return {
    myTeam,
    budgetSupervisor,
    isLoading: myTeamQuery.isLoading,
    dictionary,
    columns,
    page,
    totalPages,
    sort,
    language: language.language,
    changeSort,
    setPage,
    handleChangePage,
    setSearch,
  };
};
