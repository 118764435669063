import { API_LIRIUM_ORDER_URL, headerOptions } from '../../common/constants';
import { LiriumOrderExternalAPI, LiriumOrderInternalAPI, LiriumOrderInternalAPIResponse } from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

interface Props {
  idUsuario: number;
  reference_id: string;
  liriumOrderExternalAPI: LiriumOrderExternalAPI;
}

export const postLiriumOrder = async ({
  idUsuario,
  reference_id, //_ Creado por nosotros y no por la Api de Lirium
  liriumOrderExternalAPI: {
    id,
    asset: { currency: currencyDestination, amount: amountDestination },
    state,
    operation,
    sell,
    buy,
  },
}: Props): Promise<LiriumOrderInternalAPIResponse> => {
  const URL: string = API_LIRIUM_ORDER_URL.order;

  const monedaOrigen: string = buy ? buy.settlement.currency : sell.settlement.currency;
  const montoOrigen: string = buy ? buy.settlement.amount : sell.settlement.amount;

  const liriumOrderBody: LiriumOrderInternalAPI = {
    referenceId: reference_id,
    id,
    idUsuario,
    fechaAlta: new Date(Date.now()).toISOString(),
    tipoOrden: operation,
    monedaOrigen,
    montoOrigen,
    monedaDestino: currencyDestination,
    montoDestino: amountDestination,
    montoOrigenDecimal: parseFloat(montoOrigen),
    montoDestinoDecimal: parseFloat(amountDestination),
    estado: state,
  };

  try {
    const response: LiriumOrderInternalAPIResponse = await fetchContent(URL, {
      method: 'POST',
      headers: headerOptions,
      body: JSON.stringify(liriumOrderBody),
    });

    return response;
  } catch (error: any) {
    console.error('[postLiriumOrder]: ' + error);
    throw handleServiceError(error);
  }
};
