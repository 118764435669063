import { Grid, Typography } from '@mui/material';
import ShortLine from '../../../../components/ShortLine/ShortLine';
import {
  ButtonSubmitProps,
  ExchangeInputsProps,
  LiriumOrderExternalAPI,
  OrderExchangeFormProps,
  SelectCurrencyProps,
  SwitchCryptoPointsExchangerProps,
} from '../../../../common/types';
import {
  ExchangeFormContainer,
  ExchangeInputs,
  OrderExchangeForm,
  SelectCurrency,
  SwitchCryptoPointsExchanger,
} from '../..';
import ButtonSubmit from '../../../../components/ButtonSubmit/ButtonSubmit';

interface CryptoPointsExchangerProps {
  mainTitle: string;
  order: LiriumOrderExternalAPI;
  selectCurrency: SelectCurrencyProps;
  exchangeInputs: ExchangeInputsProps;
  buttonSubmit: ButtonSubmitProps;
  switchCryptoPointsExchanger: SwitchCryptoPointsExchangerProps;
  orderExchangeForm: OrderExchangeFormProps;
}

export const CryptoPointsExchanger: React.FC<CryptoPointsExchangerProps> = ({
  mainTitle,
  order,
  selectCurrency: { selectCurrencyTitle, currentCurrency, selectCurrency, exchangeRates, handleCurrencyOnSelect },
  exchangeInputs: { title, points, crypto, pointsText, exchangeRateText, limit, handleOnChange },
  buttonSubmit: { isExchangeDisabled, isRedeeming, buttonText, setIsRedeeming, handleSubmit },
  switchCryptoPointsExchanger: { switchTitle, linkText, route },
  orderExchangeForm: { textFields },
}) => {
  return (
    <Grid container columns={12} paddingTop={4} paddingX={2}>
      <Grid item xs={12}>
        <Typography variant="h4" color="primary" align="left">
          {mainTitle}
          <ShortLine position="left" />
        </Typography>
      </Grid>

      {!order ? (
        <ExchangeFormContainer>
          <SelectCurrency
            title={selectCurrencyTitle}
            currentCurrency={currentCurrency}
            selectCurrency={selectCurrency}
            exchangeRates={exchangeRates}
            handleCurrencyOnSelect={handleCurrencyOnSelect}
          />
          <ExchangeInputs
            title={title}
            points={points}
            crypto={crypto}
            pointsText={pointsText}
            exchangeRateText={exchangeRateText}
            limit={limit}
            onChange={handleOnChange}
          />
          <ButtonSubmit
            disabled={isExchangeDisabled}
            isLoading={isRedeeming}
            setIsLoading={setIsRedeeming}
            onClick={handleSubmit}
          >
            {buttonText}
          </ButtonSubmit>

          <SwitchCryptoPointsExchanger title={switchTitle} linkText={linkText} route={route} />
        </ExchangeFormContainer>
      ) : (
        <OrderExchangeForm order={order} textFields={textFields} />
      )}
    </Grid>
  );
};
