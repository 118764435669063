import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper'; // import required modules
import 'swiper/css'; // Import Swiper styles
import 'swiper/css/pagination'; // Import Swiper styles
import 'swiper/css/navigation'; // Import Swiper styles
import { Box } from '@mui/material';
import MiniLoader from '../../../components/Loaders/MiniLoader/MiniLoader';
import { useHome } from '../../../hooks/home';
import { HOME_CARROUSEL_STYLES } from './style';

export const Carrousel = () => {
  const { carrouselByClientLanguageQuery } = useHome();

  return (
    <Box>
      {carrouselByClientLanguageQuery.isLoading ? (
        <MiniLoader />
      ) : (
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          style={HOME_CARROUSEL_STYLES.swiper}
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {carrouselByClientLanguageQuery.data?.map(({ imagen, leyenda, id }) => (
            <SwiperSlide key={`carousel-img-${id}`}>
              <img src={`${imagen}`} alt={leyenda} style={HOME_CARROUSEL_STYLES.swiperSLide} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Box>
  );
};
