import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AVATAR_ROUTES, PERMISOS_CODIGOS } from '../../common/constants/constants';
import useLanguage from '../../common/hooks/useLanguage';
import { HeaderDictionary } from '../../common/interfaces/header';
import { useRightSide } from '.';
import { useAppContext, usePerson } from '../../common/hooks';
import ES from '../../common/language/header/es.json';
import EN from '../../common/language/header/en.json';

export const useHeader = () => {
  const { user, userName, email, lastName, permissions, handleLogout } = useAppContext();
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: HeaderDictionary };
  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(true);
  const { anchor, isMenuOpen, handleOnClick, handleOnClose } = useRightSide();
  const {
    clientPointsQuery: { data: userPoints },
  } = usePerson({ isFetchingUserPoints: true });

  const navigate = useNavigate();

  const avatar: string = AVATAR_ROUTES.filter(({ avatar }) => avatar === user?.avatar)[0]?.icono;

  const isHasRecognitionsPermission: boolean = Boolean(
    permissions?.find((el) => el.codigo === PERMISOS_CODIGOS.reconocimientosEnDashboardWeb)?.idPermiso
  );

  const isHasCryptoPermission: boolean = Boolean(
    permissions?.find((el) => el.codigo === PERMISOS_CODIGOS.criptomonedas)?.idPermiso
  );

  const handleOnNavigate = (route: string): void => navigate(route);
  const handleScroll = (): void => {
    const isShowingHeader: boolean = !Boolean(window.scrollY > 70);
    setIsHeaderVisible(isShowingHeader);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return {
    isHeaderVisible,
    user,
    userName,
    email,
    lastName,
    avatar,
    dictionary,
    isHasRecognitionsPermission,
    isHasCryptoPermission,
    anchor,
    isMenuOpen,
    puntos: userPoints,
    handleOnClick,
    handleOnClose,
    handleOnNavigate,
    handleLogout,
  };
};
