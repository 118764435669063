export interface ILiriumUser {
  isPermisosInCrypto: boolean;
}

export interface LiriumUser {
  idLiriumUsuario: number;
  idUsuario: number;
  id: string;
  label: string;
  estado: string;
  fechaActualizacion: Date;
  idCliente: number;
}

export interface CryptoDictionary {
  title: string;
  preLogIn: PreLogIn;
  balance: string;
  currency: string;
  exchangeRate: string;
  currenciesTypes: CryptoExchangeCurrencyTypes;
  buttons: Buttons;
  table: Table;
}

interface Table {
  column: CustomerOrdersFormatTable;
  row: CustomerOrdersFormatTable;
  error: string;
  errorStatus: string;
  loading: string;
  noResults: string;
}

export interface CustomerOrdersFormatTable {
  index: number;
  created_at: string;
  currency: string;
  amount: string;
  operation: string;
  id: string;
}

export enum CryptoExchangeCurrencyTypes {
  bitcoin = 'BTC',
  dai = 'DAI',
  ethereum = 'ETH',
  tether = 'USDT',
}
interface Buttons {
  next: string;
  get: string;
  sell: string;
  selectCurrency: string;
}

interface PreLogIn {
  title: string;
  subtitle: string;
}

export interface LiriumCustomer {
  id: string;
  reference_id: string;
  state: string;
  created_at: Date;
  profile: Profile;
  contact: Contact;
  products: Product[];
  data_requirements: DataRequirement[];
}

interface Contact {
  email: string;
  cellphone: string;
}

interface DataRequirement {
  id: string;
  created: Date;
  type: string;
  document_type: string;
  status: string;
  last_updated_at: Date;
  submitted_at: Date;
}

interface Product {
  code: string;
  active: boolean;
  enabled: boolean;
}

interface Profile {
  first_name: string;
  middle_name: string;
  last_name: string;
  date_of_birth: string | Date;
  national_id_country: string;
  national_id_type: string;
  national_id: string;
  citizenship: string;
  address_line1: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  // La Api de Lirium agrega una valor por defecto
  address_line2?: string;
  label?: string;
}

export interface CustomerAccounts {
  accounts: Account[];
}

interface Account {
  currency: string;
  amount: string;
}

export interface BalanceProps {
  balanceText: string;
  currencyText: string;
  amount: string;
  cryptoExchangeCurrencyTypes: string[];
  currentCurrency: CryptoExchangeCurrencyTypes;
  setCurrentCurrency: (currency: CryptoExchangeCurrencyTypes) => void;
}

export interface SignUpCryptoDictionary {
  title: string;
  subtitle: string;
  form: SignUpCryptoDictionaryForm;
  buttons: Buttons;
  success: string;
  error: string;
}

interface SignUpCryptoDictionaryForm {
  name: string;
  middleName: string;
  lastName: string;
  dni: string;
  dateOfBirth: string;
  fullAddress: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
  email: string;
  phoneNumber: string;
  uploadImagesDNI: UploadImagesDNI;
  error: string;
}

interface UploadImagesDNI {
  frontIDTitle: string;
  frontIDSubtitle: string;
  backIDTitle: string;
  backIDSubtitle: string;
  selfieTitle: string;
  selfieSubtitle: string;
}

export interface CustomerBody {
  reference_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  date_of_birth: string; // Puedes cambiarlo a tipo Date si es necesario
  national_id_country: string;
  national_id_type: string;
  national_id: string;
  citizenship: string;
  address_line1: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  email: string;
  cellphone: string;
  address_line2?: string;
}

export interface ExternalAPILiriumCustomer {
  reference_id: string;
  profile: Profile;
  contact: Contact;
}

interface Contact {
  email: string;
  cellphone: string;
}

export interface ExternalAPILiriumCustomerResponse {
  id: string;
  created_at: Date;
  reference_id: string;
  state: string;
  profile: Profile;
  contact: Contact;
  products: Product[];
  data_requirements: DataRequirement[];
}

interface DataRequirement {
  id: string;
  created: Date;
  type: string;
  document_type: string;
  status: string;
  last_updated_at: Date;
}

interface Product {
  code: string;
  active: boolean;
  enabled: boolean;
}

export interface InternalAPILiriumUser {
  idCliente: number;
  idUsuario: number;
  id: string;
  label: string;
  estado: string;
  fechaActualizacion: string | Date;
}

export interface PostCustomerProps {
  idUsuario: number;
  customerBody: CustomerBody;
}

export interface InternalAPILiriumUserResponse extends InternalAPILiriumUser {
  idLiriumUsuario: number;
}
