export enum RecognitionExcludeSortingColumns {
  idReconocimiento = 'idReconocimiento',
  idCliente = 'idCliente',
  fecha = 'fecha',
  aprobar = 'aprobar',
  tituloTipoReconocimiento = 'tituloTipoReconocimiento',
  nombreUsuarioReconocido = 'nombreUsuarioReconocido',
  nombreUsuarioSupervisor = 'nombreUsuarioSupervisor',
  puntos = 'puntos',
  estado = 'estado',
  fechaAprobacion = 'fechaAprobacion',
  descripcion = 'descripcion',
}

export interface IRecognitionsData {
  idReconocimiento: number;
  id: number;
  idCliente: number;
  fecha: Date;
  idUsuarioAsignador: number;
  idUsuarioReconocido: number;
  idUsuarioSupervisor: number;
  idTipoReconocimiento: number;
  puntos: number;
  estado: string;
  fechaAprobacion?: Date;
  idMovimiento: number;
  descripcion: string;
  nombreUsuarioAsignador: string;
  nombreUsuarioReconocido: string;
  nombreUsuarioSupervisor: string;
  descripcionTipoReconocimiento: string;
  tituloTipoReconocimiento: string;
  fechaString: string;
  horaString: string;
  fechaAprobacionString?: string;
  imagen: string;
  cantidadTotalRegistros: number;
}
export interface IRecognitions {
  cantidadTotalRegistros: number;
  cantidadPaginas: number;
  data: IRecognitionsData[];
  cantidadRegistros: number;
  pagina: number;
}

export interface IRecognitionStatus {
  approved: string;
  pending: string;
}

export enum RecognitionsColumns {
  idReconocimiento = 'idReconocimiento',
  fecha = 'fecha',
  tituloTipoReconocimiento = 'tituloTipoReconocimiento',
  nombreUsuarioReconocido = 'nombreUsuarioReconocido',
  nombreUsuarioSupervisor = 'nombreUsuarioSupervisor',
  nombreUsuarioAsignador = 'nombreUsuarioAsignador',
  puntos = 'puntos',
  estado = 'estado',
  fechaAprobacion = 'fechaAprobacion',
  descripcion = 'descripcion',
}

export enum ISortDirections {
  asc = 'asc',
  desc = 'desc',
}

export interface IRecognitionsSort {
  column: RecognitionsColumns;
  direction: ISortDirections;
}

export interface IRecognitionSearch {
  placeHolder: string;
}

export interface IRecognitionPagination {
  first: string;
  last: string;
  next: string;
  previous: string;
}

export interface IFormatTableColumns {
  label: string;
  queryParam: string;
  hasOrdering: boolean;
}

export interface DetailButtons {
  cancel: string;
  linkedIn?: string;
}

export interface Detail {
  title: string;
  points: string;
  buttons: DetailButtons;
}

export interface IRecognitionActions {
  status: IRecognitionStatus;
  detail: Detail;
}

export interface IRecognitionsQueryParams {
  ordenamiento: string;
  pagina: number;
  cantidadRegistrosPorPagina: number;
  idioma?: string;
  idUsuario?: number;
  usuario?: string;
}

export enum STATUS_APPROVED_RECOGNITION {
  approved = 'APROBADO',
  pending = 'PENDIENTE APRO.',
}

export interface IButtonDetail {
  title: string;
  text: string;
  cancelButtonText: string;
  image?: string;
  html?: string;
}

export interface ICustomSimpleTableColumn {
  label: string;
}

export interface IRecognitionsCommonRows {
  idReconocimiento: number;
  fechaString: string;
  descripcionTipoReconocimiento: string;
  descripcion: React.ReactNode;
}
