import { CSSProperties } from 'styled-components';

export const SIGN_UP_CRYPTO_FORM_STYLES = {
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  } as CSSProperties,
};
