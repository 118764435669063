import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getLiriumCustomerOrders, getLiriumExchangeRates } from '../../../services/lirium_orders';
import { AppContext } from '../../../context';

interface LiriumOrder {
  customerOrders?: string;
  currency?: string;
}

export const useLiriumOrder = ({ customerOrders, currency }: LiriumOrder) => {
  const appContext = useContext(AppContext);
  const idUsuario: number = appContext?.user?.idUsuario;

  const cryptoCustomerOrdersQuery = useQuery({
    queryKey: ['crypto-orders-customer', { idUsuario }],
    queryFn: async () => await getLiriumCustomerOrders(customerOrders),
    enabled: Boolean(customerOrders && idUsuario),
  });

  const cryptoExchangeRatesQuery = useQuery({
    queryKey: ['crypto-exchange-rates', { idUsuario, currency }],
    queryFn: async () => await getLiriumExchangeRates(currency),
    enabled: Boolean(currency && idUsuario),
  });

  return { cryptoCustomerOrdersQuery, cryptoExchangeRatesQuery };
};
