import { API_RECOGNITIONS_URL } from '../../../common/constants';
import { IRecognitions, IRecognitionsQueryParams } from '../../../common/types/recognitions';
import { fetchContent } from '../../../utils/fetchUtils';
import { setQueryParams } from '../../../utils/functions';
import { handleServiceError } from '../../GiftCardService';

export const getMadeRecognitions = async (recognition: IRecognitionsQueryParams): Promise<IRecognitions> => {
  const URL: string = setQueryParams(API_RECOGNITIONS_URL.recognition.my, recognition);

  try {
    const response: IRecognitions = await fetchContent(URL, {
      method: 'GET',
    });

    return response;
  } catch (error) {
    console.error('[getMadeRecognitions]: ' + error);
    throw handleServiceError(error);
  }
};
