import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '../../../context';
import { ILiriumUser } from '../../types';
import { getCryptoPoints, getCustomer, getCustomerAccounts, getUserById } from '../../../services/lirium_user';

interface LiriumUserProps {
  cryptoPoints?: ILiriumUser;
  isFetchingCustomer?: boolean;
  isFetchingCustomerAccounts?: boolean;
  isFetchingUser?: boolean;
}

export const useLiriumUser = ({
  cryptoPoints,
  isFetchingCustomer,
  isFetchingCustomerAccounts,
  isFetchingUser,
}: LiriumUserProps) => {
  //* todo lo que diga "customer" = API de lirium
  const appContext = useContext(AppContext);
  const idUsuario: number = appContext?.user?.idUsuario;

  const cryptoPointsQuery = useQuery({
    queryKey: ['crypto-points', { idUsuario }],
    queryFn: async () => await getCryptoPoints(),
    enabled: Boolean(idUsuario && cryptoPoints?.isPermisosInCrypto),
  });

  const cryptoUserQuery = useQuery({
    queryKey: [`crypto-user`, { idUsuario }],
    queryFn: async () => await getUserById(idUsuario),
    enabled: Boolean(idUsuario && isFetchingUser),
  });

  const cryptoCustomerQuery = useQuery({
    queryKey: [`crypto-customer`, { idUsuario }],
    queryFn: async () => {
      const idCustomer: string = cryptoUserQuery?.data?.id;
      const customer = await getCustomer(idCustomer);
      return customer;
    },
    enabled: Boolean(idUsuario && isFetchingCustomer && isFetchingUser && cryptoUserQuery?.data?.id),
  });

  const cryptoCustomerAccountsQuery = useQuery({
    queryKey: [`crypto-customer-accounts`, { idUsuario }],
    queryFn: async () => {
      const idCustomer: string = cryptoUserQuery?.data?.id;
      const customerAccount = await getCustomerAccounts(idCustomer);
      return customerAccount;
    },
    enabled: Boolean(idUsuario && isFetchingCustomerAccounts && cryptoUserQuery?.data?.id),
  });

  return { idUsuario, cryptoPointsQuery, cryptoCustomerQuery, cryptoCustomerAccountsQuery, cryptoUserQuery };
};
