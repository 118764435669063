import { useApprovedRecognitions } from '../../../hooks/recognitions';
import { ApprovedRecognitionsActions } from '../../../components/Recognitions';
import { ViewTable } from '../../../components/ViewTable/ViewTable';

export const ApprovedRecognitions = () => {
  const {
    approvedRecognitions,
    isLoading,
    dictionary,
    columns,
    page,
    sort,
    totalPages,
    form,
    isAnyPending,
    isApproving,
    handleSubmit,
    handleChangePage,
    changeSort,
    setPage,
    setSearch,
    setIsApproving,
  } = useApprovedRecognitions();

  return (
    <ViewTable
      title={dictionary.title}
      searchBarPlaceHolder={dictionary.form.search.placeHolder}
      isLoading={isLoading}
      rows={approvedRecognitions}
      page={page}
      totalPages={totalPages}
      noResults={dictionary.table.noResult}
      columns={columns}
      sort={sort}
      showSearchBar={true}
      setPage={setPage}
      changeSort={changeSort}
      setSearch={setSearch}
      handleChangePage={handleChangePage}
      actions={
        <ApprovedRecognitionsActions
          isApproving={isApproving}
          isAnyPending={isAnyPending || isApproving}
          isFormEmpty={form.idReconocimientos.length === 0}
          button={dictionary.form.create.buttons.approve}
          handleSubmit={handleSubmit}
          setIsApproving={setIsApproving}
        />
      }
    />
  );
};
