import { API_PERSON_URL } from '../../common/constants';
import { PersonUser } from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const getPersonByIdUser = async (idUsuario: number): Promise<PersonUser> => {
  const URL: string = API_PERSON_URL.userById.replace('{idUsuario}', String(idUsuario));

  try {
    const user: PersonUser = await fetchContent(URL, {
      method: 'GET',
    });
    return user;
  } catch (error: any) {
    console.error('[getPersonByIdUser]: ' + error);
    throw handleServiceError(error);
  }
};
