import React from 'react';
import { Box, Button, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { ArrowForwardIosRounded, ArrowBackIosRounded } from '@mui/icons-material';
import { formatNumber } from '../../../../utils/functions';
import { handleOnInputChangeProps, Product } from '../../../../common/types';
import { CUSTOM_COUNT_STYLES } from './style';

type Props = {
  exchangeNotAllowed: string;
  currency: string;
  minPriceText: string;
  maxPriceText: string;
  minPrice: number;
  maxPrice: number;
  amount: number;
  isError: boolean;
  product: Product;
  handleArrowOnClick: (e: React.MouseEvent) => void;
  handleOnInputChange: (input: handleOnInputChangeProps) => void;
};

export const CustomCount: React.FC<Props> = ({
  exchangeNotAllowed,
  maxPrice,
  minPrice,
  amount,
  isError,
  currency,
  product,
  minPriceText,
  maxPriceText,
  handleArrowOnClick,
  handleOnInputChange,
}) => {
  return (
    <>
      {maxPrice && (
        <Box sx={CUSTOM_COUNT_STYLES.container}>
          <Box sx={CUSTOM_COUNT_STYLES.errorMessage}>
            <Typography color="red" fontSize={12} visibility={isError ? 'visible' : 'hidden'}>
              {exchangeNotAllowed}
            </Typography>
          </Box>
          <Box sx={CUSTOM_COUNT_STYLES.containerButtons}>
            <Button
              variant="contained"
              disableElevation
              size="small"
              disabled={amount <= minPrice}
              color="btnPrimary"
              id="prev"
              onClick={handleArrowOnClick}
              style={{ padding: 0 }}
              sx={{ ...CUSTOM_COUNT_STYLES.buttonLeft, ...CUSTOM_COUNT_STYLES.button }}
              children={<ArrowBackIosRounded sx={CUSTOM_COUNT_STYLES.arrowIcon} />}
            />
            <OutlinedInput
              sx={CUSTOM_COUNT_STYLES.input}
              value={amount ? formatNumber(amount) : formatNumber(minPrice)}
              placeholder={minPrice.toString()}
              startAdornment={<InputAdornment position="start">{currency}</InputAdornment>}
              inputMode="numeric"
              onChange={(e) => {
                e.preventDefault();
                handleOnInputChange({
                  maxPrice,
                  minPrice,
                  deltaIncrementPrice: product?.deltaIncrementoPrecio,
                  value: +e.target.value,
                  idProducto: product?.idProducto,
                });
              }}
            />
            <Button
              variant="contained"
              disableElevation
              size="small"
              disabled={amount + product?.deltaIncrementoPrecio > maxPrice}
              color="btnPrimary"
              id="next"
              onClick={handleArrowOnClick}
              sx={{ ...CUSTOM_COUNT_STYLES.buttonRight, ...CUSTOM_COUNT_STYLES.button }}
              children={<ArrowForwardIosRounded sx={CUSTOM_COUNT_STYLES.arrowIcon} />}
            />
          </Box>
          <Box sx={CUSTOM_COUNT_STYLES.subtitle}>
            <Typography color="#adadad" fontSize={12}>
              {minPriceText.replace('{currency}', currency).replace('{price}', formatNumber(minPrice))}
            </Typography>
            <Typography color="#adadad" fontSize={12}>
              {maxPriceText.replace('{currency}', currency).replace('{price}', formatNumber(maxPrice))}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
