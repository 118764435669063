import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '../../../context';
import { DEFAULT_STALE_TIME } from '../../constants/constants';
import { getPercentageFinancialEducation } from '../../../services/financial_education';

export const useEducationLevelUserVideo = () => {
  const appContext = useContext(AppContext);
  const idUsuario: number = appContext?.user?.idUsuario;

  const percentageQuery = useQuery({
    queryKey: ['user-video-education-level-percentage', idUsuario],
    queryFn: async () => await getPercentageFinancialEducation(),
    staleTime: DEFAULT_STALE_TIME,
    enabled: Boolean(idUsuario),
  });

  return { percentageQuery };
};
