import { CSSProperties } from 'styled-components';

export const STYLES = {
  mainBox: {
    paddingTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    gap: '10px',
  } as CSSProperties,
  gridMainContent: {
    display: 'flex',
    alignItems: 'center',
  } as CSSProperties,
  text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as CSSProperties,
  linearProgress: {
    height: '20px',
    borderRadius: '15px',
  } as CSSProperties,
  footer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '6px',
  } as CSSProperties,
};
