import { API_TRANSACTIONS_URL } from '../../common/constants';
import { IConsolidatedPerson, IConsolidatedPersonProps } from '../../common/types';
import { fetchContent, setQueryParams } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const getConsolidatedPerson = async (props: IConsolidatedPersonProps): Promise<IConsolidatedPerson> => {
  try {
    const URL: string = setQueryParams(API_TRANSACTIONS_URL.consolidatedPerson, props);

    const response: IConsolidatedPerson = await fetchContent(URL, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.error('[getConsolidatedPerson]: ' + error);
    throw handleServiceError(error);
  }
};
