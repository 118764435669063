import React from 'react';
import { TransactionsInCryptoProps } from '../../../../common/types';
import { CustomSimpleTable } from '../../../../components/CustomSimpleTable/CustomSimpleTable';

interface Props {
  transactionsInCrypto: TransactionsInCryptoProps;
}

export const TransactionsInCrypto: React.FC<Props> = ({
  transactionsInCrypto: {
    customerOrdersTableColumns,
    customerOrdersTableRows,
    isCustomerOrdersLoading,
    isCustomerOrdersError,
    noResults,
  },
}) => {
  return (
    <CustomSimpleTable
      columns={customerOrdersTableColumns}
      rows={customerOrdersTableRows}
      isLoading={isCustomerOrdersLoading}
      isError={isCustomerOrdersError}
      noResults={noResults}
    />
  );
};
