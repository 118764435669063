import { API_RECOGNITIONS_URL } from '../../../common/constants';
import { IRecognitionDashboardRanking } from '../../../common/types';
import { fetchContent, setQueryParams } from '../../../utils';
import { handleServiceError } from '../../GiftCardService';

export interface IRecognitionDashboardRankingParams {
  idioma: string;
  fechaDesde: Date;
  fechaHasta: Date;
  cantidadRegistros: number;
}

export const postRecognitionDashboardRanking = async (
  params: IRecognitionDashboardRankingParams
): Promise<IRecognitionDashboardRanking[]> => {
  const { idioma, fechaDesde, fechaHasta, cantidadRegistros } = params;
  const URL: string = setQueryParams(API_RECOGNITIONS_URL.dashboard.ranking, { idioma });

  const body = {
    fechaDesde,
    fechaHasta,
    cantidadRegistros,
  };

  try {
    const response: IRecognitionDashboardRanking[] = await fetchContent(URL, {
      method: 'POST',
      body,
    });
    return response;
  } catch (error: any) {
    console.error('[postRecognitionDashboardRanking]: ' + error);
    throw handleServiceError(error);
  }
};
