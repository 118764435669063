import { IRecognitionDashboardRankingTable, IRecognitionDashboardRanking } from '../../common/types';

export const formatTableRowsTheMostRecognitionsOfTheMonth = (
  rows: IRecognitionDashboardRanking[]
): IRecognitionDashboardRankingTable[] => {
  return rows?.map(({ nombre, sector }, index) => {
    return {
      ranking: index + 1,
      name: nombre,
      sector,
    };
  });
};
