import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import ShortLine from '../../../../components/ShortLine/ShortLine';
import { LiriumOrderExternalAPI, LiriumOrderTextFields } from '../../../../common/types';
import { useOrderExchangeForm } from '../../../../hooks/crypto';
import { CodeContainer, ExchangeFormContainer, ORDER_EXCHANGE_FORM_STYLES } from '../..';
import { RUTAS } from '../../../../common/constants/rutas';
import ButtonSubmit from '../../../../components/ButtonSubmit/ButtonSubmit';

interface OrderExchangeFormProps {
  order: LiriumOrderExternalAPI;
  textFields: LiriumOrderTextFields;
}

export const OrderExchangeForm: React.FC<OrderExchangeFormProps> = ({ order, textFields }) => {
  const {
    code,
    textFieldInput,
    dictionary,
    isConfirmingOrder,
    isCodeBoxFilled,
    setIsConfirmingOrder,
    handleChange,
    handleKeyDown,
    handleConfirm,
  } = useOrderExchangeForm();

  return (
    <ExchangeFormContainer>
      <Typography variant="h6" color="primary">
        {dictionary.title}
        <ShortLine />
      </Typography>

      <CodeContainer
        code={code}
        textFieldInput={textFieldInput}
        handleChange={handleChange}
        handleKeyDown={handleKeyDown}
      />

      <ButtonSubmit
        isLoading={isConfirmingOrder}
        disabled={!isCodeBoxFilled}
        setIsLoading={setIsConfirmingOrder}
        onClick={() => handleConfirm(order, textFields)}
      >
        {dictionary.buttons.next}
      </ButtonSubmit>

      <Box sx={ORDER_EXCHANGE_FORM_STYLES.resendContainer}>
        <Typography variant="subtitle1" color="primary">
          {dictionary.form.isEmailNotSent}
        </Typography>
        <Link to={RUTAS.cripto}>
          <Typography variant="subtitle1" color="primary" fontWeight={'bold'}>
            &nbsp;{dictionary.buttons.resendEmail}
          </Typography>
        </Link>
      </Box>
    </ExchangeFormContainer>
  );
};
