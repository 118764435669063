import { useContext } from 'react';
import {
  Box,
  CircularProgress,
  Pagination,
  PaginationItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ImportExport as ImportExportIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import { ThemeContext } from '../../context';
import CustomTableRow from './CustomTableRow';
import { Rows, Sort, IFormatTableColumns } from '../../common/types';

interface Props {
  data: Rows;
  columns: IFormatTableColumns[];
  sort: Sort;
  isLoading: boolean;
  page: number;
  totalPages: number;
  noResults: string;
  handleChangePage(event: React.ChangeEvent<unknown>, value: number): void;
  changeSort: (sort: string) => void;
}

const CustomTable = (props: Props) => {
  const { columns, data, isLoading, page, totalPages, sort, noResults, handleChangePage, changeSort } = props;
  const theme = useContext(ThemeContext);

  return (
    <Box marginTop={3}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#E9E9E9', fontWeight: 'bold' }}>
              {columns.map((col) => {
                return (
                  <TableCell
                    key={`header-${col.label}`}
                    align="center"
                    sx={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      cursor: col.hasOrdering && 'pointer',
                    }}
                    onClick={() => col.hasOrdering && changeSort(col.queryParam)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography fontSize={14} sx={{ fontWeight: 'bold' }}>
                        {col.label}
                      </Typography>
                      {col.hasOrdering ? (
                        sort.column === col.queryParam ? (
                          sort.direction.includes('asc') ? (
                            <ArrowDropUpIcon fontSize="small" />
                          ) : (
                            <ArrowDropDownIcon fontSize="small" />
                          )
                        ) : (
                          <ImportExportIcon fontSize="small" />
                        )
                      ) : null}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={columns.length} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : data?.length > 0 ? (
              data.map((row, index) => <CustomTableRow key={`table-row-${index}`} {...row} />)
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={columns.length} sx={{ fontWeight: 'bold' }}>
                  {noResults}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 3,
        }}
      >
        <Stack spacing={2}>
          <Pagination
            color="primary"
            shape="rounded"
            variant="outlined"
            page={page}
            count={totalPages}
            onChange={handleChangePage}
            renderItem={(item) => (
              <PaginationItem
                components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: theme?.theme && theme?.theme[10] ? theme?.theme[10].color : 'primary', // Color de fondo para el número de página seleccionado
                    color: 'white', // Color del texto para el número de página seleccionado
                  },
                }}
              />
            )}
          />
        </Stack>
      </Box>
    </Box>
  );
};
export default CustomTable;
