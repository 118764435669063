import { useEffect, useState } from 'react';
import { OrderOperation } from '../../common/types';
import { useCryptoPointsExchanger } from '.';
import { calculateCryptoPerPoints } from '../../utils';
import { useClient, useLiriumUser } from '../../common/hooks';

export const useSellCrypto = () => {
  const {
    dictionary,
    currentCurrency,
    isExchangeDisabled,
    order,
    isRedeeming,
    textFields,
    exchangeRates,
    setTextFields,
    setIsRedeeming,
    handleCurrencyOnSelect,
    handlePointsOnChange,
    handleSubmit,
  } = useCryptoPointsExchanger();
  const [balance, setBalance] = useState<number>(0);

  const {
    cryptoCustomerAccountsQuery: { data: customerAccounts },
  } = useLiriumUser({
    isFetchingUser: true,
    isFetchingCustomerAccounts: true,
  });

  const {
    pointsPerAmountQuery: { data: pointsPerAmount },
  } = useClient({
    pointsPerAmount: textFields.points,
  });

  const findCurrency: string = currentCurrency
    ? customerAccounts?.accounts.find((account) => account.currency === currentCurrency)?.amount
    : '0';

  const handleSellCrypto = async (): Promise<void> => {
    const submitOrder: void = await handleSubmit(OrderOperation.Sell, pointsPerAmount);

    return submitOrder;
  };

  useEffect(() => {
    setBalance(Number(findCurrency));
    setTextFields((prevTextFields) => ({
      ...prevTextFields,
      crypto:
        calculateCryptoPerPoints(pointsPerAmount, exchangeRates?.quotes, currentCurrency) || prevTextFields.points,
    }));
  }, [pointsPerAmount, exchangeRates?.quotes, findCurrency, currentCurrency, setTextFields]);

  return {
    order,
    isRedeeming,
    isExchangeDisabled,
    balance: parseFloat(String(balance)).toFixed(8),
    currentCurrency,
    dictionary,
    exchangeRates,
    textFields,
    pointsPerAmount,
    setIsRedeeming,
    handlePointsOnChange,
    handleCurrencyOnSelect,
    handleSellCrypto,
  };
};
