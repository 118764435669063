import { RECENT_TRANSACTIONS_DEFAULT_RECORDS_PER_PAGE } from '../../../common/constants/constants';
import { ICustomSimpleTableColumn, IRecentTransactionTable } from '../../../common/types';
import { formatCustomSimpleTableRows, formatTableSimpleColumns } from '../../../utils';
import { useTransactionsPerson } from '../../../common/hooks';

interface IMyRecentTransactions {
  idioma: string;
  columns: IRecentTransactionTable;
}

export const useMyRecentTransactions = (props: IMyRecentTransactions) => {
  const { idioma, columns } = props;

  const { consolidatedHomeQuery } = useTransactionsPerson({
    transactionsPersonHome: { idioma, cantidadRegistros: RECENT_TRANSACTIONS_DEFAULT_RECORDS_PER_PAGE },
  });

  const myRecentTransactionsColumns: ICustomSimpleTableColumn[] = formatTableSimpleColumns(columns);

  const myRecentTransactionsRows: IRecentTransactionTable[] = formatCustomSimpleTableRows(
    consolidatedHomeQuery.data ?? null
  );

  return { consolidatedHomeQuery, myRecentTransactionsColumns, myRecentTransactionsRows };
};
