import { Loading } from '../../components';
import { CryptoAuth, CryptoPreLogIn } from './components';
import { useCrypto } from '../../hooks/crypto';

export const Crypto = () => {
  const {
    dictionary,
    isLoading,
    amount,
    user,
    cryptoUser,
    currentCurrency,
    cryptoExchangeCurrencyTypes,
    currency,
    customerOrdersTableColumns,
    customerOrdersTableRows,
    isCustomerOrdersLoading,
    isCustomerOrdersError,
    isHasCryptoAccount,
    onNavigate,
    setCurrentCurrency,
  } = useCrypto();

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : isHasCryptoAccount ? (
        <CryptoAuth
          title={dictionary.title}
          get={dictionary.buttons.get}
          sell={dictionary.buttons.sell}
          balance={{
            balanceText: dictionary.balance,
            currencyText: dictionary.currency,
            amount,
            cryptoExchangeCurrencyTypes,
            currentCurrency,
            setCurrentCurrency,
          }}
          currencyGraphic={{
            currency,
            graphicTitle: dictionary.exchangeRate,
          }}
          transactionsInCrypto={{
            customerOrdersTableColumns,
            customerOrdersTableRows,
            isCustomerOrdersLoading,
            isCustomerOrdersError,
            noResults: dictionary.table.noResults,
          }}
          onNavigate={onNavigate}
        />
      ) : (
        <CryptoPreLogIn
          title={dictionary.preLogIn.title}
          subtitle={dictionary.preLogIn.subtitle}
          next={dictionary.buttons.next}
          user={user}
          cryptoUser={cryptoUser}
          onNavigate={onNavigate}
        />
      )}
    </>
  );
};
