import { API_LIRIUM_USER_URL } from '../../common/constants';
import { CustomerAccounts } from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const getCustomerAccounts = async (idCustomer: string): Promise<CustomerAccounts> => {
  try {
    const URL: string = API_LIRIUM_USER_URL.customerAccountsByCustomerId.replace('{customerId}', String(idCustomer));

    const response: CustomerAccounts = await fetchContent(URL, {
      method: 'GET',
    });

    return response;
  } catch (error: any) {
    console.error('[getCustomerAccounts]: ' + error);
    throw handleServiceError(error);
  }
};
