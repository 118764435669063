import { CSSProperties } from 'styled-components';

export const CUSTOM_SIMPLE_TABLE_STYLES = {
  noResults: {
    color: 'primary',
    marginTop: '0',
  } as CSSProperties,
  tableRow: {
    backgroundColor: '#E9E9E9',
    fontWeight: 'bold',
  } as CSSProperties,
  columnBox: {
    fontSize: 12,
    fontWeight: 'bold',
  } as CSSProperties,
  columnText: {
    fontWeight: 'bold',
  } as CSSProperties,
  loaderBox: {
    textAlign: 'center',
    fontWeight: 'bold',
  } as CSSProperties,
  noResultsBox: {
    fontWeight: 'bold',
  } as CSSProperties,
};
