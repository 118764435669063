import React from 'react';
import { validarRegalo } from '../../utils/validaciones';
import { incrementar } from '../../common/redux/features/contadorItemReducer';
import { Product, ProductsDetailWeb } from '../../common/types';
import { addToCart } from '../../common/redux/features/carritoReducer';
import { modalError, modalSuccess } from '../../utils';

interface GiftModalProps {
  dispatch: React.Dispatch<React.SetStateAction<any>>;
  validateRecipient: string;
  validateEmail: string;
  validateMessage: string;
  success: string;
  error: string;
  points: number;
  amount: number;
  product: Product;
  productDetail: ProductsDetailWeb;
  amountOfGiftCards: number;
}

const initForm = {
  PersonaRegalo: '',
  Email: '',
  Comentario: '',
};

export const useGiftModal = ({
  dispatch,
  validateRecipient,
  validateEmail,
  validateMessage,
  product,
  productDetail,
  points,
  amount,
  success,
  error,
}: GiftModalProps) => {
  const [isGiftModalOpen, setIsGiftModalOpen] = React.useState(false);
  const [form, setForm] = React.useState(initForm);

  const handleOnClickModal = (): void => setIsGiftModalOpen((prev) => !prev);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (): Promise<void> => {
    if (
      !validarRegalo(form, {
        ingreseNombre: validateRecipient,
        ingreseEmail: validateEmail,
        ingreseUnComentario: validateMessage,
      })
    ) {
      return;
    }

    if (!product) {
      modalError(error);
      return;
    }

    dispatch(incrementar());
    dispatch(
      addToCart({
        idProducto: productDetail?.idProducto ?? null,
        Imagen: productDetail?.bigPicture ?? null,
        Descripcion: productDetail?.descripcion ?? null,
        puntos: points,
        puntosPorUnidad: points,
        cantidad: 1,
        Nombre: product?.tituloProducto,
        Regalo: true,
        Precio: amount.toString(),
        Marca: productDetail?.marcaDescripcion,
        esGiftCard: product?.esGiftcard,
        ...form,
      })
    );
    modalSuccess(success);
    setForm(initForm);
    handleOnClickModal();
  };

  return { isGiftModalOpen, handleOnClickModal, handleChange, handleSubmit };
};
