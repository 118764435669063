import { API_HOME_CARROUSEL_URL } from '../../common/constants';
import { IHomeCarrouselClientAndLanguage, IHomeCarrouselClientAndLanguageProps } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getHomeCarruselByClientAndLanguage = async ({
  cliente,
  lenguaje,
}: IHomeCarrouselClientAndLanguageProps): Promise<IHomeCarrouselClientAndLanguage[]> => {
  const URL: string = API_HOME_CARROUSEL_URL.clientAndLanguage
    .replace('{cliente}', String(cliente))
    .replace('{lenguaje}', lenguaje);

  try {
    const carrouselImages: IHomeCarrouselClientAndLanguage[] = await fetchContent(URL, {
      method: 'GET',
    });
    return carrouselImages;
  } catch (error: any) {
    console.error('[getHomeCarruselByClientAndLanguage]: ' + error);
    throw handleServiceError(error);
  }
};
