import { CSSProperties } from 'styled-components';

export const SHARE_ON_LINKEDIN_MODAL_STYLES = {
  modalContainer: {
    position: 'fixed',
    padding: '.625em',
    height: '100%',
    boxSizing: 'border-box',
  } as CSSProperties,
  viewDetailBtn: {
    backgroundColor: '#1d7fb8',
    borderRadius: '30px',
  } as CSSProperties,
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    width: '32rem',
    maxWidth: '100%',
    boxSizing: 'border-box',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    backgroundColor: '#f8f9f4',
    borderRadius: '1rem',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    padding: '1rem',
  } as CSSProperties,
  image: {
    width: '100%',
    maxHeight: '19rem',
  } as CSSProperties,
  title: {
    display: 'flex',
    justifyContent: 'start',
    textAlign: 'left',
    fontSize: '20px',
    color: '#1d7fb8',
    margin: '12px',
  } as CSSProperties,
  description: {
    margin: '12px',
  } as CSSProperties,
  points: {
    margin: '12px 12px 30px 12px',
  } as CSSProperties,
  linkedInBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  } as CSSProperties,
  linkedInBtn: {
    margin: '0',
    display: 'flex',
    alignItems: 'center',
  } as CSSProperties,
  closeIconContainer: {
    position: 'absolute',
    right: '0',
    top: '0',
  } as CSSProperties,
};
