import React, { useRef, useState } from 'react';
import { LiriumOrderExternalAPI, LiriumOrderTextFields, OrderExchangeDictionary } from '../../common/types';
import { useLiriumOrderMutation, useLiriumUser, usePerson } from '../../common/hooks';
import useLanguage from '../../common/hooks/useLanguage';
import ES from '../../common/language/crypto_/order_exchange/es.json';
import EN from '../../common/language/crypto_/order_exchange/en.json';

export const useOrderExchangeForm = () => {
  const [code, setCode] = useState<string[]>(Array(6).fill(''));
  const [isConfirmingOrder, setIsConfirmingOrder] = useState<boolean>(false);
  const [isCodeBoxFilled, setIsCodeBoxFilled] = useState<boolean>(false);
  const textFieldInput = useRef<HTMLInputElement[]>([]);
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: OrderExchangeDictionary };

  const { confirmOrderMutation } = useLiriumOrderMutation();

  const {
    idPersona,
    clientPointsQuery: { data: userPoints },
  } = usePerson({
    isFetchingUserPoints: true,
  });

  const {
    cryptoUserQuery: { data: liriumUser },
  } = useLiriumUser({
    isFetchingUser: true,
  });

  const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    event.preventDefault(); //event.persist();

    const { value } = event.target;
    const arr = code;
    arr[index] = value;
    const isEveryInputFilled: boolean = Boolean(arr.every((item) => item !== ''));

    setCode(arr);
    setIsCodeBoxFilled(isEveryInputFilled);

    const isFocusingCurrentInput: boolean = Boolean(value.length === 1 && index < textFieldInput.current.length - 1);

    if (isFocusingCurrentInput) {
      textFieldInput.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLDivElement>): void => {
    const isFocusingCurrentInput: boolean = Boolean(event.key === 'Backspace' && index > 0 && code[index] === '');

    if (isFocusingCurrentInput) {
      event.preventDefault();
      textFieldInput.current[index - 1]?.focus();
    }
  };

  const handleConfirm = async (order: LiriumOrderExternalAPI, textField: LiriumOrderTextFields): Promise<void> => {
    const isConfirmingOrder: boolean = Boolean(order && userPoints >= textField.points);

    if (!isConfirmingOrder) return;

    await confirmOrderMutation.mutateAsync({
      confirmOrder: {
        order: {
          idPersona,
          customerId: liriumUser?.id,
          ordenId: order?.id,
          currency: order?.buy?.settlement?.currency ?? order?.sell?.settlement?.currency,
          amount: order?.buy?.settlement?.amount ?? order?.sell?.settlement?.amount,
          points: textField.points,
          quantity: order?.asset?.amount,
          operation: order?.operation,
        },
        successText: dictionary.form.success,
        errorText: dictionary.form.error,
      },
    });
  };

  return {
    code,
    textFieldInput,
    dictionary,
    isConfirmingOrder,
    isCodeBoxFilled,
    setIsConfirmingOrder,
    handleChange,
    handleKeyDown,
    handleConfirm,
  };
};
