import { TableCell, TableRow, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { ICustomSimpleTableColumn } from '../../common/types';

interface SimpleTableRowProps {
  cell: ICustomSimpleTableColumn[];
  cellVariant?: Variant;
}

export const SimpleTableRow: React.FC<SimpleTableRowProps> = ({ cell, cellVariant }) => {
  return (
    <TableRow>
      {cell?.map(({ label }, index) => (
        <TableCell key={index} align="center">
          <Typography variant={cellVariant ?? 'body1'}>{label}</Typography>
        </TableCell>
      ))}
    </TableRow>
  );
};
