import { API_PRODUCT_URL } from '../../common/constants';
import { DetailWeb, ProductsDetailWeb } from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const getProductDetailWeb = async ({ id }: DetailWeb): Promise<ProductsDetailWeb> => {
  const URL: string = API_PRODUCT_URL.detailWeb.replace('{id}', id);

  try {
    const response: ProductsDetailWeb = await fetchContent(URL, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.error('[getProductDetailWeb]: ' + error);
    throw handleServiceError(error);
  }
};
