import { CSSProperties } from 'styled-components';

export const HEADER_STYLES = {
  mainContainer: {
    width: '100%',
    top: 0,
  } as CSSProperties,
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '14px',
  } as CSSProperties,
  burgerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  } as CSSProperties,
  appBar: {
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
  } as CSSProperties,
  logoContainer: {
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    zIndex: '100',
  } as CSSProperties,
  logo: {
    width: '130px',
  } as CSSProperties,
  logoContainerHover: () =>
    ({
      '&:hover': {
        transform: 'scale(1.08)',
        cursor: 'pointer',
      },
    } as CSSProperties),
};
