import { API_USERS_URL } from '../../../common/constants';
import { IMyTeam, IRecognitionsQueryParams } from '../../../common/types/recognitions';
import { fetchContent } from '../../../utils/fetchUtils';
import { setQueryParams } from '../../../utils/functions';
import { handleServiceError } from '../../GiftCardService';

export const getMyTeam = async (queryParams: IRecognitionsQueryParams): Promise<IMyTeam> => {
  try {
    const URL: string = setQueryParams(API_USERS_URL.supervisorUsers, queryParams);

    const response: IMyTeam = await fetchContent(URL, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.error('[getMyTeam]: ' + error);
    throw handleServiceError(error);
  }
};
