import styled from "styled-components";

export const SectionReconocimientoContainer = styled.section`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 40px 20px;
    margin-bottom: 50px;
    flex-flow: row wrap;
    transition: all 0.5s ease-out;
    background-color: #e7edfa;
    position: relative;
`

export const SectionText = styled.div`
    min-height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    z-index: 2;
`
export const SectionImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    z-index: 2;
`

export const Image = styled.img`
    width: 100%;
    max-width: 450px;
`