import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '../../../context';
import { getBranches } from '../../../services/ohGiftCard';
import { OhGiftCardBranches } from '../../types';

interface OhGiftCardProps {
  branches?: OhGiftCardBranches;
}

export const useOhGiftCard = ({ branches }: OhGiftCardProps) => {
  const appContext = useContext(AppContext);
  const idUsuario: number = appContext?.user?.idUsuario;

  const branchesOhGiftCardQuery = useQuery({
    queryKey: ['ohGiftCard-branches', { branches }],
    queryFn: async () => await getBranches(branches?.codigoProductoProveedor),
    enabled: Boolean(idUsuario && branches?.esOhGiftCard),
  });

  return { branchesOhGiftCardQuery };
};
