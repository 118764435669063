import { Box, Divider, Grid, Typography } from '@mui/material';
import FiltroCatalogo from './components/FiltroCatalogo/FiltroCatalogo';
import { SelectCatalogo } from './components/SelectCatalogo';
import './Catalogo.css';
import CatalogoCards from './components/CatalogoCards/CatalogoCards';
import { CustomPagination } from '../../components/CustomPagination/CustomPagination';
import ES from '../../common/language/catalogo/es.json';
import EN from '../../common/language/catalogo/en.json';
import useLanguage from '../../common/hooks/useLanguage';
import MiniLoader from '../../components/Loaders/MiniLoader/MiniLoader';
import { useDispatch } from 'react-redux';
import { resetFilter } from '../../common/redux/features/filtroCatalogoReducer';
import { bodyFilterInit } from '../../common/constants/constants';
import { useCallback, useEffect } from 'react';
import useCatalogo from '../../hooks/catalogo/useCatalogo';

const GiftCard = () => {
  const { products, limpiar, loading, catalogoFilters, currentProducts, setCurrentProducts, setLimpiar } =
    useCatalogo();
  const { dictionary } = useLanguage({ EN, ES });
  const paises = catalogoFilters.data?.paises;
  const dispatch = useDispatch();

  const handleClickFilter = useCallback(() => {
    dispatch(resetFilter());
    setLimpiar(!limpiar);
    let pagina = currentProducts.pagina;
    setCurrentProducts({ ...bodyFilterInit, pagina: pagina });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleClickFilter();
  }, [handleClickFilter]);

  return (
    <Box className="gift-card-section">
      <Grid container columns={14} spacing={2}>
        <Grid item xs={14} sm={14} md={3}>
          <FiltroCatalogo
            paises={paises ? paises : []}
            limpiar={limpiar}
            setLimpiar={setLimpiar}
            filterProducts={currentProducts}
            setFilterProducts={setCurrentProducts}
            handleClickFilter={handleClickFilter}
          />
        </Grid>
        <Grid item xs={14} sm={14} md={11}>
          <Box
            component={Grid}
            container
            sx={{ borderColor: 'primary.main' }}
            borderBottom={2}
            className="select-catalogo-container"
          >
            <Grid item md={4}>
              <Typography variant="h4" sx={{ fontSize: '30px' }} fontWeight={700} color="primary">
                {dictionary?.catalogo}
              </Typography>
            </Grid>
            <Grid item xs={8} display={'flex'} alignItems={'center'} justifyContent={'right'}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'center',
                }}
              >
                {products && (
                  <Typography
                    variant="h6"
                    color="initial"
                    fontWeight={400}
                    sx={{
                      color: 'var(--color-light-gray)',
                      fontSize: '18px',
                      pr: 1,
                    }}
                  >
                    {products.cantidadTotalRegistros} {dictionary?.totalResultados}
                  </Typography>
                )}
                <Divider orientation="vertical" sx={{ height: '20px' }} />
                <SelectCatalogo
                  limpiar={limpiar}
                  filterProducts={currentProducts}
                  setFilterProducts={setCurrentProducts}
                />
              </Box>
            </Grid>
          </Box>
          {loading ? <MiniLoader /> : <CatalogoCards productos={products?.data ? products?.data : null} />}
        </Grid>
      </Grid>
      <Box className="pagination-container">
        {!loading && products && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 4 }}>
            <CustomPagination
              cantidadPaginas={products.cantidadPaginas}
              filterProducts={currentProducts}
              setFilterProducts={setCurrentProducts}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GiftCard;
