import React from 'react';
import { Typography, Box } from '@mui/material';
import {
  EmojiEventsOutlined as EmojiEventsOutlinedIcon,
  AccessibilityNewOutlined as AccessibilityNewOutlinedIcon,
  ModeCommentOutlined as ModeCommentOutlinedIcon,
} from '@mui/icons-material/';

interface RecognitionDetailProps {
  recognitionTitle: string;
  recognizedByTitle: string;
  recognitionCommentTitle: string;
  recognition: string;
  recognizedBy: string;
  comment: string;
}

export const RecognitionDetail: React.FC<RecognitionDetailProps> = ({
  recognitionTitle,
  recognizedByTitle,
  recognitionCommentTitle,
  recognition,
  recognizedBy,
  comment,
}) => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        py={1}
      >
        <EmojiEventsOutlinedIcon color="primary" />
        <Typography variant="body2">
          {recognitionTitle} {recognition}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        gap={1}
        py={1}
      >
        <AccessibilityNewOutlinedIcon color="primary" />
        <Typography variant="body2">
          {recognizedByTitle} {recognizedBy}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        gap={1}
        py={1}
      >
        <ModeCommentOutlinedIcon color="primary" />
        <Typography variant="body2">
          {recognitionCommentTitle} {comment}
        </Typography>
      </Box>
    </>
  );
};
