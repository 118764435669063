import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext, LanguageContext } from '../../../context';
import { cliente, DEFAULT_STALE_TIME } from '../../constants/constants';
import { IHomeCarrouselClientAndLanguageProps } from '../../types';
import { getHomeCarruselByClientAndLanguage } from '../../../services/homeCarrousel';

interface IHomeCarrousel {
  clientAndLanguage?: IHomeCarrouselClientAndLanguageProps;
}

export const useHomeCarrousel = ({ clientAndLanguage }: IHomeCarrousel) => {
  const appContext = useContext(AppContext);
  const idUsuario: number = appContext?.user?.idUsuario;
  const { language } = useContext(LanguageContext);

  const carrouselByClientLanguageQuery = useQuery({
    queryKey: ['carrousel-by-client-language', { idUsuario, language }],
    queryFn: async () => await getHomeCarruselByClientAndLanguage({ cliente, lenguaje: language }),
    staleTime: DEFAULT_STALE_TIME,
    enabled: Boolean(clientAndLanguage?.cliente),
  });

  return { carrouselByClientLanguageQuery };
};
