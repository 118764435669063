import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from '@mui/material';
import {
  StorefrontOutlined as StorefrontOutlinedIcon,
  PushPinOutlined as PushPinOutlinedIcon,
  Close as CloseIcon,
} from '@mui/icons-material/';
import { OhGiftCard } from '../../../../common/types';
import { BRANCHES_MODAL_STYLES } from './styles';

interface BranchesModalProps {
  title: string;
  branches: OhGiftCard[];
  isBranchesLoading: boolean;
  handleClose: () => void;
  open: boolean;
}

export const BranchesModal: React.FC<BranchesModalProps> = ({
  handleClose,
  open,
  title,
  branches,
  isBranchesLoading,
}) => {
  if (isBranchesLoading) return null;

  //TODO fix this
  const groupedBranches = branches?.reduce((acc, branch) => {
    const { provincia } = branch;
    if (!acc[provincia]) {
      acc[provincia] = [];
    }
    acc[provincia].push(branch);
    return acc;
  }, {});

  const sortedProvinces: string[] = groupedBranches && Object.keys(groupedBranches).sort();

  sortedProvinces?.forEach((provincia) => {
    groupedBranches[provincia].sort((a, b) => a.localidad.localeCompare(b.localidad));
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={BRANCHES_MODAL_STYLES.boxContainer}>
        <Box sx={BRANCHES_MODAL_STYLES.closeIcon}>
          <Button variant="text" color="primary" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </Box>

        <Box sx={BRANCHES_MODAL_STYLES.title}>
          <StorefrontOutlinedIcon color="primary" sx={BRANCHES_MODAL_STYLES.storeIcon} />
          <Typography fontSize={20} component="h3" color="primary">
            {title}
          </Typography>
        </Box>

        <Divider sx={BRANCHES_MODAL_STYLES.divider} />
        {/* //! Eliminar cuanto este listo el endpoint */}
        {sortedProvinces?.map((provincia) => (
          <Box key={provincia} sx={{ mt: 2, p: '0px 20px' }}>
            <Typography fontSize={18} color="primary">
              {provincia}
            </Typography>
            <Grid container>
              {groupedBranches[provincia]?.map(
                (branch, index) =>
                  branch.direccion && (
                    <Grid item xs={12} sm={6} key={index}>
                      <List sx={{ p: 0, m: 0 }}>
                        <ListItem>
                          <ListItemIcon>
                            <PushPinOutlinedIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary={branch.localidad} secondary={branch.direccion} />
                        </ListItem>
                      </List>
                    </Grid>
                  )
              )}
            </Grid>
          </Box>
        ))}
        {/* {branches?.map(({ idSucursal, provincia, direccion, localidad }) => (
          <Box key={idSucursal} sx={BRANCHES_MODAL_STYLES.branchesBox}>
            <Typography fontSize={18} color="primary">
              {provincia}
            </Typography>
            <Grid container>
              {direccion && (
                <Grid item xs={12} sm={6}>
                  <List sx={BRANCHES_MODAL_STYLES.address}>
                    <ListItem>
                      <ListItemIcon>
                        <PushPinOutlinedIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={localidad} secondary={direccion} />
                    </ListItem>
                  </List>
                </Grid>
              )}
            </Grid>
          </Box>
        ))} */}
      </Box>
    </Modal>
  );
};
