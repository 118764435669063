import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfirmLiriumOrderProps, LiriumOrderExternalAPI, NewLiriumOrderProps } from '../../types';
import { modalError, modalSuccess } from '../../../utils';
import { postConfirmOrder, postLiriumOrderAPIExternal } from '../../../services/lirium_orders';
import { RUTAS } from '../../constants/rutas';
import { useAppContext } from '../useAppContext';

interface LiriumOrderMutationProps {
  confirmOrder?: ConfirmLiriumOrderProps;
  newOrder?: NewLiriumOrderProps;
}

export const useLiriumOrderMutation = () => {
  const { idUsuario } = useAppContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const confirmOrderMutation = useMutation({
    mutationFn: async ({ confirmOrder: { order } }: LiriumOrderMutationProps): Promise<void> =>
      await postConfirmOrder(order),

    onSuccess(_, variables) {
      const {
        confirmOrder: { successText },
      } = variables;

      modalSuccess(successText);
      navigate(RUTAS.cripto);
    },

    onError(_, variables) {
      const {
        confirmOrder: { errorText },
      } = variables;

      modalError(errorText);
    },
  });

  const postLiriumOrder = useMutation({
    mutationFn: async ({ newOrder: { order } }: LiriumOrderMutationProps): Promise<LiriumOrderExternalAPI> =>
      await postLiriumOrderAPIExternal(order),

    onSuccess(_, variables) {
      const {
        newOrder: { successMessage },
      } = variables;

      queryClient.invalidateQueries({
        queryKey: ['crypto-orders-customer', { idUsuario }],
      });

      modalSuccess(successMessage);
    },

    onError(_, variables) {
      const {
        newOrder: { errorMessage },
      } = variables;

      modalError(errorMessage);
    },
  });

  return { confirmOrderMutation, postLiriumOrder };
};
