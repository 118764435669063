import { API_PERSON_URL } from '../../common/constants';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

interface IPointsByPerson {
  idPersona: number;
  idCliente: number;
}

export const getPointsByPerson = async (params: IPointsByPerson): Promise<number> => {
  const { idCliente, idPersona } = params;
  const URL: string = API_PERSON_URL.personPoints
    .replace('{idPersona}', String(idPersona))
    .replace('{idCliente}', String(idCliente));

  try {
    const points: number = await fetchContent(URL, {
      method: 'GET',
    });
    return points;
  } catch (error: any) {
    console.error('[getPointsByPerson]: ' + error);
    throw handleServiceError(error);
  }
};
