import { API_LIRIUM_USER_URL } from '../../common/constants';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';
import { LiriumCustomer } from '../../common/types';

export const getCustomer = async (idCustomer: string): Promise<LiriumCustomer> => {
  try {
    const URL: string = API_LIRIUM_USER_URL.customerById.replace('{idCustomer}', String(idCustomer));

    const customer: LiriumCustomer = await fetchContent(URL);

    return customer;
  } catch (error: any) {
    console.error('[getCustomer]: ', error);
    throw handleServiceError(error);
  }
};
