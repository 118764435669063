import { CSSProperties } from 'styled-components';

export const ORDER_EXCHANGE_FORM_STYLES = {
  mainContainer: {
    display: 'flex',
    gap: '15px',
  } as CSSProperties,
  codeTextField: {
    width: '60px',
    input: {
      color: 'primary.main',
      fontSize: '18px',
    },
  } as CSSProperties,
  resendContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
  } as CSSProperties,
};
