import React, { useContext, useState } from 'react';
import { AppContext, LanguageContext } from '../../context';
import { RecognitionsColumns, IRecognitionsSort, ISortDirections } from '../types/recognitions';

export const useRecognitionsTable = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [sort, setSort] = useState<IRecognitionsSort>({
    column: RecognitionsColumns.fecha,
    direction: ISortDirections.desc,
  });
  const appContext = useContext(AppContext);
  const idUsuario = appContext?.user?.idUsuario;
  const idSector = appContext?.user?.idSector;
  const language = useContext(LanguageContext);

  const changeSort = (column: string): void => {
    const getColumn = RecognitionsColumns[column];
    const getDirection = sort.direction === ISortDirections.asc ? ISortDirections.desc : ISortDirections.asc;

    setSort({
      column: getColumn,
      direction: getDirection,
    });
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number): void => {
    event.preventDefault();
    setPage(value);
  };

  return {
    page,
    totalPages,
    idUsuario,
    idSector,
    language,
    search,
    sort,
    changeSort,
    setPage,
    setSearch,
    setTotalPages,
    handleChangePage,
  };
};
