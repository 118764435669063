import { API_LIRIUM_USER_URL, headerOptions } from '../../common/constants';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';
import { InternalAPILiriumUser, InternalAPILiriumUserResponse } from '../../common/types';

export const postUser = async (customer: InternalAPILiriumUser): Promise<InternalAPILiriumUserResponse> => {
  const URL: string = API_LIRIUM_USER_URL.user;
  try {
    const user: InternalAPILiriumUserResponse = await fetchContent(URL, {
      method: 'POST',
      body: JSON.stringify(customer),
      headers: headerOptions,
    });

    return user;
  } catch (error: any) {
    console.error('[postUser]: ', error);
    throw handleServiceError(error);
  }
};
