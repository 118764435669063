import { CSSProperties } from 'styled-components';

export const BRANCHES_MODAL_STYLES = {
  boxContainer: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80vh',
    width: '700px',
    backgroundColor: 'white',
    borderRadius: '15px',
    boxShadow: '0 0 24px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  title: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
  } as CSSProperties,
  storeIcon: {
    fontSize: '30px',
  } as CSSProperties,
  divider: {
    width: '100%',
    my: '20px',
  } as CSSProperties,
  branchesBox: {
    marginTop: 2,
    padding: '0px 20px',
  } as CSSProperties,
  address: {
    padding: '0',
    margin: '0',
  } as CSSProperties,
  closeIcon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  } as CSSProperties,
};
