import { API_STATE_URL } from '../../common/constants';
import { State } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getStates = async (): Promise<State[]> => {
  const URL: string = API_STATE_URL.state;

  try {
    const states: State[] = await fetchContent(URL, {
      method: 'GET',
    });

    return states;
  } catch (error: any) {
    console.error('[getStates]: ' + error);
    throw handleServiceError(error);
  }
};
