import { API_CLIENT_URL } from '../../common/constants';
import { ClientCurrency } from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const getClientCurrency = async (idCliente: number): Promise<ClientCurrency> => {
  const URL: string = API_CLIENT_URL.clientCurrency.replace('{idCliente}', String(idCliente));

  try {
    const response: ClientCurrency = await fetchContent(URL, {
      method: 'GET',
    });

    return response;
  } catch (error: any) {
    console.error('[getClientCurrency]: ' + error);
    throw handleServiceError(error);
  }
};
