import { formateDayAndMonth, formateFullYear } from '.';
import {
  CustomerAccounts,
  LiriumCustomerOrder,
  LiriumHistoricalPrice,
  CustomerOrdersFormatTable,
  ExchangeRatesQuote,
  CustomerBody,
} from '../common/types';

export const balanceAmount = (customerAccounts: CustomerAccounts, currentCurrency: string, text: string): string => {
  let amount: string = '';
  if (customerAccounts) {
    amount = `${currentCurrency}: ${parseFloat(
      customerAccounts?.accounts.filter(({ currency }) => currency === currentCurrency)[0]?.amount ?? '0'
    ).toFixed(8)}`;
  } else {
    amount = text;
  }
  return amount;
};

export const formatCurrency = (currency: LiriumHistoricalPrice[]) => {
  const data = {
    labels: currency?.map(({ timestamp }) => formateDayAndMonth(timestamp)),
    datasets: [
      {
        data: currency?.map(({ avg }) => avg),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  return data;
};

export const formatSimpleOrdersTransactionRows = (rows: LiriumCustomerOrder[]): CustomerOrdersFormatTable[] => {
  return rows?.map(({ created_at, asset: { currency, amount }, operation, id }, index) => {
    return {
      index: index + 1,
      created_at: formateFullYear(created_at),
      currency,
      operation,
      id,
      amount: amount ? parseFloat(amount).toFixed(8) : '0',
    };
  });
};

export const calculatePointsPerCrypto = (
  points: number,
  exchangeRates: ExchangeRatesQuote[],
  currency: string
): number => {
  const valueInCrypto: ExchangeRatesQuote[] = exchangeRates?.filter((exchange) => exchange.currency === currency);

  const crypto: number =
    valueInCrypto && valueInCrypto[0]?.ask && parseFloat((points / Number(valueInCrypto[0].ask)).toFixed(8));

  return crypto;
};

export const checkFormProperties = (form: CustomerBody): boolean => {
  const isFormFilled: boolean = Object.entries(form).every(([key, value]) => key === 'middle_name' || Boolean(value));
  return isFormFilled;
};

export const calculateCryptoPerPoints = (crypto: number, exchangeRates: ExchangeRatesQuote[], currency: string) => {
  const valueInCrypto: ExchangeRatesQuote[] = exchangeRates?.filter((exchange) => exchange.currency === currency);

  const points: number = valueInCrypto && valueInCrypto[0]?.ask && Math.round(crypto * Number(valueInCrypto[0]?.ask));

  return points;
};
