import { CSSProperties } from 'styled-components';

export const ACCOUNT_MENU_STYLES = {
  accountContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '2.6vh',
    borderRadius: '15px',
    minWidth: 'fit-content',
  } as CSSProperties,
  logoContainerHover: () =>
    ({
      '&:hover': {
        backgroundColor: 'rgb(0, 71, 89)',
      },
    } as CSSProperties),
  iconButton: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  } as CSSProperties,
  profileIcon: {
    fontSize: 20,
  } as CSSProperties,
  menuItemPaper: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  } as CSSProperties,
  userNameContainer: {
    cursor: 'default',
  } as CSSProperties,
};
