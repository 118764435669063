import { API_SUPERVISOR_URL } from '../../../common/constants';
import { IMyTeamSupervisorBudget } from '../../../common/types/recognitions';
import { fetchContent } from '../../../utils/fetchUtils';
import { handleServiceError } from '../../GiftCardService';

export const getBudgetSupervisor = async (idUsuario: number | string): Promise<IMyTeamSupervisorBudget> => {
  try {
    const URL: string = API_SUPERVISOR_URL.supervisorById.replace('{idUsuario}', String(idUsuario));

    const response: IMyTeamSupervisorBudget = await fetchContent(URL, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.error('[getBudgetSupervisor]: ' + error);
    throw handleServiceError(error);
  }
};
