import { ViewTable } from '../../../components/ViewTable/ViewTable';
import { useMyRecognitions } from '../../../hooks/recognitions';
import { ShareOnLinkedInModal } from './ShareOnLinkedInModal';

export const MyRecognitions = () => {
  const {
    myRecognitions,
    isLoading,
    dictionary,
    columns,
    page,
    sort,
    totalPages,
    isModalOpen,
    selectedRecognition,
    linkedInBtn,
    shareOnLinkedIn,
    handleChangePage,
    changeSort,
    setPage,
    setIsModalOpen,
  } = useMyRecognitions();

  return (
    <>
      <ViewTable
        title={dictionary.title}
        isLoading={isLoading}
        rows={myRecognitions}
        page={page}
        totalPages={totalPages}
        noResults={dictionary.table.noResult}
        columns={columns}
        sort={sort}
        showSearchBar={false}
        setPage={setPage}
        changeSort={changeSort}
        handleChangePage={handleChangePage}
      />
      {isModalOpen && (
        <ShareOnLinkedInModal
          key={selectedRecognition?.idReconocimiento}
          description={selectedRecognition?.descripcion}
          image={selectedRecognition?.imagen}
          title={selectedRecognition?.tituloTipoReconocimiento}
          points={
            selectedRecognition?.puntos > 0 &&
            dictionary.table.actions.detail.points.replace('{points}', String(selectedRecognition.puntos))
          }
          linkedInBtn={linkedInBtn}
          isModalOpen={isModalOpen}
          shareOnLinkedIn={shareOnLinkedIn}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};
