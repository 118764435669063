import { ICardPoints, IFormatCardPoint, IHomeDictionary } from '../../common/types';

export const formatPointsData = (points: ICardPoints[], dictionary: IHomeDictionary): IFormatCardPoint[] => {
  const cardPoints: IFormatCardPoint[] = points.map((card) => {
    return {
      points: card.data.data ?? null,
      isLoading: card.data.isLoading,
      isError: card.data.isError,
      title: dictionary.cards[card.cardType].title,
      subtitle: dictionary.cards[card.cardType].subtitle,
      noResults: dictionary.noResults,
      route: card.route,
      isPermisos: card.isPermisos,
      isPointsCard: card.isPointsCard,
    };
  });

  return cardPoints;
};
