import React from 'react';
import { Box, TextField } from '@mui/material';
import { ORDER_EXCHANGE_FORM_STYLES } from '../..';

interface CodeContainerProps {
  code: string[];
  textFieldInput: React.MutableRefObject<HTMLInputElement[]>;
  handleChange: (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleKeyDown: (index: number, event: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const CodeContainer: React.FC<CodeContainerProps> = ({ code, textFieldInput, handleChange, handleKeyDown }) => {
  return (
    <Box sx={ORDER_EXCHANGE_FORM_STYLES.mainContainer}>
      {code.map((_, index) => (
        <TextField
          key={index}
          type="text"
          sx={ORDER_EXCHANGE_FORM_STYLES.codeTextField}
          inputRef={(elRef) => (textFieldInput.current[index] = elRef as any)}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          inputProps={{
            maxLength: 1,
            style: { textAlign: 'center' },
          }}
        />
      ))}
    </Box>
  );
};
